import { useEffect, Children } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

// Plugins
import flagsmith from 'flagsmith';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getAcademyPlaylist, getUpcomingWebinars } from './actions';

// Components
import Header from './Header';
import Summary from './Summary';
import Academy from './Academy';
import TopNav from '@/components/Shared/TopNav';
import WarningBanners from '@/components/Shared/WarningBanners';

// Styles
import './style.css';

const Home = function Home() {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    studio: { id: loginStudioId }
  } = useSelector((state) => state.login);
  const { studio: usersStudio } = useSelector((state) => state.users);

  const studio = usersStudio;
  const { id: studioId, name: studioName, email: studioEmail } = studio ?? {};

  // Identify user after initialising
  flagsmith.identify(studioId, { name: studioName, email: studioEmail });

  useEffect(() => {
    dispatch(getAcademyPlaylist());
    dispatch(getUpcomingWebinars());
  }, []);

  return (
    <>
      <TopNav />
      <section id="home" className="container">
        <Header path={location.pathname} studioId={loginStudioId} />
        <WarningBanners />
        <Switch>
          <Redirect exact from="/home" to="/home/summary" />
          <Route path="/home/summary" component={Summary} />
          <Route path="/home/academy" component={Academy} />
        </Switch>
      </section>
    </>
  );
};

export default Home;

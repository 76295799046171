export const RESET_PRICESHEETS_REQUEST = 'RESET_PRICESHEETS_REQUEST';

export const GET_PRICESHEET_LIST_REQUEST = 'GET_PRICESHEET_LIST_REQUEST';
export const GET_PRICESHEET_LIST_SUCCESS = 'GET_PRICESHEET_LIST_SUCCESS';
export const GET_PRICESHEET_LIST_ERROR = 'GET_PRICESHEET_LIST_ERROR';

export const CREATE_PRICESHEET_REQUEST = 'CREATE_PRICESHEET_REQUEST';
export const CREATE_PRICESHEET_SUCCESS = 'CREATE_PRICESHEET_SUCCESS';
export const CREATE_PRICESHEET_ERROR = 'CREATE_PRICESHEET_ERROR';

export const UPDATE_PRICESHEET_REQUEST = 'UPDATE_PRICESHEET_REQUEST';
export const UPDATE_PRICESHEET_SUCCESS = 'UPDATE_PRICESHEET_SUCCESS';
export const UPDATE_PRICESHEET_ERROR = 'UPDATE_PRICESHEET_ERROR';

export const DELETE_PRICESHEET_REQUEST = 'DELETE_PRICESHEET_REQUEST';
export const DELETE_PRICESHEET_SUCCESS = 'DELETE_PRICESHEET_SUCCESS';
export const DELETE_PRICESHEET_ERROR = 'DELETE_PRICESHEET_ERROR';

export const GET_PRICESHEET_REQUEST = 'GET_PRICESHEET_REQUEST';
export const GET_PRICESHEET_SUCCESS = 'GET_PRICESHEET_SUCCESS';
export const GET_PRICESHEET_ERROR = 'GET_PRICESHEET_ERROR';

export const GET_PRICESHEET_BIOMETRICS_DISABLED_JOBS_REQUEST = 'GET_PRICESHEET_BIOMETRICS_DISABLED_JOBS_REQUEST';
export const GET_PRICESHEET_BIOMETRICS_DISABLED_JOBS_SUCCESS = 'GET_PRICESHEET_BIOMETRICS_DISABLED_JOBS_SUCCESS';
export const GET_PRICESHEET_BIOMETRICS_DISABLED_JOBS_ERROR = 'GET_PRICESHEET_BIOMETRICS_DISABLED_JOBS_ERROR';

export const CREATE_PRICESHEET_ITEM_REQUEST = 'CREATE_PRICESHEET_ITEM_REQUEST';
export const CREATE_PRICESHEET_ITEM_SUCCESS = 'CREATE_PRICESHEET_ITEM_SUCCESS';
export const CREATE_PRICESHEET_ITEM_ERROR = 'CREATE_PRICESHEET_ITEM_ERROR';

export const CREATE_PRICESHEET_ITEM_BY_PRODUCTS_REQUEST = 'CREATE_PRICESHEET_ITEM_BY_PRODUCTS_REQUEST';
export const CREATE_PRICESHEET_ITEM_BY_PRODUCTS_SUCCESS = 'CREATE_PRICESHEET_ITEM_BY_PRODUCTS_SUCCESS';
export const CREATE_PRICESHEET_ITEM_BY_PRODUCTS_ERROR = 'CREATE_PRICESHEET_ITEM_BY_PRODUCTS_ERROR';

export const CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_REQUEST = 'CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_REQUEST';
export const CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_SUCCESS = 'CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_SUCCESS';
export const CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_ERROR = 'CREATE_PRICESHEET_ITEM_DIGITAL_BUNDLE_ERROR';

export const CREATE_PRICESHEET_ITEM_DOWNLOAD_ALL_REQUEST = 'CREATE_PRICESHEET_ITEM_DOWNLOAD_ALL_REQUEST';
export const CREATE_PRICESHEET_ITEM_DOWNLOAD_ALL_SUCCESS = 'CREATE_PRICESHEET_ITEM_DOWNLOAD_ALL_SUCCESS';
export const CREATE_PRICESHEET_ITEM_DOWNLOAD_ALL_ERROR = 'CREATE_PRICESHEET_ITEM_DOWNLOAD_ALL_ERROR';

export const COPY_PRICESHEET_ITEM_REQUEST = 'COPY_PRICESHEET_ITEM_REQUEST';
export const COPY_PRICESHEET_ITEM_SUCCESS = 'COPY_PRICESHEET_ITEM_SUCCESS';
export const COPY_PRICESHEET_ITEM_ERROR = 'COPY_PRICESHEET_ITEM_ERROR';

export const UPDATE_PRICESHEET_ITEM_REQUEST = 'UPDATE_PRICESHEET_ITEM_REQUEST';
export const UPDATE_PRICESHEET_ITEM_SUCCESS = 'UPDATE_PRICESHEET_ITEM_SUCCESS';
export const UPDATE_PRICESHEET_ITEM_ERROR = 'UPDATE_PRICESHEET_ITEM_ERROR';

export const DELETE_PRICESHEET_ITEM_REQUEST = 'DELETE_PRICESHEET_ITEM_REQUEST';
export const DELETE_PRICESHEET_ITEM_SUCCESS = 'DELETE_PRICESHEET_ITEM_SUCCESS';
export const DELETE_PRICESHEET_ITEM_ERROR = 'DELETE_PRICESHEET_ITEM_ERROR';

export const GET_LAB_REQUEST = 'GET_LAB_REQUEST';
export const GET_LAB_SUCCESS = 'GET_LAB_SUCCESS';
export const GET_LAB_ERROR = 'GET_LAB_ERROR';

export const COPY_PRICESHEET_REQUEST = 'COPY_PRICESHEET_REQUEST';
export const COPY_PRICESHEET_SUCCESS = 'COPY_PRICESHEET_SUCCESS';
export const COPY_PRICESHEET_ERROR = 'COPY_PRICESHEET_ERROR';

export const GET_PRICESHEET_ACTIVITIES = 'GET_PRICESHEET_ACTIVITIES';
export const GET_PRICESHEET_ACTIVITIES_SUCCESS = 'GET_PRICESHEET_ACTIVITIES_SUCCESS';
export const GET_PRICESHEET_ACTIVITIES_ERROR = 'GET_PRICESHEET_ACTIVITIES_ERROR';

export const FEATURE_PRICE_SHEET_PRODUCT = 'FEATURE_PRICE_SHEET_PRODUCT';
export const FEATURE_PRICE_SHEET_PRODUCT_SUCCESS = 'FEATURE_PRICE_SHEET_PRODUCT_SUCCESS';
export const FEATURE_PRICE_SHEET_PRODUCT_ERROR = 'FEATURE_PRICE_SHEET_PRODUCT_ERROR';

export const UNFEATURE_PRICE_SHEET_PRODUCT = 'UNFEATURE_PRICE_SHEET_PRODUCT';
export const UNFEATURE_PRICE_SHEET_PRODUCT_SUCCESS = 'UNFEATURE_PRICE_SHEET_PRODUCT_SUCCESS';
export const UNFEATURE_PRICE_SHEET_PRODUCT_ERROR = 'UNFEATURE_PRICE_SHEET_PRODUCT_ERROR';

export const GET_PRICESHEET_CSV_REQUEST = 'GET_PRICESHEET_CSV_REQUEST';
export const GET_PRICESHEET_CSV_SUCCESS = 'GET_PRICESHEET_CSV_SUCCESS';
export const GET_PRICESHEET_CSV_ERROR = 'GET_PRICESHEET_CSV_ERROR';

export const GET_PRICESHEET_THEME_PROPERTIES_REQUEST = 'GET_PRICESHEET_THEME_PROPERTIES_REQUEST';
export const GET_PRICESHEET_THEME_PROPERTIES_SUCCESS = 'GET_PRICESHEET_THEME_PROPERTIES_SUCCESS';
export const GET_PRICESHEET_THEME_PROPERTIES_ERROR = 'GET_PRICESHEET_THEME_PROPERTIES_ERROR';

export const DIGITAL_PRICE_MINIMUM = 200;
export const DIGITAL_BUNDLE_MAX_PRICE = 9999;
export const DIGITAL_BUNDLE_MAX_TIERS = 5;
export const DIGITAL_BUNDLE_MIN_PHOTOS = 2;
export const DIGITAL_BUNDLE_MAX_PHOTOS = 100;
export const DIGITAL_DOWNLOAD_ALL_COST = 0;
export const DIGITAL_DOWNLOAD_ALL_MINIMUM = 200;

import { FC, useState, useEffect, ChangeEvent, MouseEvent, FormEvent } from 'react';
import { Link } from 'react-router-dom';

// Plugins
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import DatePicker from 'react-datepicker';
import Select, { SingleValue, MultiValue } from 'react-select';

// Components
import Header from '../Header';
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Redux
import { getJobList } from '../../Jobs/actions';
import { useSelector, useDispatch } from 'react-redux';
import { productsReportRequest, productsReportDownloadRequest } from '../actions';

// Helpers
import { pickerDate } from '@/utils/displayFormats';

// Types
import { ReportProduct, ReportProductParams, ReportProductTypes, SelectOptionType, TableFilter } from '@/types';

interface ProductsProps {
  match: {
    path: string;
  };
}

interface LoginState {
  login: {
    user: {
      roles: string[];
    };
    studio: {
      name: string;
    };
  };
}

interface ReportsState {
  reports: {
    result: {
      monthly: [];
      years: string[];
      year: string;
    };
    productsReport: {
      list: [];
      pagination: { page: number; perPage: number; total: number };
      requesting: boolean;
    };
    requesting: boolean;
  };
}

interface JobsState {
  jobs: {
    jobs: {
      list: [];
    };
    requesting: boolean;
  };
}

// Constants
const ORDER_PAGE: number = 1;
const ORDER_PER_PAGE: number = 20;

const Products: FC<ProductsProps> = ({ match }) => {
  const dispatch = useDispatch();

  const { user, studio } = useSelector((state: LoginState) => state.login);
  const {
    productsReport: { list: productsReportList, pagination, requesting: productsReportRequesting },
    requesting: reportsRequesting
  } = useSelector((state: ReportsState) => state.reports);
  const {
    jobs: { list: jobList },
    requesting: jobsRequesting
  } = useSelector((state: JobsState) => state.jobs);

  const requesting = productsReportRequesting || reportsRequesting || jobsRequesting;

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [tableFilter, setTableFilter] = useState<TableFilter>({});

  const [jobOptions, setJobOptions] = useState<MultiValue<SelectOptionType>>([]);
  const [selectJobs, setSelectJobs] = useState<MultiValue<SelectOptionType>>([]);

  const [selectType, setSelectType] = useState<SingleValue<SelectOptionType>>({ value: ReportProductTypes.All, label: 'All' });
  const [search, setSearch] = useState<string>('');

  const typeOptions = [
    { value: ReportProductTypes.All, label: 'All' },
    { value: ReportProductTypes.Packages, label: 'Packages' },
    { value: ReportProductTypes.ALaCarte, label: 'A La Carte' },
    { value: ReportProductTypes.DigitalBundles, label: 'Digital Bundles' },
    { value: ReportProductTypes.PhotoAddOns, label: 'Photo Add-Ons' },
    { value: ReportProductTypes.DownloadAll, label: 'Download All' }
  ];

  const initialTableFilter: TableFilter = {
    order: 'date',
    dir: 'DESC',
    search: '',
    page: ORDER_PAGE,
    per_page: ORDER_PER_PAGE
  };

  // Helpers
  const setInitialState = () => {
    // Populate start date for products with the beginning of the year by default
    const currentDate = moment();
    const beginningOfYear = moment().startOf('year');

    setSearch('');
    setSelectJobs([]);
    setSelectType(typeOptions[0]);
    setEndDate(currentDate.toDate());
    setStartDate(beginningOfYear.toDate());
    setTableFilter(initialTableFilter);

    dispatch(
      productsReportRequest({
        ...initialTableFilter,
        start_date: pickerDate(beginningOfYear),
        end_date: pickerDate(currentDate),
        type: ReportProductTypes.All
      })
    );
  };

  // UI Handlers
  const handleStartDateChange = (date: Date | null): void => setStartDate(date!);
  const handleEndDateChange = (date: Date | null): void => setEndDate(date!);

  const handleJobsChange = (jobs: MultiValue<SelectOptionType>): void => setSelectJobs(jobs);

  const handleTypeChange = (type: SingleValue<SelectOptionType>): void => {
    setInitialState();

    const params: ReportProductParams = {
      start_date: pickerDate(startDate) ?? undefined,
      end_date: pickerDate(endDate) ?? undefined,
      type: type?.value
    };

    dispatch(productsReportRequest({ ...tableFilter, ...params }));
    setSelectType(type);
  };

  const handlePagination = (page: number) => {
    dispatch(productsReportRequest({ ...tableFilter, page }));
    setTableFilter((prevState: TableFilter) => ({ ...prevState, page }));
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const handleSearchClear = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setInitialState();
  };
  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const trimSearch = search.trim();

    setStartDate(undefined);
    setEndDate(undefined);
    setSelectJobs([]);
    setSearch(trimSearch);
    setSelectType(typeOptions[0]);

    if (trimSearch) dispatch(productsReportRequest({ ...tableFilter, page: ORDER_PAGE, search: trimSearch }));
  };

  const handleFilter = (): void => {
    const params: ReportProductParams = {};

    const start_date = moment(startDate).isValid() ? pickerDate(startDate) : null;
    const end_date = moment(endDate).isValid() ? pickerDate(endDate) : null;
    const filterJobs = selectJobs && selectJobs.length ? selectJobs.map((item: { value?: string }) => item.value) : [];

    if (start_date) params['start_date'] = start_date;
    if (end_date) params['end_date'] = end_date;
    if (filterJobs.length > 0) params['jobs'] = filterJobs;
    if (selectType?.value !== 'all') params['type'] = selectType?.value;

    dispatch(productsReportRequest({ ...tableFilter, ...params }));
  };

  const handleReportExport = () => {
    const params: ReportProductParams = {};

    const start_date = moment(startDate).isValid() ? pickerDate(startDate) : null;
    const end_date = moment(endDate).isValid() ? pickerDate(endDate) : null;
    const filterJobs = selectJobs && selectJobs.length ? selectJobs.map((item: { value?: string }) => item.value) : [];

    if (start_date) params['start_date'] = start_date;
    if (end_date) params['end_date'] = end_date;
    if (filterJobs.length > 0) params['jobs'] = filterJobs;
    if (selectType && selectType.value && selectType.value !== ReportProductTypes.All) params['type'] = selectType.value;

    dispatch(
      productsReportDownloadRequest({
        ...params,
        format: 'csv',
        fileName: `${studio.name} - ${start_date && start_date !== null ? start_date : ''} - ${
          end_date && end_date !== null ? `${end_date} - ` : ''
        }Products.csv`
      })
    );
  };

  const handleRowExpand = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    const element = event.currentTarget;

    element.classList.toggle('table-dynamic__section--active');
    element.classList.toggle('reports-table-dynamic__section--active');
  };

  useEffect(() => {
    const options: SelectOptionType[] | [] = jobList.map((job: { id: string; name: string }) => ({ value: job.id, label: job.name }));

    setJobOptions(options);
  }, [jobList]);

  useEffect(() => {
    setInitialState();

    if (jobList?.length === 0) {
      dispatch(getJobList({ order: 'name', dir: 'ASC', per_page: 10000 }));
    }
  }, []);

  return (
    <section className="products-report">
      <Header match={match} user={user} />

      <main className="container">
        <aside className="flex justify-between mb-2.5 md:flex-wrap">
          <div className="flex flex-nowrap items-center button-group md:flex-wrap">
            <DatePicker
              className="input--date"
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              isClearable={true}
              placeholderText="Start date"
              strictParsing
            />
            <i className="icon-arrow-long-right"></i>
            <DatePicker
              className="input--date"
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              isClearable={true}
              placeholderText="End date"
              strictParsing
            />
            <Select
              className="basis-5/12 select"
              classNamePrefix="select"
              isMulti={true}
              value={selectJobs}
              isClearable={true}
              placeholder="All Jobs"
              isLoading={requesting}
              options={jobOptions}
              onChange={(newValue) => handleJobsChange(newValue)}
            />
            <button className="button" name="filter" type="button" onClick={handleFilter} disabled={requesting}>
              Filter
            </button>
          </div>
          <div className="flex justify-end md:items-start button-group">
            <Select
              className="basis-2/6 md:basis-5/12 select"
              classNamePrefix="select"
              value={selectType}
              placeholder="Product Types"
              isLoading={requesting}
              options={typeOptions}
              onChange={(newValue) => handleTypeChange(newValue)}
            />
            <form onSubmit={handleSearch}>
              <fieldset className="fieldset--clean">
                <input type="search" name="search" placeholder="Search" maxLength={50} value={search} onChange={handleSearchChange} />
                <button className={`button button--clear button--small ${search ? '' : 'hidden'}`} name="button" type="button" onClick={handleSearchClear}>
                  Clear
                </button>
                <button className="button button--icon" name="button" type="submit">
                  <i className="icon-search" />
                </button>
              </fieldset>
            </form>
            <Tooltip {...{ title: 'Download CSV', position: 'top', arrow: true }}>
              <button
                className="button button--outline button--small"
                name="export"
                type="button"
                onClick={handleReportExport}
                disabled={productsReportList?.length === 0 || requesting}
              >
                <i className="icon-download-sharp"></i>
              </button>
            </Tooltip>
          </div>
        </aside>

        {productsReportList?.length > 0 ? (
          <article className="table-dynamic table-dynamic--compact">
            {/* Header */}
            <ul className="table-dynamic__header">
              <li className="table-dynamic__cell table-dynamic__cell--large">Type</li>
              <li className="table-dynamic__cell table-dynamic__cell--xlarge">Price Sheet</li>
              <li className="table-dynamic__cell table-dynamic__cell--flex font-semibold">Name</li>
              <li className="table-dynamic__cell">Quantity</li>
              <li className="table-dynamic__cell table-dynamic__cell--xlarge">Retail Sold</li>
            </ul>

            {/* Table */}
            {requesting ? (
              <TableLoader rows={12} rowHeight={75} />
            ) : (
              <>
                {productsReportList?.map((data: ReportProduct, index: number) => (
                  <div
                    key={`${index}${data.id}`}
                    className="table-dynamic__section table-dynamic__section--notexpandable"
                    onClick={(event) => (data.digital_bundles?.length ? handleRowExpand(event) : null)}
                  >
                    <ul className="table-fixed__row">
                      <li data-header="Type" className="table-dynamic__cell capitalize">
                        {data.type}
                      </li>
                      <li data-header="Price Sheet" className="table-dynamic__cell">
                        <Link className="font-semibold" to={`/storefront/price-sheets/${data.price_sheet_id}`}>
                          {data.price_sheet}
                        </Link>
                      </li>
                      <li data-header="Name" className="table-dynamic__cell">
                        {data.name}
                        {data.digital_bundles?.length && (
                          <ul className="mt-5 font-normal text-right">
                            {data.digital_bundles.map((bundle: { size: number }, index: number) => (
                              <li key={index}>
                                {bundle.size}
                                {bundle.size >= 10 && '+'} Image{bundle.size > 1 && 's'}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                      <li data-header="Quantity" className="table-dynamic__cell">
                        {data.quantity}
                        {data.digital_bundles?.length && (
                          <ul className="mt-5 font-semibold">
                            {data.digital_bundles.map((bundle: { qty: number }, index: number) => (
                              <li key={index}>{bundle.qty}</li>
                            ))}
                          </ul>
                        )}
                      </li>
                      <li data-header="Retail Sold" className="table-dynamic__cell">
                        {data.retail_sold ? Number(data.retail_sold / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}
                      </li>
                    </ul>
                  </div>
                ))}
              </>
            )}
          </article>
        ) : (
          <aside className="flex flex-col items-center justify-center panel panel--secondary panel--tall">
            <h2>To view products sold, select a start and end date or search for one.</h2>
            <p>You can download the data shown by selecting the export option.</p>
          </aside>
        )}
        {pagination.total > pagination.perPage && <Pagination pagination={pagination} onPagination={handlePagination} showPagesCount={4} />}
      </main>
    </section>
  );
};

export default Products;

import { useState } from 'react';

// Components
import Navigation from '@/components/Shared/Navigation';

// Styles
import './style.css';

const Header = ({ path, user, studio }) => {
  const [navigationIsOpen, setNavigationIsOpen] = useState(false);
  const roles = (user && user.roles) || [];

  const items = [
    { name: 'Account', link: '/user/account', icon: 'icon-user', dataCy: 'account-nav-link' },
    {
      name: 'My Studio',
      link: '/user/my-studio',
      icon: 'icon-camera',
      dataCy: 'my-studio-nav-link',
      hidden: studio?.id === 'all-studios' || roles.some((role) => role === 'manage_studio_settings' || role === 'manage_stripe') === false
    }
  ];

  const currentItem = items.find((item) => path.includes(item.link)) || items[0];

  const handleToggleNavigation = () => {
    setNavigationIsOpen(!navigationIsOpen);
  };

  return (
    <header className="user-header">
      <div className="container flex items-center justify-between">
        <div className="basis-3/12 md:basis-full resources__mobile-header">
          <h1 className="text-headline-md">Settings</h1>
          <button className="button button--outline resources__mobile-action" data-cy={`settings-nav-${currentItem.name}`} onClick={handleToggleNavigation}>
            <i className={currentItem.icon} />
            {currentItem.name}
          </button>
        </div>

        <div className="flex basis-9/12 justify-between">
          <Navigation items={items} isOpen={navigationIsOpen} onToggleNavigation={handleToggleNavigation} />
        </div>
      </div>
    </header>
  );
};

export default Header;

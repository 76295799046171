import { FC, useEffect, useState, useRef } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import {
  createPriceSheetItemDownloadAll,
  updatePricesheetItemRequest,
  deletePricesheetItemRequest,
  getPricesheetRequest,
  getPricesheetBiometricsDisabledJobsRequest
} from './actions';

// Plugins
import { Tooltip } from 'react-tippy';
import { NumericFormat } from 'react-number-format';

// Helpers
import { formatCurrency } from '@/utils/displayFormats';
import { convertToCents, convertToText } from '@/utils/currency';

// Images
import ReactIconWarn from '@/assets/images/icon-mark.svg?react';
import imageDownloadAll from '@/assets/images/image-download-all-universal.jpg';

// Styles
import './pricesheet-detail.css';

// Types
import { PriceSheet } from '@/types';

// Types
interface DownloadAllDetailProps {
  pricesheet: PriceSheet;
  pricesheetsRequesting: boolean;
}

interface BiometricsDisabledJob {
  id: string;
  name: string;
}

// Constants
import { DIGITAL_DOWNLOAD_ALL_COST, DIGITAL_DOWNLOAD_ALL_MINIMUM } from './constants';

const DownloadAllDetail: FC<DownloadAllDetailProps> = ({ pricesheet, pricesheetsRequesting }) => {
  const dispatch = useDispatch();
  const downloadAllRequestingRef = useRef<boolean>(false);

  const [downloadAllRetailPriceCents, setDownloadAllRetailPriceCents] = useState<number>(DIGITAL_DOWNLOAD_ALL_MINIMUM);
  const [downloadAllMarkupCents, setDownloadAllMarkupCents] = useState<number>(downloadAllRetailPriceCents - DIGITAL_DOWNLOAD_ALL_COST);
  const [invalidPriceModalOpen, setInvalidPriceModalOpen] = useState<boolean>(false);
  const [actionRequiredModalOpen, setActionRequiredModalOpen] = useState<boolean>(false);
  const [jobsBiometricsDisabledModalOpen, setJobsBiometricsDisabledModalOpen] = useState<boolean>(false);
  const [jobsBiometricsDisabled, setJobsBiometricsDisabled] = useState<BiometricsDisabledJob[]>([]);

  const { id: priceSheetId, pd_fee: pricesheetPhotoDayFee } = pricesheet;
  const downloadAllItem = pricesheet?.price_sheet_items?.find((item) => item.download_all === true);
  const digitalBundleItem = pricesheet?.price_sheet_items?.find((item) => item.digital_bundle === true);
  const showDisabledDownloadAll = downloadAllItem && pricesheet.has_biometrics_disabled_jobs;

  const handleRetailPriceChange = (retailPriceValue: string) => {
    const retailPriceValueCents = convertToCents(retailPriceValue) || 0;

    setDownloadAllRetailPriceCents(retailPriceValueCents);
    setDownloadAllMarkupCents(retailPriceValueCents - DIGITAL_DOWNLOAD_ALL_COST);
  };

  const handleRetailPriceSave = (retailPriceValue: string) => {
    const retailPriceValueCents = convertToCents(retailPriceValue) || 0;

    if (retailPriceValueCents < DIGITAL_DOWNLOAD_ALL_MINIMUM) {
      setInvalidPriceModalOpen(true);
    } else if (downloadAllItem) {
      dispatch(updatePricesheetItemRequest({ id: downloadAllItem.id, price_cents: downloadAllRetailPriceCents }));
    }
  };

  const handleDownloadAllInputChange = (event: any) => {
    if (downloadAllRequestingRef.current) return;

    if (event.target.checked) {
      if (digitalBundleItem) {
        setActionRequiredModalOpen(true);
      } else {
        downloadAllRequestingRef.current = true;
        dispatch(
          createPriceSheetItemDownloadAll({ priceSheetId, price_cents: downloadAllRetailPriceCents }, null, ({ response: { data } }: any) => {
            if (data?.error?.includes('contains digital items with bundles')) {
              setActionRequiredModalOpen(true);
            } else {
              dispatch(getPricesheetRequest(priceSheetId));
            }
          })
        );
      }
    } else if (downloadAllItem) {
      downloadAllRequestingRef.current = true;
      dispatch(
        deletePricesheetItemRequest({ id: priceSheetId, price_sheet_item_ids: [downloadAllItem.id] }, () => {
          dispatch(getPricesheetRequest(priceSheetId));
          handleRetailPriceChange(convertToText(DIGITAL_DOWNLOAD_ALL_MINIMUM));
        })
      );
    }
  };

  const handleToggleJobsBiometricsDisabledModal = () => setJobsBiometricsDisabledModalOpen(!jobsBiometricsDisabledModalOpen);

  const handleShowBiometricsDisabledJobs = () => {
    dispatch(
      getPricesheetBiometricsDisabledJobsRequest(priceSheetId, (data: BiometricsDisabledJob[]) => {
        setJobsBiometricsDisabled(data);
        handleToggleJobsBiometricsDisabledModal();
      })
    );
  };

  useEffect(() => {
    setDownloadAllRetailPriceCents(downloadAllItem?.price_cents || DIGITAL_DOWNLOAD_ALL_MINIMUM);
  }, [downloadAllItem?.price_cents]);

  useEffect(() => {
    if (downloadAllItem?.markup_cents) setDownloadAllMarkupCents(downloadAllItem?.markup_cents);
  }, [downloadAllItem?.markup_cents]);

  useEffect(() => {
    if (!pricesheetsRequesting && downloadAllRequestingRef.current) downloadAllRequestingRef.current = pricesheetsRequesting;
  }, [pricesheetsRequesting]);

  return (
    <>
      <section className="pricesheet-section pricesheet-section--active animate">
        <article className="pricesheet-section__table pricesheet-section__table--secondary">
          <table className="table">
            <thead className="table__header">
              <tr>
                <th colSpan={2}>Name</th>
                <th className="whitespace-nowrap">
                  <Tooltip {...{ title: 'Cost set by lab', position: 'top', trigger: 'mouseenter', arrow: true }}>
                    Unit Cost
                    <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-question-circle-o" />
                    </a>
                  </Tooltip>
                </th>
                <th className="whitespace-nowrap">
                  <Tooltip {...{ title: 'Retail Price minus Unit Cost', position: 'top', trigger: 'mouseenter', arrow: true }}>
                    Markup
                    <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-question-circle-o" />
                    </a>
                  </Tooltip>
                </th>
                <th className="whitespace-nowrap">
                  <Tooltip {...{ title: 'Amount displayed on price sheet', position: 'top', trigger: 'mouseenter', arrow: true }}>
                    Retail Price
                    <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-question-circle-o" />
                    </a>
                  </Tooltip>
                </th>
                <th className="whitespace-nowrap">
                  <Tooltip {...{ title: 'PhotoDay Fee', position: 'top', trigger: 'mouseenter', arrow: true }}>
                    PhotoDay Fee
                    <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-question-circle-o" />
                    </a>
                  </Tooltip>
                </th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table__body">
              {/* Download All */}
              <tr>
                <td className="!w-[84px] min-w-[84px]" {...{ headers: 'Name' }}>
                  <img className="rounded-md" src={imageDownloadAll} width="64" height="43" alt="hi res download" />
                </td>
                <td className="max-w-24">
                  Download All My Photos <span className="pill pill--small pill--blue mt-1.5">Beta</span>
                </td>
                <td {...{ headers: 'Unit Cost' }}>{convertToText(DIGITAL_DOWNLOAD_ALL_COST, '$')}</td>
                <td {...{ headers: 'Markup' }}>{convertToText(downloadAllMarkupCents > 0 ? downloadAllMarkupCents : 0, '$')}</td>
                <td {...{ headers: 'Retail Price' }}>
                  <NumericFormat
                    className={`input--block input--clean ${downloadAllMarkupCents < 0 ? 'input--invalid' : ''}`}
                    prefix={'$'}
                    decimalScale={2}
                    allowNegative={false}
                    value={convertToText(downloadAllRetailPriceCents)}
                    onValueChange={({ value }) => handleRetailPriceChange(value)}
                    onBlur={({ target }: any) => handleRetailPriceSave(target.value)}
                  />
                </td>
                <td {...{ headers: 'PhotoDay Fee' }}>
                  {isNaN(downloadAllRetailPriceCents) || isNaN(pricesheetPhotoDayFee)
                    ? '-'
                    : `$${formatCurrency(Math.round(downloadAllRetailPriceCents * (pricesheetPhotoDayFee / 100)))}`}
                </td>
                <td className="!w-[313px] text-normal" {...{ headers: 'Description' }}>
                  Download all your photos of one person as high-resolution digital images for a fixed price. Includes a print release, so you can print them in
                  various sizes whenever you’re ready.{' '}
                  <a
                    href="https://support.photoday.io/en/articles/10743526-how-do-i-offer-a-download-all-my-photos-product"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </a>
                </td>
                <td {...{ headers: 'Action' }}>
                  <input
                    className="hidden"
                    id="downloadAllSwitch"
                    type="checkbox"
                    checked={downloadAllItem ? true : false}
                    defaultChecked={downloadAllItem ? true : undefined}
                    onChange={handleDownloadAllInputChange}
                  />
                  <label
                    className={`label-switch label-switch--small ${pricesheetsRequesting ? 'disabled' : ''}`}
                    htmlFor="downloadAllSwitch"
                    data-loading-dark={pricesheetsRequesting}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {showDisabledDownloadAll && (
            <aside className="download-all__warning-panel animate">
              <ReactIconWarn className="download-all__warning-icon" />
              <p className="m-0 text-body-sm">
                This price sheet is attached to jobs that have FaceFind disabled, this product will not be available for those jobs. View a list of affected
                jobs{' '}
                <button className="download-all__warning-link" type="button" name="resolve" onClick={handleShowBiometricsDisabledJobs}>
                  here.
                </button>
              </p>
            </aside>
          )}
        </article>
      </section>

      {/* Invalid Price Modal */}
      {invalidPriceModalOpen && (
        <aside className="modal animate">
          <div className="modal__box download-all__modal-box">
            <main className="modal__content">
              <h2 className="mb-0 text-headline-md">Invalid Price</h2>

              <p className="mb-0 py-[20px] text-body-md">{`There is a ${formatCurrency(DIGITAL_DOWNLOAD_ALL_MINIMUM)} minimum for this product. Please enter a price of ${formatCurrency(DIGITAL_DOWNLOAD_ALL_MINIMUM)} or more.`}</p>
            </main>
            <footer className="modal__footer flex justify-end">
              <button
                className="button button--outline button--xmedium font-semibold"
                name="button"
                type="button"
                onClick={() => setInvalidPriceModalOpen(false)}
              >
                Close
              </button>
            </footer>
          </div>
        </aside>
      )}

      {/* Action Required Modal */}
      {actionRequiredModalOpen && (
        <aside className="modal animate">
          <div className="modal__box download-all__modal-box">
            <main className="modal__content">
              <h2 className="mb-0 text-headline-md">Action Required to Add Product</h2>

              <p className="mb-0 py-[20px] text-body-md">To add this product, you must disable Digital Bundles for High-Res Downloads.</p>
            </main>
            <footer className="modal__footer flex justify-end">
              <button
                className="button button--outline button--xmedium font-semibold"
                name="button"
                type="button"
                onClick={() => setActionRequiredModalOpen(false)}
              >
                Close
              </button>
            </footer>
          </div>
        </aside>
      )}

      {/* Biometrics Disabled Jobs Modal */}
      {jobsBiometricsDisabledModalOpen && (
        <aside className="modal animate">
          <div className="modal__box download-all__modal-box">
            <main className="modal__content">
              <h2 className="mb-0 text-headline-md">Jobs with FaceFind Disabled</h2>
              <ul className="overflow-y-auto pt-5 max-h-80">
                {jobsBiometricsDisabled.map((job, index) => {
                  return (
                    <li className={`${index === jobsBiometricsDisabled.length - 1 ? 'pb-0' : 'pb-3'}`} key={index}>
                      <p className="mb-0">{job.name}</p>
                    </li>
                  );
                })}
              </ul>
            </main>
            <footer className="modal__footer flex justify-end">
              <button
                className="button button--outline button--xmedium font-semibold"
                name="button"
                type="button"
                onClick={handleToggleJobsBiometricsDisabledModal}
              >
                Close
              </button>
            </footer>
          </div>
        </aside>
      )}
    </>
  );
};

export default DownloadAllDetail;

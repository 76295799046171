import { FC, ChangeEvent, useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateOrganization } from '../actions';

// Plugins
import { PatternFormat } from 'react-number-format';

// Types
import { OrganizationContact } from '@/types';

interface AddEditContactModalProps {
  organizationId: string;
  contactId: string | null;
  onModalClose: () => void;
}

const AddEditContactModal: FC<AddEditContactModalProps> = ({ organizationId, contactId, onModalClose }) => {
  const dispatch = useDispatch();
  const { organization, requesting } = useSelector((state: any) => state.organizations);

  const [contactInfo, setContactInfo] = useState<Partial<OrganizationContact>>();

  const handleInfoChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    setContactInfo((prevState?: Partial<OrganizationContact>) => ({ ...prevState, [target.name]: target.value }));
  };

  const handleSave = (): void => {
    dispatch(updateOrganization({ organizationId: organizationId, updatedOrganizationInfo: { contacts_attributes: [contactInfo] } }, () => onModalClose()));
  };

  const handleAddEditCancel = (): void => onModalClose();

  useEffect(() => {
    const foundContact = organization.contacts.find((contact: OrganizationContact) => contact.id === contactId);

    if (foundContact) {
      setContactInfo(foundContact);
    }
  }, []);

  return (
    <aside className="modal animate" data-cy={`${contactId ? 'edit' : 'add'}-contact-modal`}>
      <div className="modal__box">
        <header className="text-left">
          <h2 className="text-headline-sm">{contactId ? 'Edit' : 'Add'} Contact</h2>
        </header>
        <form className="modal__content">
          <fieldset className="flex flex-nowrap gap-2.5">
            <div className="basis-6/12">
              <label htmlFor="firstName">First Name</label>
              <input id="firstName" type="text" name="first_name" value={contactInfo?.first_name ?? ''} maxLength={50} onChange={handleInfoChange} />
            </div>
            <div className="basis-6/12">
              <label htmlFor="lastName">Last Name</label>
              <input id="lastName" type="text" name="last_name" value={contactInfo?.last_name ?? ''} maxLength={50} onChange={handleInfoChange} />
            </div>
          </fieldset>
          <fieldset>
            <label htmlFor="name">Role</label>
            <input id="title" type="text" name="title" value={contactInfo?.title ?? ''} maxLength={50} onChange={handleInfoChange} />
          </fieldset>
          <fieldset className="flex flex-nowrap gap-2.5">
            <div className="basis-6/12">
              <label htmlFor="email">Email</label>
              <input id="email" type="text" name="email" value={contactInfo?.email ?? ''} maxLength={50} onChange={handleInfoChange} />
            </div>
            <div className="basis-6/12">
              <label htmlFor="phone">Phone</label>
              <PatternFormat
                id="phone"
                name="phone"
                format="(###) ###-####"
                allowEmptyFormatting
                mask="_"
                value={contactInfo?.phone}
                onChange={handleInfoChange}
              />
            </div>
          </fieldset>
        </form>
        <footer className="modal__footer flex justify-end gap-2.5">
          <button className="button button--outline" type="button" name="cancel" onClick={handleAddEditCancel}>
            Cancel
          </button>
          <button className="button" type="button" name="save" data-loading={requesting} onClick={handleSave}>
            Save
          </button>
        </footer>
      </div>
    </aside>
  );
};

export default AddEditContactModal;

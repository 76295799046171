import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';

const renderInput = ({ input, type, meta: { touched, error }, children, placeholder, addonPosition, disabled }) => {
  return (
    <fieldset>
      {addonPosition === 'left' ? <div className="button--icon">{children}</div> : null}
      <input className="input--block input--noappearance" {...input} placeholder={placeholder} type={type} disabled={disabled} />
      {addonPosition === 'right' ? <div className="button--icon">{children}</div> : null}
      {touched && error && <small className="block text-error-500">{error}</small>}
    </fieldset>
  );
};

const InputGroup = ({
  name,
  children,
  type = 'text',
  label = null,
  placeholder = null,
  addonPosition = 'right',
  onChange,
  disabled = false,
  noMargin = false
}) => {
  return (
    <div className="text-left">
      {label && <label>{label}</label>}
      <Field
        name={name}
        type={type}
        component={renderInput}
        children={children}
        placeholder={placeholder}
        addonPosition={addonPosition}
        validate={matchValidatePropToValidators({ name, children, type, label, placeholder, addonPosition, onChange, disabled, noMargin })}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

InputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  addonPosition: PropTypes.oneOf(['left', 'right']),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  noMargin: PropTypes.bool
};

export default InputGroup;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import { Tooltip } from 'react-tippy';
import InputGroup from './InputGroup';
import RenderSelect from '@/components/Shared/Forms/ReduxSelect';

const PercentOrFixed = ({
  children,
  onChange,
  amountName = 'amount',
  name,
  priceSheetId,
  labSelected,
  jobsCount,
  markupType: initialMarkupType,
  amount: initialAmount,
  shippingType: initialShippingType
}) => {
  const [markupType, setMarkupType] = useState(initialMarkupType);
  const [amount, setAmount] = useState(initialAmount);
  const [shippingType, setShippingType] = useState(initialShippingType);

  const markupOptions = [
    { value: 'fixed_amount', label: 'Fixed Amount' },
    { value: 'fixed_percentage', label: 'Percent' }
  ];

  const handleMarkupChange = (select) => {
    const value = select.value;
    const newAmount = value === 'fixed_amount' ? '5' : '20';

    setMarkupType(value);
    setAmount(newAmount);

    if (onChange) {
      onChange({ amount: newAmount, markup_type: value });
    }
  };

  const handleShippingChange = (select) => {
    setShippingType(select);

    if (onChange) {
      onChange({ shipping_type: select });
    }
  };

  const amountOnChange = (event) => {
    const newAmount = event.target.value;
    setAmount(newAmount);

    if (onChange) {
      onChange({ amount: newAmount, markup_type: markupType });
    }
  };

  const shippingDisabled = labSelected ? !labSelected.allowBulkShipping : false;
  const shippingUpdateForbidden = priceSheetId && jobsCount > 0;
  const showShippingTooltip = shippingUpdateForbidden && !shippingDisabled;
  const markupTypeValue = markupType?.value ? markupType.value : markupType;

  useEffect(() => {
    setShippingType(initialShippingType);
  }, []);

  return (
    <div className="mb-14">
      <fieldset className="flex flex-col">
        <div className="flex justify-between nowrap">
          <label>Markup</label>
          <Tooltip {...{ title: 'Retail Price minus Base Cost', position: 'bottom-end', trigger: 'mouseenter', arrow: true, zIndex: 10000 }}>
            <a href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-question-circle-o" />
            </a>
          </Tooltip>
        </div>
        <div>
          <RenderSelect value={markupTypeValue} name={name} required={true} placeholder="Markup" options={markupOptions} onChange={handleMarkupChange} />
        </div>

        <InputGroup
          name={amountName}
          addonPosition={markupTypeValue === 'fixed_percentage' ? 'right' : 'left'}
          required
          disabled={markupTypeValue === ''}
          onChange={amountOnChange}
        >
          <span>{markupTypeValue === 'fixed_percentage' ? '%' : '$'}</span>
        </InputGroup>

        {priceSheetId && (
          <aside className="panel panel--neat panel--warning">
            <p className="m-0 text-body-sm">
              Updating the markup will overwrite <strong>all prices</strong>, including prices that were manually set.
            </p>
          </aside>
        )}
      </fieldset>

      <hr />

      <fieldset className="flex flex-col">
        <div className="flex justify-between nowrap">
          <label>Shipping</label>
          <Tooltip {...{ title: 'Select how you want products to be shipped.', position: 'bottom-end', trigger: 'mouseenter', arrow: true, zIndex: 10000 }}>
            <a href="https://support.photoday.io/en/articles/5099838-what-shipping-options-are-available" target="_blank" rel="noopener noreferrer">
              <i className="fa fa-question-circle-o" />
            </a>
          </Tooltip>
        </div>

        <div>
          <Tooltip
            title="This price sheet is assigned to a job and the shipping type can’t be modified"
            distance={5}
            position="top"
            trigger="mouseenter"
            arrow={true}
            disabled={!showShippingTooltip}
          >
            <RenderSelect
              disabled={shippingUpdateForbidden || shippingDisabled}
              classNamePrefix="select"
              name="shipping_type"
              required={true}
              placeholder="Shipping"
              value={shippingType}
              options={[
                { value: 'drop_shipping', label: 'Drop' },
                { value: 'bulk_shipping', label: 'Bulk' },
                { value: 'drop_or_bulk_shipping', label: 'Bulk & Drop' }
              ]}
              onChange={handleShippingChange}
            />
          </Tooltip>
        </div>
      </fieldset>

      {!priceSheetId && shippingType.value !== 'drop_shipping' && (
        <div className="panel panel--lean">
          <p className="text-left">
            <small>
              Some products may not be available to bulk ship.{' '}
              <a href="https://support.photoday.io/en/articles/4037868-product-availability-for-bulk-shipping" target="_blank" rel="noopener noreferrer">
                Learn more
              </a>{' '}
            </small>
          </p>
        </div>
      )}

      {priceSheetId && shippingType.value !== 'drop_shipping' && (
        <div className="panel panel--lean panel--warning">
          <p className="text-left m-0 text-body-sm">
            Changing shipping to “Bulk” or “Bulk & Drop” will remove certain products.{' '}
            <a
              href="https://support.photoday.io/en/articles/4037868-product-availability-for-bulk-shipping-miller-s-lab"
              target="_blank"
              rel="noopener noreferrer"
              className="text-type-base"
            >
              <strong>Learn more</strong>
            </a>{' '}
          </p>
        </div>
      )}
    </div>
  );
};

PercentOrFixed.propTypes = {
  children: PropTypes.element,
  onChange: PropTypes.func,
  amountName: PropTypes.string,
  name: PropTypes.string,
  priceSheetId: PropTypes.string,
  labSelected: PropTypes.object,
  jobsCount: PropTypes.number,
  markupType: PropTypes.string,
  amount: PropTypes.string,
  shippingType: PropTypes.object
};

export default PercentOrFixed;

import { FC, isValidElement, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getMyAccountStudioRequest } from '@/components/Settings/actions';

// Components
import BusinessVerificationBanner from '@/components/Shared/BusinessVerification/VerificationBanner';
import StripeExpressMigrationBanner from '@/components/Shared/StripeExpressMigration/MigrationBanner';

const WarningBanners: FC = () => {
  const dispatch = useDispatch();

  const {
    studio: { id: loginStudioId }
  } = useSelector((state: any) => state.login);

  const isBusinessVerificationBannerVisible: boolean = isValidElement(BusinessVerificationBanner({}));

  useEffect(() => {
    dispatch(getMyAccountStudioRequest({ studioId: loginStudioId }));
  }, []);

  return (
    <>
      {/* Business Verification Banner */}
      <BusinessVerificationBanner />

      {/* Stripe Express Migration Banner */}
      {!isBusinessVerificationBannerVisible && <StripeExpressMigrationBanner />}
    </>
  );
};

export default WarningBanners;

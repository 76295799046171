import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import matchValidatePropToValidators from './validators';
import matchNormalizePropToNormalizers from './normalize';

const renderInput = ({ input, type, loading, dataCy, readOnly, disabled, placeholder, autoComplete, meta: { touched, error } }) => {
  return (
    <div className={loading ? 'input--block-loading' : ''}>
      <input
        {...input}
        className="input--block"
        type={type}
        data-cy={dataCy}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
      {touched && (error || error) && <small className="block text-error-500">{error || error}</small>}
    </div>
  );
};

const Input = ({
  name,
  type = 'text',
  label = null,
  placeholder = null,
  autoComplete,
  disabled = false,
  loading = false,
  readOnly = false,
  onChange,
  error = null,
  transformValueOnChange,
  className,
  normalize,
  onBlur,
  required,
  dataCy
}) => {
  return (
    <fieldset className={className ?? ''}>
      {label && <label>{label}</label>}
      <Field
        name={name}
        type={type}
        component={renderInput}
        validate={matchValidatePropToValidators({ required: !!required })}
        normalize={normalize && ((value) => matchNormalizePropToNormalizers(normalize, value))}
        dataCy={dataCy}
        readOnly={readOnly}
        disabled={disabled || loading ? true : false}
        placeholder={placeholder}
        autoComplete={autoComplete}
        loading={loading}
        onChange={onChange}
        onBlur={onBlur}
      />
    </fieldset>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  loading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  transformValueOnChange: PropTypes.func,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  normalize: PropTypes.func,
  onBlur: PropTypes.func
};

export default Input;

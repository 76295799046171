import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Redux
import { getAllLabs, getStudioLabsInStudio, addStudioLabtoStudio } from '../../actions';

// Components
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Styles
import './../studio.css';

const Labs = () => {
  const dispatch = useDispatch();

  const { entities: loginEntities, studio: loginStudio, requesting: loginRequesting } = useSelector((state) => state.login);
  const { entities: usersEntities, studio: usersStudio, requesting: usersRequesting } = useSelector((state) => state.users);

  const requesting = loginRequesting || usersRequesting;

  const entities = Object.keys(usersEntities ?? {}).length ? usersEntities : loginEntities;

  const studio = Object.keys(usersStudio ?? {}).length ? usersStudio : loginStudio;
  const { id: studioId, lab_id: labId } = studio || {};

  const allLabs = entities ? (entities.allLabs ? Object.values(entities.allLabs) : []) : [];

  const [isLabSelected, setIsLabSelected] = useState(false);
  const [studioLabIds, setStudioLabIds] = useState([]);
  const [labIdLoading, setLabIdLoading] = useState('');

  const handleToggleLab = (labId) => {
    setLabIdLoading(labId);
    dispatch(
      addStudioLabtoStudio({ studio_id: studioId, lab_id: labId }, (res) => {
        const newLabId = res.lab?.id;
        setStudioLabIds([...studioLabIds, newLabId]);
      })
    );
  };

  useEffect(() => {
    dispatch(getAllLabs());
    dispatch(
      getStudioLabsInStudio(studioId, (res) => {
        const initialLabIds = Object.values(res).map((lab) => lab.lab.id);
        setStudioLabIds(initialLabIds);
      })
    );
  }, []);

  return (
    <>
      <header className="flex items-center justify-between my-studio__header">
        <h2 className="text-headline-sm">Add Labs</h2>
      </header>

      <section className="panel panel--section" data-cy="my-studio-labs-section">
        <p>To use a lab's product catalog in PhotoDay, simply select the labs you wish to add to your account.</p>
        {allLabs.length > 0 && (
          <ul className="labs__container animate">
            {allLabs.map((lab) => {
              const added = studioLabIds.indexOf(lab.id) >= 0;

              // TODO: Find a better way to disable labs from being added
              if (lab.key === 'bri' && !added) return null;

              return (
                <li className="lab" key={lab.id}>
                  <figure className="lab__logo-container">
                    <img className="lab__logo" src={lab.logo_url} alt="studio logo" />
                  </figure>
                  <button
                    className={`button lab__button ${added ? 'lab__button--selected' : ''}`}
                    disabled={labIdLoading === lab.id && requesting}
                    data-loading={labIdLoading === lab.id && requesting}
                    onClick={() => handleToggleLab(lab.id)}
                  >
                    {added ? 'Added' : 'Add'}
                  </button>
                </li>
              );
            })}
          </ul>
        )}

        {requesting && !allLabs.length && (
          <>
            <br />
            <br />
            <GridLoader rows={2} columns={4} gap={20} />
          </>
        )}
      </section>
    </>
  );
};

export default Labs;

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { object, array } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { PureComponent } from 'react';

// Plugins
import { ToastContainer } from 'react-toastify';
import { AsyncPaginate } from 'react-select-async-paginate';

// Components
import ExpiredSessionModal from '../ExpiredSessionModal';
import LoadingBar from 'react-redux-loading-bar';

// Helpers
import moment from 'moment';
import { logoutRequest } from '@/components/Login/actions';
import { getUserStudiosRequest, changeCurrentStudioRequest } from '../../Settings/actions';

// Images
import logo from '@/assets/images/image_PD_logo.svg';
import image_oto from '@/assets/images/image_PD_badge.svg';

// Styles
import './style.css';
import 'react-toastify/dist/ReactToastify.min.css';

const mapStateToProps = (state) => {
  const {
    login: { user, studio },
    users: {
      studios: { list: studiosList, pagination: studiosPagination }
    }
  } = state;

  return { user, studiosList, studiosPagination, studio };
};

const mapDispatchToProps = {
  logoutRequest,
  getUserStudiosRequest,
  changeCurrentStudioRequest
};

class Header extends PureComponent {
  state = {
    currentStudio: { value: '', label: '' },
    isResponsiveNavOpen: false,
    isLegalOpen: false
  };

  currentYear = moment().format('YYYY');

  static propTypes = {
    location: object,
    user: object,
    studiosList: array,
    studiosPagination: object,
    studio: object
  };

  static defaultProps = {
    location: {},
    user: {},
    studiosList: [],
    studiosPagination: {},
    studio: {}
  };

  componentDidMount() {
    const { studio } = this.props;

    if (studio?.id) {
      const name = studio.reporting_code ? `${studio.name} (${studio.reporting_code})` : studio.name;
      this.setState({ currentStudio: { value: studio.id, label: name } });
    }
  }

  logout = () => {
    const { logoutRequest } = this.props;
    logoutRequest();
  };

  toggleResponsiveNav = () => {
    this.setState({
      isResponsiveNavOpen: !this.state.isResponsiveNavOpen,
      isLegalOpen: false
    });
  };

  toggleLegal = () => {
    this.setState({ isLegalOpen: !this.state.isLegalOpen });
  };

  loadStudioOptions = async (search) => {
    const { studiosPagination } = this.props;

    const response = await new Promise((resolve) => {
      this.props.getUserStudiosRequest(
        { slim: true, order: 'name', search, per_page: 100, page: search ? 1 : studiosPagination.page + 1 },
        ({ headers, data }) => {
          const pagination = { page: Number(headers['x-page']), perPage: Number(headers['x-per-page']), total: Number(headers['x-total']) };

          let options = data.map((studio) => {
            const name = studio.reporting_code ? `${studio.name} (${studio.reporting_code})` : studio.name;
            return { value: studio.id, label: name };
          });

          if (pagination.page === 1) {
            options.unshift({ label: 'All Studios', value: 'all-studios' });
          }

          resolve({ options, pagination });
        }
      );
    });

    const { options, pagination } = response;

    return {
      options,
      hasMore: pagination.total / pagination.perPage > pagination.page
    };
  };

  changeCurrentStudio = (option) => {
    if (option && option.value) {
      const { history, studiosList, changeCurrentStudioRequest } = this.props;
      const studio = studiosList.find((row) => row.id === option.value) || { id: 'all-studios', name: 'All Studios' };

      changeCurrentStudioRequest(studio, () => {
        history.replace('/home');
        history.go(0);
      });
    }
  };

  render() {
    const { user } = this.props;
    const roles = user.roles || [];
    const isAdminUser = user.is_photoday || roles.includes('studios_admin') ? true : false;
    const { currentStudio, isResponsiveNavOpen, isLegalOpen } = this.state;

    return (
      <>
        <ToastContainer />

        <ExpiredSessionModal />

        <header className={`topnav ${isResponsiveNavOpen ? 'topnav--active' : ''}`}>
          <div className="topnav__header topnav__hide-desktop">
            <NavLink className="topnav__logo" to="/home">
              <img src={image_oto} alt="PhotoDay" />
            </NavLink>
            <i className={`hamburger-menu ${isResponsiveNavOpen ? 'hamburger-menu--active' : ''}`} onClick={this.toggleResponsiveNav}>
              <span />
              <span />
              <span />
              <span />
            </i>
          </div>

          <nav className={`container topnav__container ${isResponsiveNavOpen ? 'topnav__container--active' : ''}`}>
            <NavLink className="topnav__logo topnav__hide-mobile" to="/home" onClick={this.toggleResponsiveNav}>
              <img src={logo} alt="PhotoDay" />
            </NavLink>

            <ul className="topnav__main" onClick={this.toggleResponsiveNav}>
              <li className="topnav__item">
                <NavLink to="/home" className="topnav__link" activeClassName="topnav__link--active" draggable="false">
                  Home
                </NavLink>
              </li>
              <li className="topnav__item">
                <NavLink to="/storefront" className="topnav__link" activeClassName="topnav__link--active" draggable="false">
                  Store
                </NavLink>
              </li>
              {roles.includes('view_jobs') && (
                <li className="topnav__item" data-cy="topnav-jobs-link">
                  <NavLink to="/jobs" className="topnav__link" activeClassName="topnav__link--active" draggable="false">
                    Jobs
                  </NavLink>
                </li>
              )}
              {roles.includes('view_clients') && currentStudio.value !== 'all-studios' && (
                <li className="topnav__item" data-cy="topnav-organizations-link">
                  <NavLink to="/organizations" className="topnav__link" activeClassName="topnav__link--active" draggable="false">
                    Organizations
                  </NavLink>
                </li>
              )}
              {currentStudio.value !== 'all-studios' && roles.includes('view_sales') && (
                <li className="topnav__item">
                  <NavLink to="/reports" className="topnav__link" activeClassName="topnav__link--active" draggable="false">
                    Reports
                  </NavLink>
                </li>
              )}
            </ul>

            <ul className="topnav__user" onClick={this.toggleResponsiveNav}>
              {isAdminUser && (
                <li className="topnav__item topnav__item--admin">
                  <AsyncPaginate
                    className="select select--nomargin header-studio-select topnav__hide-mobile"
                    classNamePrefix="select"
                    name="studio"
                    placeholder="Select a Studio..."
                    value={currentStudio}
                    loadOptions={this.loadStudioOptions}
                    onChange={this.changeCurrentStudio}
                  />
                </li>
              )}
              <li className="topnav__item">
                <NavLink to="/collateral/sales" className="topnav__link" activeClassName="topnav__link--active" draggable="false">
                  Resources
                </NavLink>
              </li>
              <li className="topnav__item" data-cy="topnav-settings-link">
                <NavLink to="/user" className="topnav__link" activeClassName="topnav__link--active" draggable="false">
                  Settings
                </NavLink>
              </li>
              <li className="topnav__item" data-cy="cy-logout-button" onClick={this.logout}>
                Logout
              </li>
            </ul>

            <aside className="topnav__legal-container">
              <p className="topnav__section-title" onClick={this.toggleLegal}>
                Legal
              </p>

              <ul className={`topnav__legal ${isLegalOpen ? 'topnav__legal--active' : ''}`} onClick={this.toggleResponsiveNav}>
                <li className="topnav__item">
                  <a href="https://photoday.io/terms" className="topnav__link" target="_blank" rel="noopener noreferrer">
                    Terms of Service
                  </a>
                </li>
                <li className="topnav__item">
                  <a href="https://www.photoday.io/brand_guidelines/" className="topnav__link" target="_blank" rel="noopener noreferrer">
                    Brand Guidelines
                  </a>
                </li>
              </ul>
              {isLegalOpen && <span className="topnav__item--legal">&copy; {`${this.currentYear} PhotoDay`}</span>}
            </aside>
          </nav>
        </header>
        <LoadingBar
          className="loading-bar"
          style={{ position: 'absolute', height: '5px', top: 0, borderRadius: '2.5px', backgroundColor: '#22bffd', zIndex: 1000 }}
        />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

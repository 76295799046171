import * as types from './constants';
import { handleErrorState, handleRequestingState, handleSuccessState } from '@/utils/reducerHelpers';
import tagsSorter from './Gallery/Tags/Shared/tagsSorter';

// Types
import { QrCodeResolutionMethod } from '@/types';

const initialState = {
  job: {
    setup_steps: {
      published: false,
      storefront: false,
      gallery_access: false,
      exports_created: false,
      uploaded_photos: false,
      uploaded_subjects: false
    },
    photo_stats: {},
    priceSheets: [],
    subjectsSummary: {},
    requesting: false
  },
  jobs: {
    list: [],
    pagination: { page: 1, perPage: 15, total: 0 },
    requesting: false
  },
  timezones: [],
  smsTimes: [],
  jobTypes: [
    { value: 'youth_sports', label: 'Youth Sports' },
    { value: 'high_school_sports', label: 'High School Sports' },
    { value: 'dance_schools', label: 'Dance Schools' },
    { value: 'preschool', label: 'Preschools' },
    { value: 'elementary_school', label: 'Elementary Schools' },
    { value: 'underclass', label: 'Underclass' },
    { value: 'seniors', label: 'Seniors' },
    { value: 'graduation', label: 'Graduation' },
    { value: 'portraits', label: 'Portraits' },
    { value: 'weddings', label: 'Weddings' },
    { value: 'events', label: 'Events' },
    { value: 'other', label: 'Other' }
  ],

  query: {
    tags: 'all',
    people: 'all'
  },

  photo: {},
  photos: {
    list: [],
    pagination: { page: 1, perPage: 100, total: 0 },
    requesting: false,
    successful: false
  },
  photoTypeOptions: [],

  tags: {
    list: [],
    requesting: false
  },
  people: {
    list: [],
    listByPhotoId: [],
    sidebarList: [],

    subject: {},

    fields: {},
    fieldsMap: [
      { value: 'student_id', label: 'Student ID', map: '' },
      { value: 'first_name', label: 'First Name', map: '' },
      { value: 'last_name', label: 'Last Name', map: '' },
      { value: 'image_name', label: 'Image Name', map: '' },
      { value: 'email', label: 'Email', map: '' },
      { value: 'email_2', label: 'Email 2', map: '' },
      { value: 'email_3', label: 'Email 3', map: '' },
      { value: 'phone', label: 'Mobile Phone', map: '' },
      { value: 'teacher_name', label: 'Teacher', map: '' },
      { value: 'grade', label: 'Grade', map: '' },
      { value: 'period', label: 'Period', map: '' },
      { value: 'home_room', label: 'Homeroom', map: '' },
      { value: 'school', label: 'School', map: '' },
      { value: 'instructor', label: 'Instructor', map: '' },
      { value: 'coach', label: 'Coach', map: '' },
      { value: 'league', label: 'League', map: '' },
      { value: 'team', label: 'Team', map: '' },
      { value: 'division', label: 'Division', map: '' },
      { value: 'event', label: 'Event', map: '' },
      { value: 'organization', label: 'Organization', map: '' },
      { value: 'sport_type', label: 'Sport Type', map: '' },
      { value: 'session_start', label: 'Check-in Date', map: '' },
      { value: 'other', label: 'Other', map: '' },
      { value: 'other_2', label: 'Other 2', map: '' },
      { value: 'other_3', label: 'Other 3', map: '' }
    ],
    fieldsMapDetails: { currentCount: 0, willUpdate: 0, willAdd: 0 },

    lookupConfig: {},
    lookupConfigValidate: [],
    csvColumnConfig: [],

    labels: [],
    email: { type: '', people: [], groups: [] },

    pagination: { page: 1, perPage: 15, total: 0 },
    requesting: false
  },

  faces: {
    list: [],
    requesting: false
  },

  insights: {
    customers: {
      list: [],
      pagination: { page: 1, perPage: 15, total: 0 }
    },
    customersSummary: {},
    accessedSubjectsSummary: {},
    yearbookSubjectsSummary: {},
    sales: {
      list: [],
      pagination: { page: 1, perPage: 15, total: 0 }
    },
    salesSummary: {},
    requesting: false
  },

  upload: {
    jobId: '',

    failed: [],
    rejected: [],
    errored: [],
    duplicated: [],

    time: [],
    queue: [],

    total: 0,
    current: 0,
    successful: 0,

    hold: false,
    cancel: false,
    retry: false,
    replace: false,
    retainAttributes: false,
    keywords: true,
    processing: false,
    requesting: false
  },

  knockoutUpload: {
    knockoutId: '',

    failed: [],
    rejected: [],
    duplicated: [],

    time: [],
    queue: [],

    total: 0,
    current: 0,
    successful: 0,
    completed: 0,

    hold: false,
    cancel: false,
    skip: false,
    retry: false,
    replace: false,
    keywords: true,
    processing: false,
    requesting: false,
    dismiss: false
  },

  flyer: {},
  flyers: {
    list: [],
    pagination: { page: 1, perPage: 15, total: 0 },
    requesting: false
  },

  export: {},
  exports: {
    list: [],
    exportItems: [],
    pagination: { page: 1, perPage: 15, total: 0 },
    requesting: false
  },

  knockout: {},
  knockouts: {
    list: [],
    knockoutItems: {
      list: [],
      sort: { order: 'filename', dir: 'asc' },
      pagination: { page: 1, perPage: 15, total: 0 }
    },
    pagination: { page: 1, perPage: 15, total: 0 },
    requesting: false
  },

  unsubscribes: [],

  marketingMessages: [],

  sessionQrCodes: [],

  errors: [],
  requesting: false,
  successful: false
};

// Global
const handleJobsRequestingState = (state, key) => ({
  ...state,
  [key]: {
    ...state[key],
    requesting: true
  },
  ...{ requesting: true, successful: false, errors: [] }
});

const handleJobsSuccessState = (state, key) => ({
  ...state,
  [key]: {
    ...state[key],
    requesting: false
  },
  ...{ requesting: false, successful: true, errors: [] }
});

const handleJobsErrorState = (state, payload, key) => {
  const { error } = payload;
  const { message, response } = error;
  const { status, data } = response || {};
  const errorBody = data ? data : message;
  const errors = state.errors.concat([
    {
      code: status || 500,
      body: errorBody,
      time: new Date()
    }
  ]);

  return {
    ...state,
    [key]: {
      ...state[key],
      requesting: false
    },
    ...{ errors, requesting: false, successful: false }
  };
};

// Jobs
const handleGetJobListSuccess = (state, { payload }) => {
  const { job: stateJob } = state;
  const { data: payloadJobs } = payload;

  // Update current state job with fresh fetched from job list
  const foundJob = payloadJobs.find((job) => job.id === stateJob.id);

  return {
    ...state,
    job: { ...stateJob, ...foundJob },
    jobs: {
      list: payload.data,
      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      },
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobTimezoneListSuccess = (state, { payload }) => {
  return {
    ...state,
    timezones: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobSmsTimeListSuccess = (state, { payload }) => {
  return {
    ...state,
    smsTimes: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobSuccess = (state, { payload }) => {
  return {
    ...state,
    job: { ...payload.data, requesting: false },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobPriceSheetsSuccess = (state, { payload }) => {
  return {
    ...state,
    job: { ...state.job, priceSheets: payload.data, requesting: false },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobUnsubscribeListSuccess = (state, { payload }) => {
  return {
    ...state,
    unsubscribes: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobSubjectsSummarySuccess = (state, { payload }) => {
  return {
    ...state,
    job: { ...state.job, subjectsSummary: { ...payload.data }, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateJobSuccess = (state, { payload }) => {
  return {
    ...state,
    job: { ...payload.data, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleRestoreJobSuccess = (state, { payload }) => {
  const newJobsList = state.jobs.list.filter((job) => job.id !== payload.data.id);

  return {
    ...state,
    jobs: { ...state.jobs, list: newJobsList },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleResetJob = (state, initialState) => {
  const { timezones, smsTimes, knockoutUpload } = state;

  return {
    ...initialState,
    timezones,
    smsTimes,
    knockoutUpload: { ...knockoutUpload, completed: knockoutUpload.queue.length + knockoutUpload.successful }
  };
};

// Photos
const handleGetPhotoListSuccess = (state, { payload }) => {
  const { data: photos, headers, reset } = payload;

  return {
    ...state,
    photos: {
      list: reset ? photos : [...state.photos.list, ...photos],
      pagination: {
        page: Number(headers['x-page']),
        perPage: Number(headers['x-per-page']),
        total: Number(headers['x-total'])
      },
      requesting: false,
      successful: true
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPhotoSuccess = (state, { payload }) => {
  return {
    ...state,
    photo: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeletePhotoSuccess = (state, { payload }) => {
  const { search, photo_ids, perform_all, filter_tags, subject_id, last_uploaded, untagged, unmatched_faces, has_transparency, unselected_photo_ids } = payload;

  const allPhotosDeleted =
    !search &&
    untagged === null &&
    filter_tags === null &&
    subject_id === null &&
    last_uploaded === null &&
    unmatched_faces === null &&
    has_transparency === null &&
    unselected_photo_ids === null &&
    (perform_all || photo_ids.length === state.photos.pagination.total);

  return {
    ...state,
    photos: { ...state.photos },
    job: { ...state.job, setup_steps: { ...state.job.setup_steps, uploaded_photos: allPhotosDeleted === false } },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdatePhotoGallery = (state, { payload }) => {
  const {
    job: stateJob,
    photos: statePhotos,
    query: { tags: stateTagsQuery, people: statePeopleQuery }
  } = state;
  const { photo: payloadPhoto } = payload;
  const { list: statePhotoList } = statePhotos;

  const sortPhotos = (photos, order) => {
    switch (order) {
      case 'alpha_asc':
        return photos.sort((a, b) => a.image_filename.localeCompare(b.image_filename));
      case 'alpha_desc':
        return photos.sort((a, b) => b.image_filename.localeCompare(a.image_filename));
      case 'upload_asc':
        return photos.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
      case 'upload_desc':
        return photos.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      default:
        return photos;
    }
  };

  const updatedPhotoList = statePhotoList
    // Check if payloadPhoto is already in the gallery
    .some((photo) => photo.id === payloadPhoto.id || photo.image_filename === payloadPhoto.image_filename)
    ? // If so update/mutate it
      statePhotoList.map((photo) => (photo.id === payloadPhoto.id ? payloadPhoto : photo))
    : // Otherwise add it respecting job sort type order
      // Avoid gallery live photo updates if any Tag or Subject is selected
      stateTagsQuery === 'all' && statePeopleQuery === 'all'
      ? sortPhotos([...statePhotoList, payloadPhoto], stateJob.sort_type)
      : statePhotoList;

  return {
    ...state,
    job: { ...stateJob, setup_steps: { ...stateJob.setup_steps, uploaded_photos: true }, requesting: false },
    photos: {
      ...statePhotos,
      list: updatedPhotoList
    }
  };
};

const handleUpdatePhotoTrainedAndRemoveQrImages = (state, { payload }) => {
  const { photo_ids: payloadPhotoIds, qr_photo_ids: payloadQrPhotoIds } = payload;
  const { photos: statePhotos } = state;
  const { list: statePhotoList } = statePhotos;

  const updatedPhotoList = statePhotoList
    .filter((photo) => payloadQrPhotoIds.includes(photo.id) === false)
    .map((photo) => (payloadPhotoIds.includes(photo.id) ? { ...photo, trained: true } : photo));

  return {
    ...state,
    photos: {
      ...statePhotos,
      list: updatedPhotoList
    }
  };
};

const handleUpdatePhotoStats = (state, { payload }) => {
  const { stats } = payload;

  return {
    ...state,
    job: {
      ...state.job,
      photo_stats: stats
    }
  };
};

const handleUpdatePhotoTags = (state, { payload }) => {
  const { tags: payloadTags } = payload;
  const { job: stateJob, tags: stateTags } = state;
  const updatedTagList = tagsSorter(payloadTags, stateJob.tag_sort_type);

  return {
    ...state,
    tags: {
      ...stateTags,
      list: updatedTagList
    }
  };
};

const handleUpdatePhotoDropzone = (state, { payload }) => {
  const { rejected: stateRejected, errored: stateErrored } = state.upload;
  const { rejected: payloadRejected, errored: payloadErrored } = payload;

  return {
    ...state,
    upload: {
      ...state.upload,
      ...payload,
      ...(payloadRejected ? { rejected: payloadRejected.length > 0 ? [...stateRejected, ...payloadRejected] : [] } : {}),
      ...(payloadErrored ? { errored: payloadErrored.length > 0 ? [...stateErrored, ...payloadErrored] : [] } : {})
    }
  };
};

const handleSetPhotoQueryComponent = (state, { payload }) => {
  const { search: stateSearch, query: stateQuery } = state;

  return {
    ...state,
    query: { ...stateQuery, ...stateSearch, ...payload }
  };
};

const handleUpdatePhotos = (state, { payload }) => {
  const {
    attributes: { forbid_download },
    photo_ids
  } = payload;

  const newPhotoList = state.photos.list.map((photo) =>
    photo_ids?.includes(photo.id) ? { ...photo, forbid_download: forbid_download === true ? true : false } : photo
  );

  return {
    ...state,
    photos: {
      ...state.photos,
      list: newPhotoList,
      requesting: false,
      successful: true
    }
  };
};

const handleGetPhotoTypeOptions = (state, { payload }) => {
  return {
    ...state,
    photoTypeOptions: payload.data,
    errors: [],
    successful: true,
    requesting: false
  };
};

// Tags
const handleGetTagListSuccess = (state, { payload }) => {
  return {
    ...state,
    tags: {
      list: payload.data,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateTagSuccess = (state, { payload }) => {
  const { tag: payloadTag } = payload;
  const {
    job: stateJob,
    tags: { list: stateTags }
  } = state;

  // Consider job tag sort position before add new tag into list
  const newTag = { name: payloadTag, photo_count: 0, display_priority: null };
  const updatedTagList = tagsSorter([...stateTags, newTag], stateJob.tag_sort_type);

  return {
    ...state,
    tags: {
      list: updatedTagList,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateTagAssociationSuccess = (state, { payload }) => {
  const { replace, unselected_photo_ids, untagged, perform_all, photo_ids, tags, filter_tags } = payload;

  const newTags = tags.map((tag) => ({ name: tag }));
  let newPhotosList = state.photos.list;

  if (perform_all) {
    newPhotosList.forEach((photo) => {
      if (!unselected_photo_ids?.includes(photo.id)) {
        const newPhotoTags = replace ? (photo.tags = newTags) : (photo.tags = [...photo.tags, ...newTags]);

        return { ...photo, tags: newPhotoTags };
      }
      return photo;
    });
  } else {
    newPhotosList.forEach((photo) => {
      if (photo_ids?.includes(photo.id)) {
        const newPhotoTags = replace
          ? (photo.tags = newTags)
          : (photo.tags = [...photo.tags, ...newTags.filter((tag) => !photo.tags.some((photoTag) => photoTag.name === tag.name))]);

        return { ...photo, tags: newPhotoTags };
      }
      return photo;
    });
  }

  if (untagged) {
    newPhotosList = newPhotosList.filter((photo) => photo.tags?.length === 0);
  }

  newPhotosList =
    !filter_tags || (filter_tags && !filter_tags.length) || untagged
      ? newPhotosList
      : newPhotosList.filter((photo) => photo.tags.some((tag) => filter_tags?.includes(tag.name)));

  const listDiff = state.photos.list.length - newPhotosList.length;
  const newPagination = { ...state.photos.pagination, total: listDiff ? state.photos.pagination.total - listDiff : state.photos.pagination.total };

  return {
    ...state,
    photos: {
      ...state.photos,
      list: newPhotosList,
      pagination: newPagination
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleRenameTagSuccess = (state, { payload }) => {
  const newTagList = [...state.tags.list];
  const foundTagIndex = newTagList.findIndex((tag) => tag.name === payload.tag.old);

  if (foundTagIndex >= 0) {
    newTagList[foundTagIndex].name = payload.tag.new;
  }

  return {
    ...state,
    tags: {
      list: newTagList,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleSortTagSuccess = (state, { payload }) => {
  const { list } = payload;
  const sortedTags = list.sort((a, b) => a.display_priority - b.display_priority);

  return {
    ...state,
    tags: {
      list: sortedTags,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteTagSuccess = (state, { payload }) => {
  const {
    job,
    tags: { list: tagsList },
    photos: { list: photoList }
  } = state;

  const deletedTag = tagsList.find((tag) => tag.name === payload.tag);
  const updatedPhotos = photoList.map((photo) => ({ ...photo, tags: photo.tags.filter((tag) => tag.name !== payload.tag) }));

  return {
    ...state,
    job: { ...job, photo_stats: { ...job.photo_stats, photos_untagged: Number(job.photo_stats.photos_untagged + deletedTag.photo_count) } },
    tags: {
      list: state.tags.list.filter((tag) => tag.name !== payload.tag),
      requesting: false
    },
    photos: {
      ...state.photos,
      list: updatedPhotos
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

// People
const handleCreatePeopleSuccess = (state) => {
  const { job } = state;

  return {
    ...state,
    job: {
      ...job,
      setup_steps: {
        ...job.setup_steps,
        uploaded_subjects: true
      }
    },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreatePeopleAssociationSuccess = (state, { payload }) => {
  const { photo_face_ids: faceIds } = payload;

  return {
    ...state,
    faces: {
      list: state.faces.list.map((face) => ({
        ...face,
        matched: faceIds?.includes(face.id) ? true : face.matched,
        ignored: faceIds?.includes(face.id) ? false : face.ignored
      })),
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreatePeopleAssociationPhotosSuccess = (state, { payload }) => {
  const { subject_ids, photo_ids } = payload;
  const { people: peopleQuery } = state.query;
  const {
    photos: { list: statePhotoList }
  } = state;

  const newPhotoList = statePhotoList.map((photo) => {
    if (!photo_ids?.includes(photo.id)) {
      return photo;
    } else {
      const newSubjectIds = photo.subject_ids;

      subject_ids.forEach((id) => !newSubjectIds?.includes(id) && newSubjectIds.push(id));
      return {
        ...photo,
        subject_ids: newSubjectIds,
        subject_count: newSubjectIds.length,
        matched: photo.face_count - photo.face_ignored_count === newSubjectIds.length
      };
    }
  });

  let filteredPhotoList = newPhotoList;

  // Filter OUT over/under matched solved photos
  if (peopleQuery === 'overmatched') {
    filteredPhotoList = newPhotoList.filter((photo) => photo.subject_count > 0 && photo.subject_count > Number(photo.face_count - photo.face_ignored_count));
  }

  if (peopleQuery === 'undermatched') {
    filteredPhotoList = newPhotoList.filter((photo) => photo.subject_count > 0 && photo.subject_count < Number(photo.face_count - photo.face_ignored_count));
  }

  // Filter OUT unmatched photos
  if (peopleQuery === 'unmatched') {
    filteredPhotoList = newPhotoList.filter((photo) => photo.subject_count === 0);
  }

  return {
    ...state,
    photos: { ...state.photos, list: filteredPhotoList, requesting: false, successful: true },
    people: { ...state.people, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreatePeopleCsvDetailsSuccess = (state, { payload }) => {
  const { current_count, will_update, will_add } = payload.data;

  return {
    ...state,
    people: {
      ...state.people,
      fieldsMapDetails: { currentCount: current_count, willUpdate: will_update, willAdd: will_add },

      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPeopleListSuccess = (state, { payload }) => {
  return {
    ...state,
    people: {
      ...state.people,
      list: payload.data,

      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      },
      requesting: false
    },
    photos: {
      ...state.photos
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetSidebarPeopleListSuccess = (state, { payload }) => {
  return {
    ...state,
    people: {
      ...state.people,
      sidebarList: payload.data,

      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      },
      requesting: false
    },
    photos: {
      ...state.photos
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPeopleFacesListSuccess = (state, { payload }) => {
  return {
    ...state,
    faces: {
      list: payload.data,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPeoplePhotoFacesListSuccess = (state, { payload }) => {
  return {
    ...state,
    faces: {
      list: payload.data,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPeopleFieldOptionsSuccess = (state, { payload }) => {
  return {
    ...state,
    people: {
      ...state.people,
      fields: payload.data,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPeopleByPhotoIdSuccess = (state, { payload }) => {
  const listByPhotoId = payload.data.map((person) => person.id);

  return {
    ...state,
    people: { ...state.people, listByPhotoId, requesting: false },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetSubjectSuccess = (state, { payload }) => {
  return {
    ...state,
    people: { ...state.people, subject: payload.data, requesting: false },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdatePeopleSuccess = (state, { payload }) => {
  const { data } = payload;
  const { people } = state;

  const foundPeopleIndex = people.list.findIndex((person) => person.id === data.id);
  const updatedPeopleList = Object.assign([], people.list, { [foundPeopleIndex]: data });

  const foundSidebarPeopleIndex = people.sidebarList.findIndex((person) => person.id === data.id);
  const updatedSidebarPeopleList = Object.assign([], people.sidebarList, { [foundSidebarPeopleIndex]: data });

  return {
    ...state,
    people: {
      ...state.people,
      list: updatedPeopleList,
      sidebarList: updatedSidebarPeopleList,
      requesting: false
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleUpdatePeopleFacesSuccess = (state, { payload }) => {
  const {
    faces,
    query: { people: peopleQuery },
    photos: { list }
  } = state;
  const { data } = payload;
  const { ignored, photo_id } = data;
  const faceIndex = faces.list.findIndex((face) => face.id === data.id);
  const photoIndex = list.findIndex((photo) => photo.id === photo_id);

  let newPhotosList = list;

  if (peopleQuery === 'unmatched' && photoIndex >= 0) {
    let sliceIndex;

    newPhotosList = list.map((photo, index) => {
      if (photo.id === photo_id) {
        // update photo if in current list
        const newFaceIgnored = ignored ? photo.face_ignored_count + 1 : photo.face_ignored_count - 1;
        const matched = photo.face_count - newFaceIgnored === photo.subject_ids.length;
        if (matched) sliceIndex = index;

        return { ...photo, face_ignored_count: newFaceIgnored, matched };
      }

      return photo;
    });

    if (sliceIndex >= 0) {
      // remove photo from list if peopleQuery is unmatched and photo has been matched
      newPhotosList = [...newPhotosList.slice(0, sliceIndex), ...newPhotosList.slice(sliceIndex + 1)];
    }
  }

  return {
    ...state,
    photos: { ...state.photos, list: newPhotosList },
    faces: {
      list: [...faces.list.slice(0, faceIndex), data, ...faces.list.slice(faceIndex + 1)],
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleSetPeopleDetails = (state, { payload }) => {
  const { subjects } = payload;
  const { list, sidebarList } = state.people;

  return {
    ...state,
    people: {
      ...state.people,
      list: [...list.filter((person) => !subjects.some((item) => item.id === person.id)), ...subjects].sort((a, b) => (a.last_name > b.last_name ? 1 : -1)),
      sidebarList: [...sidebarList.filter((person) => !subjects.some((item) => item.id === person.id)), ...subjects].sort((a, b) =>
        a.last_name > b.last_name ? 1 : -1
      ),
      requesting: false
    }
  };
};

const handleSetPeopleMail = (state, { payload }) => {
  return {
    ...state,
    people: { ...state.people, email: payload, requesting: false }
  };
};

const handleSetPeoplePhotosCount = (state, { payload }) => {
  const { photo: payloadPhoto, subjects: payloadSubjects } = payload;
  const { people: peopleQuery } = state.query;
  const { list: photosList } = state.photos;
  const { list: peopleList, sidebarList } = state.people;

  let photosListUpdated = photosList;

  // Remove unmatched photo if filter is unmatched
  if (peopleQuery === 'unmatched' && payloadPhoto.matched === true) {
    photosListUpdated = photosList.filter((photo) => photo.id !== payloadPhoto.id);
  }

  const newPeopleList = peopleList.map((person) => ({
    ...person,
    photos_count: payloadSubjects.some((subject) => subject.id === person.id)
      ? payloadSubjects.find((subject) => subject.id === person.id).photos_count
      : person.photos_count
  }));

  const newSidebarList = sidebarList.map((person) => ({
    ...person,
    photos_count: payloadSubjects.some((subject) => subject.id === person.id)
      ? payloadSubjects.find((subject) => subject.id === person.id).photos_count
      : person.photos_count
  }));

  return {
    ...state,
    photos: { ...state.photos, list: photosListUpdated },
    people: {
      ...state.people,
      list: newPeopleList,
      sidebarList: newSidebarList,
      requesting: false
    }
  };
};

const handleDeletePhotoPeopleSuccess = (state, { payload }) => {
  const { data: payloadPhoto, subject_ids: targetSubjectIds } = payload;
  const { people: peopleQuery } = state.query;
  const {
    photos: { list: statePhotoList }
  } = state;

  const newPhotoList = statePhotoList.map((photo) => {
    if (payloadPhoto.id !== photo.id) {
      return photo;
    } else {
      return { ...photo, subject_count: payloadPhoto.subject_count, subject_ids: payloadPhoto.subject_ids };
    }
  });

  let filteredPhotoList = newPhotoList;

  if (peopleQuery && targetSubjectIds?.includes(peopleQuery)) {
    filteredPhotoList = newPhotoList.filter((photo) => photo.id !== payloadPhoto.id);
  }

  // Filter OUT over/under matched solved photos
  if (peopleQuery === 'overmatched') {
    filteredPhotoList = newPhotoList.filter((photo) => photo.subject_count > 0 && photo.subject_count > Number(photo.face_count - photo.face_ignored_count));
  }

  if (peopleQuery === 'undermatched') {
    filteredPhotoList = newPhotoList.filter((photo) => photo.subject_count > 0 && photo.subject_count < Number(photo.face_count - photo.face_ignored_count));
  }

  // Filter OUT unmatched photos
  if (peopleQuery === 'unmatched') {
    filteredPhotoList = newPhotoList.filter((photo) => photo.subject_count === 0);
  }

  const newPeopleList = state.people.list.map((person) => {
    if (targetSubjectIds?.includes(person.id)) {
      return { ...person, photos_count: person.photos_count - 1 };
    } else {
      return person;
    }
  });

  const newSidebarList = state.people.sidebarList.map((person) => {
    if (targetSubjectIds?.includes(person.id)) {
      return { ...person, photos_count: person.photos_count - 1 };
    } else {
      return person;
    }
  });

  return {
    ...state,
    photos: { ...state.photos, list: filteredPhotoList, requesting: false, successful: true },
    people: { ...state.people, list: newPeopleList, sidebarList: newSidebarList, requesting: false },

    errors: [],
    requesting: false,
    successful: true
  };
};

const handleDeletePeoplePhotoSuccess = (state, { payload }) => {
  const { id: subjectId, photo_ids: removeIds, unselected_photo_ids: keepIds, perform_all, data } = payload;
  const { list: photosList, pagination } = state.photos;
  const { list: peopleList, sidebarList } = state.people;

  const newPhotoList = perform_all ? photosList.filter((photo) => keepIds?.includes(photo.id)) : photosList.filter((photo) => !removeIds.includes(photo.id));

  const newPeopleList = peopleList.map((person) => ({ ...person, photos_count: person.id === subjectId ? data.photos_count : person.photos_count }));
  const newSidebarList = sidebarList.map((person) => ({ ...person, photos_count: person.id === subjectId ? data.photos_count : person.photos_count }));
  const paginationTotal = data.photos_count;

  return {
    ...state,

    photos: { ...state.photos, list: newPhotoList, pagination: { ...pagination, total: paginationTotal }, requesting: false, successful: true },
    people: { ...state.people, list: newPeopleList, sidebarList: newSidebarList, requesting: false },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleSetPeopleLabels = (state, { payload }) => {
  return {
    ...state,
    people: { ...state.people, labels: payload, requesting: false }
  };
};

const handleCreatePeopleLookupConfigSuccess = (state, { payload }) => {
  const payloadData = payload.data;
  const { people: statePeople } = state;

  return {
    ...state,
    people: { ...statePeople, lookupConfig: payloadData, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreatePeopleLookupValidateSuccess = (state, { payload }) => {
  const payloadData = payload.data;
  const { people: statePeople } = state;

  return {
    ...state,
    people: { ...statePeople, lookupConfigValidate: payloadData, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPeopleLookupConfigSuccess = (state, { payload }) => {
  const payloadData = payload.data;
  const { people: statePeople } = state;

  return {
    ...state,
    people: { ...statePeople, lookupConfig: payloadData, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetPeopleCsvColumnSuccess = (state, { payload }) => {
  const payloadData = payload.data;
  const { people: statePeople } = state;

  return {
    ...state,
    people: { ...statePeople, csvColumnConfig: payloadData, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleResetPeopleLookupValidate = (state) => {
  const { people: statePeople } = state;

  return {
    ...state,
    people: { ...statePeople, lookupConfigValidate: [] }
  };
};

// Insights
const handleInsightsCustomersSummarySuccess = (state, { payload }) => {
  return {
    ...state,

    insights: { ...state.insights, customersSummary: payload, requesting: false },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleInsightsAccessedSubjectsSummarySuccess = (state, { payload }) => {
  return {
    ...state,

    insights: { ...state.insights, accessedSubjectsSummary: payload, requesting: false },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleInsightsYearbookSubjectsSummarySuccess = (state, { payload }) => {
  return {
    ...state,

    insights: { ...state.insights, yearbookSubjectsSummary: payload, requesting: false },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleInsightsSalesSummarySuccess = (state, { payload }) => {
  return {
    ...state,

    insights: { ...state.insights, salesSummary: payload, requesting: false },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleInsightsCustomersSuccess = (state, { payload }) => {
  return {
    ...state,

    insights: {
      ...state.insights,

      customers: {
        list: payload.data,
        pagination: {
          page: Number(payload.headers['x-page']),
          perPage: Number(payload.headers['x-per-page']),
          total: Number(payload.headers['x-total'])
        }
      },
      requesting: false
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

const handleInsightsSalesSuccess = (state, { payload }) => {
  return {
    ...state,

    insights: {
      ...state.insights,

      sales: {
        list: payload.data,
        pagination: {
          page: Number(payload.headers['x-page']),
          perPage: Number(payload.headers['x-per-page']),
          total: Number(payload.headers['x-total'])
        }
      },
      requesting: false
    },
    errors: [],
    requesting: false,
    successful: true
  };
};

// Flyers
const handleGetFlyerListSuccess = (state, { payload }) => {
  return {
    ...state,
    flyers: {
      list: payload.data,
      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      },
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateFlyerSuccess = (state, { payload }) => {
  return {
    ...state,
    flyer: payload.data,
    flyers: { ...state.flyers, list: [...state.flyers.list, payload.data] },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateFlyerEmailSuccess = (state, { payload }) => {
  const { flyers } = state;
  const { data, payload: request } = payload;

  // Requested emails are asynchronous
  const requestedActivity = {
    id: Date.now().toString(),
    key: 'flyer.email',
    parameters: {
      to: request.emails
    },
    requested: true
  };

  const newFlyer = {
    ...data,
    email_activities: [...data.email_activities, requestedActivity]
  };

  return {
    ...state,
    flyer: newFlyer,
    flyers: { ...flyers, list: [...flyers.list.filter((flyer) => flyer.id !== newFlyer.id), newFlyer] },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetFlyerSuccess = (state, { payload }) => {
  return {
    ...state,
    flyer: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateFlyerSuccess = (state, { payload }) => {
  const { flyers } = state;
  const { data } = payload;

  return {
    ...state,
    flyer: data,
    flyers: { ...flyers, list: [...flyers.list.filter((flyer) => flyer.id !== data.id), data] },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCleanFlyer = (state) => {
  return {
    ...state,
    flyer: {}
  };
};

// Exports
const handleCreateJobExportSuccess = (state, { payload }) => {
  const { data } = payload;
  const { setup_steps: jobSetupSteps } = state.job;

  return {
    ...state,
    job: { ...state.job, setup_steps: { ...jobSetupSteps, exports_created: true } }, // turn exports create to true after export item creation
    export: data,
    exports: { ...state.exports, list: [data, ...state.exports.list] },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobExportReadySuccess = (state) => {
  return {
    ...state,
    exports: {
      ...state.exports,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobExportAssignSubjectsSuccess = (state) => {
  return {
    ...state,
    exports: {
      ...state.exports,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobExportPrepareSuccess = (state, { payload }) => {
  const { data } = payload;
  const newExports = state.exports.list;
  const currentIndex = newExports.findIndex((exp) => exp.id === data.id);

  if (currentIndex >= 0) {
    newExports.splice(currentIndex, 1, data);
  }

  return {
    ...state,
    export: data,
    exports: { ...state.exports, list: newExports, requesting: false },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobExportListSuccess = (state, { payload }) => {
  return {
    ...state,
    exports: {
      list: payload.data,
      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      },
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobExportSuccess = (state, { payload }) => {
  const { data } = payload;

  return {
    ...state,
    export: data,
    exports: { ...state.exports, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobExportItemsSuccess = (state, { payload }) => {
  const { data } = payload;

  return {
    ...state,
    exports: { ...state.exports, exportItems: data, requesting: false },
    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobExportPeopleFieldOptionsSuccess = (state, { payload }) => {
  return {
    ...state,
    people: {
      ...state.people,
      fields: payload.data,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateJobExportSuccess = (state, { payload }) => {
  const { data, ignore_issues } = payload;
  const newExports = state.exports.list;
  const currentIndex = newExports.findIndex((exp) => exp.id === data.id);

  let newExportItems = state.exports.exportItems;

  if (ignore_issues) {
    newExportItems = newExportItems.map((item) => {
      if (item.crop_status.match(/^(no_crop|warning|failed)$/)) {
        return { ...item, ignore_issues };
      }

      return item;
    });
  }

  if (currentIndex >= 0) {
    newExports.splice(currentIndex, 1, data);
  }

  return {
    ...state,
    export: data,
    exports: { ...state.exports, list: newExports, exportItems: newExportItems, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateJobExportItemsSuccess = (state, { payload }) => {
  const { data } = payload;
  const newExportItems = state.exports.exportItems ? state.exports.exportItems : [];
  const currentIndex = newExportItems.findIndex((item) => item.id === data.id);

  if (currentIndex >= 0) {
    newExportItems.splice(currentIndex, 1, data);
  }

  return {
    ...state,
    exports: { ...state.exports, exportItems: newExportItems, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteJobExportSuccess = (state, { payload }) => {
  const { exportId } = payload;
  const {
    job: stateJob,
    exports: { list: exportsList }
  } = state;
  const { setup_steps: jobSetupSteps } = stateJob;

  return {
    ...state,
    job: { ...stateJob, setup_steps: { ...jobSetupSteps, exports_created: exportsList.length === 1 ? false : true } }, // turn exports create to false if it is the last export item to be deleted
    export: {},
    exports: {
      ...state.exports,
      list: [...exportsList.filter((item) => item.id !== exportId)]
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteJobExportItemsSuccess = (state, { payload }) => {
  const { id } = payload;

  const removeItem = state.exports.exportItems.find((item) => item.id === id);
  const newExportItems = state.exports.exportItems.filter((item) => item.id !== id);

  const newExportSubjectIds = state.export && removeItem ? state.export.subject_ids.filter((subjectId) => subjectId !== removeItem.subject.id) : [];
  const newExport = { ...state.export, subject_count: newExportSubjectIds.length, subject_ids: newExportSubjectIds };

  return {
    ...state,
    export: newExport,
    exports: { ...state.exports, exportItems: newExportItems, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

// Knockouts
const handleCreateJobKnockoutSuccess = (state, { payload }) => {
  const { data } = payload;

  return {
    ...state,
    knockout: data,
    knockouts: { ...state.knockouts, list: [data, ...state.knockouts.list] },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobKnockoutReadySuccess = (state) => {
  return {
    ...state,
    knockouts: {
      ...state.knockouts,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobKnockoutPrepareSuccess = (state) => {
  return {
    ...state,
    knockouts: {
      ...state.knockouts,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobKnockoutTransferSuccess = (state) => {
  return {
    ...state,
    knockouts: {
      ...state.knockouts,
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobKnockoutListSuccess = (state, { payload }) => {
  return {
    ...state,
    knockout: {},
    knockouts: {
      ...state.knockouts,
      list: payload.data,
      pagination: {
        page: Number(payload.headers['x-page']),
        perPage: Number(payload.headers['x-per-page']),
        total: Number(payload.headers['x-total'])
      },
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobKnockoutSuccess = (state, { payload }) => {
  const { data } = payload;

  return {
    ...state,
    knockout: data,
    knockouts: { ...state.knockouts, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateJobKnockoutSuccess = (state, { payload }) => {
  const { data } = payload;
  const { id: payloadKnockoutId } = data;
  const { id: stateKnockoutId } = state.knockout;

  // Prevent cable to update other device sessions
  if (payloadKnockoutId && stateKnockoutId && payloadKnockoutId !== stateKnockoutId) return state;

  const newKnockoutsList = state.knockouts.list;
  const currentIndex = newKnockoutsList.findIndex((exp) => exp.id === data.id);

  if (currentIndex >= 0) {
    newKnockoutsList.splice(currentIndex, 1, data);
  } else {
    newKnockoutsList.unshift(data);
  }

  return {
    ...state,
    knockout: data,
    knockouts: { ...state.knockouts, list: newKnockoutsList, requesting: false },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteJobKnockoutSuccess = (state, { payload }) => {
  const { knockouts: stateKnockouts } = state;
  const { knockoutId } = payload;

  return {
    ...state,
    knockout: {},
    knockouts: {
      ...stateKnockouts,
      list: [...stateKnockouts.list.filter((item) => item.id !== knockoutId)],
      pagination: { ...stateKnockouts.pagination, total: stateKnockouts.pagination.total - 1 },
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobKnockoutItemsSuccess = (state, { payload }) => {
  const { data } = payload;

  return {
    ...state,
    knockouts: {
      ...state.knockouts,
      knockoutItems: {
        ...state.knockouts.knockoutItems,
        list: data,
        pagination: {
          page: Number(payload.headers['x-page']),
          perPage: Number(payload.headers['x-per-page']),
          total: Number(payload.headers['x-total'])
        }
      },
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleUpdateKnockoutDropzone = (state, { payload }) => {
  const stateRejected = state.knockoutUpload.rejected || [];
  const payloadRejected = payload.rejected;

  return {
    ...state,
    knockoutUpload: {
      ...state.knockoutUpload,
      ...payload,
      ...(payloadRejected ? { rejected: payloadRejected.length > 0 ? [...stateRejected, ...payloadRejected] : [] } : {})
    }
  };
};

const handleUpdateKnockoutUploadFromCable = (state, { payload }) => {
  const { data: knockoutItem, file } = payload;
  const { knockout_id: payloadKnockoutId } = knockoutItem;
  const { id: stateKnockoutId } = state.knockout;

  // Prevent cable to update other device sessions
  if (payloadKnockoutId && stateKnockoutId && payloadKnockoutId !== stateKnockoutId) return state;

  const { completed, successful, failed, dismiss, knockoutId } = state.knockoutUpload;
  const {
    knockoutItems: { list: knockoutItems }
  } = state.knockouts;
  const newKnockoutItems = knockoutItems;

  let newCompleted = completed;
  let newSuccessful = successful;

  if (knockoutItem.uploaded_at && knockoutItem.original_image_url) newCompleted++;

  if (knockoutItem.status === 'failed') {
    if (!failed.find((item) => item?.name === knockoutItem.filename) && !dismiss) {
      failed.push(file);
      newSuccessful = newSuccessful > 0 ? newSuccessful - 1 : 0;
    }
  }

  if (knockoutId === state.knockout.id && knockoutItem.original_image_url) {
    const currentIndex = newKnockoutItems.findIndex((item) => item?.id === knockoutItem.id);

    if (currentIndex < 0) {
      newKnockoutItems.push(knockoutItem);
    } else {
      newKnockoutItems.splice(currentIndex, 1, knockoutItem);
    }
  }

  return {
    ...state,
    knockoutUpload: { ...state.knockoutUpload, completed: newCompleted, successful: newSuccessful },
    knockouts: { ...state.knockouts, knockoutItems: { ...state.knockouts.knockoutItems, list: newKnockoutItems } }
  };
};

const handleDeleteJobKnockoutItemsSuccess = (state, { payload }) => {
  const { ids } = payload;
  const {
    knockoutItems: { list: stateKnockoutItems, pagination: stateKnockoutItemsPagination }
  } = state.knockouts;

  const newKnockoutItems = stateKnockoutItems.filter((item) => !ids?.includes(item.id));

  return {
    ...state,
    knockouts: {
      ...state.knockouts,
      knockoutItems: {
        ...state.knockouts.knockoutItems,
        list: newKnockoutItems,
        pagination: { ...stateKnockoutItemsPagination, total: stateKnockoutItemsPagination.total - 1 }
      },
      requesting: false
    },

    errors: [],
    successful: true,
    requesting: false
  };
};

// Marketing Messages
const handleGetJobMarketingMessagesSuccess = (state, { payload }) => {
  return {
    ...state,
    marketingMessages: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

// Capture QR
const handleGetJobQrSessionsSuccess = (state, { payload }) => {
  const { job: stateJob } = state;
  const { data: payloadQrSessions } = payload;

  return {
    ...state,
    job: { ...stateJob, qr_sessions: payloadQrSessions },

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleGetJobQrCodeBySessionSuccess = (state, { payload }) => {
  return {
    ...state,
    sessionQrCodes: payload.data,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobQrCodeResolutionSuccess = (state, { payload }) => {
  const { job: stateJob, sessionQrCodes: stateSessionQrCodes } = state;
  const { qr_sessions: stateQrSessions } = stateJob;
  const {
    data: { qr_session: payloadQrSession, qr_code: payloadQrCode }
  } = payload;

  const updatedQrSessions = stateQrSessions.map((qrSession) => (qrSession.id === payloadQrSession.id ? payloadQrSession : qrSession));
  const updatedSessionQrCodes = stateSessionQrCodes.map((qrCode) => (qrCode.id === payloadQrCode.id ? payloadQrCode : qrCode));

  return {
    ...state,
    job: { ...stateJob, qr_sessions: updatedQrSessions },
    sessionQrCodes: updatedSessionQrCodes,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleDeleteJobQrSessionPhotosSuccess = (state, { payload }) => {
  const { job: stateJob, sessionQrCodes: stateSessionQrCodes } = state;
  const { qr_sessions: stateQrSessions } = stateJob;
  const { data: payloadQrSession } = payload;

  const updatedQrSessions = stateQrSessions.map((qrSession) => (qrSession.id === payloadQrSession.id ? payloadQrSession : qrSession));
  const updatedSessionQrCodes = stateSessionQrCodes.map((qrCode) => ({ ...qrCode, photos: [], resolution: QrCodeResolutionMethod.ResolutionDeletedPhoto }));

  return {
    ...state,
    job: { ...stateJob, qr_sessions: updatedQrSessions },
    sessionQrCodes: updatedSessionQrCodes,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobQrIgnoreErrorsSuccess = (state, { payload }) => {
  const { job: stateJob, sessionQrCodes: stateSessionQrCodes } = state;
  const { qr_sessions: stateQrSessions } = stateJob;
  const { data: payloadQrSession } = payload;

  const updatedQrSessions = stateQrSessions.map((qrSession) => (qrSession.id === payloadQrSession.id ? payloadQrSession : qrSession));
  const updatedSessionQrCodes = stateSessionQrCodes.map((qrCode) => ({ ...qrCode, resolution: QrCodeResolutionMethod.ResolutionIgnored }));

  return {
    ...state,
    job: { ...stateJob, qr_sessions: updatedQrSessions },
    sessionQrCodes: updatedSessionQrCodes,

    errors: [],
    successful: true,
    requesting: false
  };
};

const handleCreateJobQrSessionMatchingSuccess = (state, { payload }) => {
  const { job: stateJob } = state;
  const { qr_sessions: stateQrSessions } = stateJob;
  const { data: payloadQrSession } = payload;
  const updatedQrSessions = stateQrSessions.map((qrSession) => (qrSession.id === payloadQrSession.id ? payloadQrSession : qrSession));

  return {
    ...state,
    job: { ...stateJob, qr_sessions: updatedQrSessions },

    errors: [],
    successful: true,
    requesting: false
  };
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.CREATE_JOB_REQUEST:
    case types.RESTORE_JOB_REQUEST:
    case types.GET_JOB_TIMEZONE_LIST_REQUEST:
    case types.GET_JOB_SMS_TIME_LIST_REQUEST:
    case types.DELETE_JOB_REQUEST:
    case types.RESET_JOB_REQUEST:
    case types.GET_PHOTO_REQUEST:
    case types.GET_PHOTO_TYPE_OPTIONS_REQUEST:
    case types.UPDATE_PHOTO_REQUEST:
    case types.DELETE_PHOTO_REQUEST:
    case types.CREATE_TAG_REQUEST:
    case types.CREATE_TAG_ASSOCIATION_REQUEST:
    case types.RENAME_TAG_REQUEST:
    case types.SORT_TAG_REQUEST:
    case types.DELETE_TAG_REQUEST:
    case types.DELETE_TAG_ASSOCIATION_REQUEST:
    case types.CREATE_PEOPLE_REQUEST:
    case types.CREATE_PEOPLE_ASSOCIATION_REQUEST:
    case types.CREATE_PEOPLE_ASSOCIATION_PHOTOS_REQUEST:
    case types.CREATE_PEOPLE_CSV_REQUEST:
    case types.GET_PEOPLE_FIELD_OPTIONS_REQUEST:
    case types.GET_PEOPLE_BY_PHOTO_ID_REQUEST:
    case types.DELETE_PEOPLE_REQUEST:
    case types.GET_INSIGHTS_CUSTOMERS_CSV_REQUEST:
    case types.GET_INSIGHTS_SALES_CSV_REQUEST:
    case types.GET_ACCESS_CODE_REQUEST:
    case types.CREATE_FLYER_REQUEST:
    case types.CREATE_FLYER_EMAIL_REQUEST:
    case types.GET_FLYER_REQUEST:
    case types.GET_FLYER_PDF_REQUEST:
    case types.UPDATE_FLYER_REQUEST:
    case types.DELETE_FLYER_REQUEST:
    case types.CREATE_ADMIN_SMS_REQUEST:
    case types.CREATE_JOB_EXPORT_REQUEST:
    case types.CREATE_JOB_EXPORT_PREPARE_REQUEST:
    case types.CREATE_JOB_EXPORT_READY_REQUEST:
    case types.CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_REQUEST:
    case types.GET_JOB_EXPORT_REQUEST:
    case types.UPDATE_JOB_EXPORT_REQUEST:
    case types.UPDATE_JOB_EXPORT_ITEM_REQUEST:
    case types.DELETE_JOB_EXPORT_REQUEST:
    case types.DELETE_JOB_EXPORT_ITEM_REQUEST:
    case types.CREATE_JOB_KNOCKOUT_REQUEST:
    case types.GET_JOB_KNOCKOUT_REQUEST:
    case types.UPDATE_JOB_KNOCKOUT_REQUEST:
    case types.DELETE_JOB_KNOCKOUT_REQUEST:
    case types.GET_JOB_UNSUBSCRIBE_LIST_REQUEST:
    case types.GET_JOB_MARKETING_MESSAGES_REQUEST:
    case types.GET_JOB_QR_SESSIONS_REQUEST:
    case types.GET_JOB_QR_CODE_BY_SESSION_REQUEST:
    case types.CREATE_JOB_QR_CODE_RESOLUTION_REQUEST:
    case types.DELETE_JOB_QR_SESSION_PHOTOS_REQUEST:
    case types.CREATE_JOB_QR_IGNORE_ERRORS_REQUEST:
    case types.CREATE_JOB_QR_SESSION_MATCHING_REQUEST:
      return handleRequestingState(state);

    case types.GET_JOB_LIST_REQUEST:
    case types.GET_JOB_LIST_BY_ORGANIZATION_REQUEST:
      return handleJobsRequestingState(state, 'jobs');

    case types.UPDATE_JOB_REQUEST:
    case types.GET_JOB_REQUEST:
    case types.GET_JOB_PRICESHEETS_REQUEST:
    case types.GET_JOB_SUBJECTS_SUMMARY_REQUEST:
      return handleJobsRequestingState(state, 'job');

    case types.CREATE_PHOTO_CHECK_DUPLICATE_REQUEST:
    case types.GET_PHOTO_LIST_REQUEST:
    case types.UPDATE_PHOTOS_REQUEST:
    case types.DELETE_PEOPLE_PHOTO_REQUEST:
    case types.DELETE_PHOTO_PEOPLE_REQUEST:
      return handleJobsRequestingState(state, 'photos');

    case types.GET_INSIGHTS_CUSTOMERS_REQUEST:
    case types.GET_INSIGHTS_CUSTOMERS_SUMMARY_REQUEST:
    case types.GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_REQUEST:
    case types.GET_INSIGHTS_YEARBOOK_SUBJECTS_SUMMARY_REQUEST:
    case types.GET_INSIGHTS_SALES_REQUEST:
    case types.GET_INSIGHTS_SALES_SUMMARY_REQUEST:
      return handleJobsRequestingState(state, 'insights');

    case types.GET_TAG_LIST_REQUEST:
      return handleJobsRequestingState(state, 'tags');

    case types.CREATE_PEOPLE_CSV_DETAILS_REQUEST:
    case types.CREATE_PEOPLE_BULK_FEATURE_REQUEST:
    case types.CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_REQUEST:
    case types.CREATE_PEOPLE_YEARBOOK_SELECTION_REQUEST:
    case types.GET_PEOPLE_LIST_REQUEST:
    case types.GET_SIDEBAR_PEOPLE_LIST_REQUEST:
    case types.GET_SUBJECT_REQUEST:
    case types.UPDATE_PEOPLE_REQUEST:
    case types.CREATE_PEOPLE_LOOKUP_CONFIG_REQUEST:
    case types.CREATE_PEOPLE_LOOKUP_VALIDATE_REQUEST:
    case types.GET_PEOPLE_LOOKUP_CONFIG_REQUEST:
    case types.GET_PEOPLE_CSV_COLUMN_CONFIG_REQUEST:
      return handleJobsRequestingState(state, 'people');

    case types.GET_PEOPLE_PHOTO_FACES_LIST_REQUEST:
    case types.GET_PEOPLE_FACES_LIST_REQUEST:
    case types.UPDATE_PEOPLE_FACES_REQUEST:
      return handleJobsRequestingState(state, 'faces');

    case types.GET_FLYER_LIST_REQUEST:
      return handleJobsRequestingState(state, 'flyers');

    case types.GET_JOB_EXPORT_LIST_REQUEST:
    case types.GET_JOB_EXPORT_ITEMS_REQUEST:
    case types.GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_REQUEST:
      return handleJobsRequestingState(state, 'exports');

    case types.CREATE_JOB_KNOCKOUT_READY_REQUEST:
    case types.CREATE_JOB_KNOCKOUT_PREPARE_REQUEST:
    case types.CREATE_JOB_KNOCKOUT_TRANSFER_REQUEST:
    case types.GET_JOB_KNOCKOUT_LIST_REQUEST:
    case types.GET_JOB_KNOCKOUT_ITEMS_REQUEST:
    case types.DELETE_JOB_KNOCKOUT_ITEMS_REQUEST:
      return handleJobsRequestingState(state, 'knockouts');

    case types.CREATE_PEOPLE_BULK_FEATURE_SUCCESS:
    case types.CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_SUCCESS:
    case types.CREATE_PEOPLE_YEARBOOK_SELECTION_SUCCESS:
      return handleJobsSuccessState(state, 'people');

    case types.CREATE_PHOTO_CHECK_DUPLICATE_SUCCESS:
      return handleJobsSuccessState(state, 'photos');

    case types.UPDATE_PHOTO_SUCCESS:
    case types.DELETE_PEOPLE_SUCCESS:
    case types.GET_INSIGHTS_CUSTOMERS_CSV_SUCCESS:
    case types.GET_INSIGHTS_SALES_CSV_SUCCESS:
    case types.GET_ACCESS_CODE_SUCCESS:
    case types.GET_FLYER_PDF_SUCCESS:
    case types.DELETE_FLYER_SUCCESS:
    case types.CREATE_PEOPLE_CSV_SUCCESS:
    case types.DELETE_TAG_ASSOCIATION_SUCCESS:
    case types.CREATE_ADMIN_SMS_SUCCESS:
      return handleSuccessState(state);

    case types.GET_JOB_LIST_SUCCESS:
    case types.GET_JOB_LIST_BY_ORGANIZATION_SUCCESS:
      return handleGetJobListSuccess(state, payload);
    case types.GET_JOB_TIMEZONE_LIST_SUCCESS:
      return handleGetJobTimezoneListSuccess(state, payload);
    case types.GET_JOB_SMS_TIME_LIST_SUCCESS:
      return handleGetJobSmsTimeListSuccess(state, payload);
    case types.GET_JOB_SUCCESS:
      return handleGetJobSuccess(state, payload);
    case types.GET_JOB_PRICESHEETS_SUCCESS:
      return handleGetJobPriceSheetsSuccess(state, payload);
    case types.GET_JOB_UNSUBSCRIBE_LIST_SUCCESS:
      return handleGetJobUnsubscribeListSuccess(state, payload);
    case types.GET_JOB_SUBJECTS_SUMMARY_SUCCESS:
      return handleGetJobSubjectsSummarySuccess(state, payload);
    case types.UPDATE_JOB_SUCCESS:
      return handleUpdateJobSuccess(state, payload);
    case types.RESTORE_JOB_SUCCESS:
      return handleRestoreJobSuccess(state, payload);
    case types.GET_PHOTO_LIST_SUCCESS:
      return handleGetPhotoListSuccess(state, payload);
    case types.RESET_JOB_SUCCESS:
      return handleResetJob(state, initialState);

    case types.GET_PHOTO_SUCCESS:
      return handleGetPhotoSuccess(state, payload);
    case types.GET_PHOTO_TYPE_OPTIONS_SUCCESS:
      return handleGetPhotoTypeOptions(state, payload);

    case types.DELETE_PHOTO_SUCCESS:
      return handleDeletePhotoSuccess(state, payload);

    case types.UPDATE_PHOTO_GALLERY:
      return handleUpdatePhotoGallery(state, payload);
    case types.UPDATE_PHOTO_TRAINED_AND_REMOVE_QR_IMAGES:
      return handleUpdatePhotoTrainedAndRemoveQrImages(state, payload);
    case types.UPDATE_PHOTO_STATS:
      return handleUpdatePhotoStats(state, payload);
    case types.UPDATE_PHOTO_TAGS:
      return handleUpdatePhotoTags(state, payload);
    case types.UPDATE_PHOTO_DROPZONE:
      return handleUpdatePhotoDropzone(state, payload);
    case types.SET_PHOTO_QUERY_COMPONENT:
      return handleSetPhotoQueryComponent(state, payload);
    case types.UPDATE_PHOTOS_SUCCESS:
      return handleUpdatePhotos(state, payload);

    case types.CREATE_TAG_SUCCESS:
      return handleCreateTagSuccess(state, payload);
    case types.GET_TAG_LIST_SUCCESS:
      return handleGetTagListSuccess(state, payload);
    case types.RENAME_TAG_SUCCESS:
      return handleRenameTagSuccess(state, payload);
    case types.SORT_TAG_SUCCESS:
      return handleSortTagSuccess(state, payload);
    case types.DELETE_TAG_SUCCESS:
      return handleDeleteTagSuccess(state, payload);

    case types.CREATE_TAG_ASSOCIATION_SUCCESS:
      return handleCreateTagAssociationSuccess(state, payload);

    case types.CREATE_PEOPLE_SUCCESS:
      return handleCreatePeopleSuccess(state);
    case types.CREATE_PEOPLE_ASSOCIATION_SUCCESS:
      return handleCreatePeopleAssociationSuccess(state, payload);
    case types.CREATE_PEOPLE_ASSOCIATION_PHOTOS_SUCCESS:
      return handleCreatePeopleAssociationPhotosSuccess(state, payload);
    case types.CREATE_PEOPLE_CSV_DETAILS_SUCCESS:
      return handleCreatePeopleCsvDetailsSuccess(state, payload);
    case types.GET_PEOPLE_LIST_SUCCESS:
      return handleGetPeopleListSuccess(state, payload);
    case types.GET_SIDEBAR_PEOPLE_LIST_SUCCESS:
      return handleGetSidebarPeopleListSuccess(state, payload);
    case types.GET_PEOPLE_FACES_LIST_SUCCESS:
      return handleGetPeopleFacesListSuccess(state, payload);
    case types.GET_PEOPLE_PHOTO_FACES_LIST_SUCCESS:
      return handleGetPeoplePhotoFacesListSuccess(state, payload);
    case types.GET_PEOPLE_FIELD_OPTIONS_SUCCESS:
      return handleGetPeopleFieldOptionsSuccess(state, payload);
    case types.GET_PEOPLE_BY_PHOTO_ID_SUCCESS:
      return handleGetPeopleByPhotoIdSuccess(state, payload);
    case types.GET_SUBJECT_SUCCESS:
      return handleGetSubjectSuccess(state, payload);
    case types.UPDATE_PEOPLE_SUCCESS:
      return handleUpdatePeopleSuccess(state, payload);
    case types.UPDATE_PEOPLE_FACES_SUCCESS:
      return handleUpdatePeopleFacesSuccess(state, payload);
    case types.SET_PEOPLE_DETAILS:
      return handleSetPeopleDetails(state, payload);
    case types.SET_PEOPLE_MAIL:
      return handleSetPeopleMail(state, payload);
    case types.SET_PEOPLE_PHOTOS_COUNT:
      return handleSetPeoplePhotosCount(state, payload);
    case types.DELETE_PHOTO_PEOPLE_SUCCESS:
      return handleDeletePhotoPeopleSuccess(state, payload);
    case types.DELETE_PEOPLE_PHOTO_SUCCESS:
      return handleDeletePeoplePhotoSuccess(state, payload);
    case types.SET_PEOPLE_LABELS:
      return handleSetPeopleLabels(state, payload);
    case types.CREATE_PEOPLE_LOOKUP_CONFIG_SUCCESS:
      return handleCreatePeopleLookupConfigSuccess(state, payload);
    case types.CREATE_PEOPLE_LOOKUP_VALIDATE_SUCCESS:
      return handleCreatePeopleLookupValidateSuccess(state, payload);
    case types.GET_PEOPLE_LOOKUP_CONFIG_SUCCESS:
      return handleGetPeopleLookupConfigSuccess(state, payload);
    case types.GET_PEOPLE_CSV_COLUMN_CONFIG_SUCCESS:
      return handleGetPeopleCsvColumnSuccess(state, payload);
    case types.RESET_PEOPLE_LOOKUP_VALIDATE:
      return handleResetPeopleLookupValidate(state);

    case types.GET_INSIGHTS_CUSTOMERS_SUCCESS:
      return handleInsightsCustomersSuccess(state, payload);
    case types.GET_INSIGHTS_CUSTOMERS_SUMMARY_SUCCESS:
      return handleInsightsCustomersSummarySuccess(state, payload);
    case types.GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_SUCCESS:
      return handleInsightsAccessedSubjectsSummarySuccess(state, payload);
    case types.GET_INSIGHTS_YEARBOOK_SUBJECTS_SUMMARY_SUCCESS:
      return handleInsightsYearbookSubjectsSummarySuccess(state, payload);
    case types.GET_INSIGHTS_SALES_SUCCESS:
      return handleInsightsSalesSuccess(state, payload);
    case types.GET_INSIGHTS_SALES_SUMMARY_SUCCESS:
      return handleInsightsSalesSummarySuccess(state, payload);

    case types.CREATE_FLYER_SUCCESS:
      return handleCreateFlyerSuccess(state, payload);
    case types.CREATE_FLYER_EMAIL_SUCCESS:
      return handleCreateFlyerEmailSuccess(state, payload);
    case types.GET_FLYER_LIST_SUCCESS:
      return handleGetFlyerListSuccess(state, payload);
    case types.GET_FLYER_SUCCESS:
      return handleGetFlyerSuccess(state, payload);
    case types.UPDATE_FLYER_SUCCESS:
      return handleUpdateFlyerSuccess(state, payload);
    case types.CLEAR_FLYER:
      return handleCleanFlyer(state, payload);

    case types.CREATE_JOB_EXPORT_SUCCESS:
      return handleCreateJobExportSuccess(state, payload);
    case types.CREATE_JOB_EXPORT_PREPARE_SUCCESS:
      return handleCreateJobExportPrepareSuccess(state, payload);
    case types.CREATE_JOB_EXPORT_READY_SUCCESS:
      return handleCreateJobExportReadySuccess(state, payload);
    case types.CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_SUCCESS:
      return handleCreateJobExportAssignSubjectsSuccess(state, payload);
    case types.GET_JOB_EXPORT_LIST_SUCCESS:
      return handleGetJobExportListSuccess(state, payload);
    case types.GET_JOB_EXPORT_SUCCESS:
      return handleGetJobExportSuccess(state, payload);
    case types.GET_JOB_EXPORT_ITEMS_SUCCESS:
      return handleGetJobExportItemsSuccess(state, payload);
    case types.GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_SUCCESS:
      return handleGetJobExportPeopleFieldOptionsSuccess(state, payload);
    case types.UPDATE_JOB_EXPORT_SUCCESS:
      return handleUpdateJobExportSuccess(state, payload);
    case types.UPDATE_JOB_EXPORT_ITEM_SUCCESS:
      return handleUpdateJobExportItemsSuccess(state, payload);
    case types.DELETE_JOB_EXPORT_SUCCESS:
      return handleDeleteJobExportSuccess(state, payload);
    case types.DELETE_JOB_EXPORT_ITEM_SUCCESS:
      return handleDeleteJobExportItemsSuccess(state, payload);

    case types.CREATE_JOB_KNOCKOUT_SUCCESS:
      return handleCreateJobKnockoutSuccess(state, payload);
    case types.CREATE_JOB_KNOCKOUT_READY_SUCCESS:
      return handleCreateJobKnockoutReadySuccess(state, payload);
    case types.CREATE_JOB_KNOCKOUT_PREPARE_SUCCESS:
      return handleCreateJobKnockoutPrepareSuccess(state, payload);
    case types.CREATE_JOB_KNOCKOUT_TRANSFER_SUCCESS:
      return handleCreateJobKnockoutTransferSuccess(state, payload);
    case types.GET_JOB_KNOCKOUT_LIST_SUCCESS:
      return handleGetJobKnockoutListSuccess(state, payload);
    case types.GET_JOB_KNOCKOUT_SUCCESS:
      return handleGetJobKnockoutSuccess(state, payload);
    case types.GET_JOB_KNOCKOUT_ITEMS_SUCCESS:
      return handleGetJobKnockoutItemsSuccess(state, payload);
    case types.UPDATE_JOB_KNOCKOUT_SUCCESS:
      return handleUpdateJobKnockoutSuccess(state, payload);
    case types.UPDATE_KNOCKOUT_DROPZONE:
      return handleUpdateKnockoutDropzone(state, payload);
    case types.UPDATE_KNOCKOUT_UPLOAD_FROM_CABLE:
      return handleUpdateKnockoutUploadFromCable(state, payload);
    case types.DELETE_JOB_KNOCKOUT_SUCCESS:
      return handleDeleteJobKnockoutSuccess(state, payload);
    case types.DELETE_JOB_KNOCKOUT_ITEMS_SUCCESS:
      return handleDeleteJobKnockoutItemsSuccess(state, payload);

    case types.GET_JOB_MARKETING_MESSAGES_SUCCESS:
      return handleGetJobMarketingMessagesSuccess(state, payload);

    case types.GET_JOB_QR_SESSIONS_SUCCESS:
      return handleGetJobQrSessionsSuccess(state, payload);
    case types.GET_JOB_QR_CODE_BY_SESSION_SUCCESS:
      return handleGetJobQrCodeBySessionSuccess(state, payload);
    case types.CREATE_JOB_QR_CODE_RESOLUTION_SUCCESS:
      return handleCreateJobQrCodeResolutionSuccess(state, payload);
    case types.DELETE_JOB_QR_SESSION_PHOTOS_SUCCESS:
      return handleDeleteJobQrSessionPhotosSuccess(state, payload);
    case types.CREATE_JOB_QR_IGNORE_ERRORS_SUCCESS:
      return handleCreateJobQrIgnoreErrorsSuccess(state, payload);
    case types.CREATE_JOB_QR_SESSION_MATCHING_SUCCESS:
      return handleCreateJobQrSessionMatchingSuccess(state, payload);

    case types.CREATE_JOB_ERROR:
    case types.RESTORE_JOB_ERROR:
    case types.GET_JOB_TIMEZONE_LIST_ERROR:
    case types.GET_JOB_SMS_TIME_LIST_ERROR:
    case types.DELETE_JOB_ERROR:
    case types.GET_PHOTO_ERROR:
    case types.GET_PHOTO_TYPE_OPTIONS_ERROR:
    case types.UPDATE_PHOTO_ERROR:
    case types.DELETE_PHOTO_ERROR:
    case types.CREATE_TAG_ERROR:
    case types.CREATE_TAG_ASSOCIATION_ERROR:
    case types.RENAME_TAG_ERROR:
    case types.SORT_TAG_ERROR:
    case types.DELETE_TAG_ERROR:
    case types.DELETE_TAG_ASSOCIATION_ERROR:
    case types.CREATE_PEOPLE_ERROR:
    case types.CREATE_PEOPLE_ASSOCIATION_ERROR:
    case types.CREATE_PEOPLE_ASSOCIATION_PHOTOS_ERROR:
    case types.CREATE_PEOPLE_CSV_ERROR:
    case types.CREATE_PEOPLE_CSV_DETAILS_ERROR:
    case types.CREATE_PEOPLE_BULK_FEATURE_ERROR:
    case types.CREATE_PEOPLE_BULK_YEARBOOK_SELECTION_ERROR:
    case types.CREATE_PEOPLE_YEARBOOK_SELECTION_ERROR:
    case types.GET_PEOPLE_FIELD_OPTIONS_ERROR:
    case types.GET_PEOPLE_BY_PHOTO_ID_ERROR:
    case types.GET_SUBJECT_ERROR:
    case types.UPDATE_PEOPLE_ERROR:
    case types.DELETE_PEOPLE_ERROR:
    case types.GET_INSIGHTS_CUSTOMERS_CSV_ERROR:
    case types.GET_INSIGHTS_SALES_CSV_ERROR:
    case types.GET_ACCESS_CODE_ERROR:
    case types.CREATE_FLYER_ERROR:
    case types.CREATE_FLYER_EMAIL_ERROR:
    case types.GET_FLYER_ERROR:
    case types.GET_FLYER_PDF_ERROR:
    case types.UPDATE_FLYER_ERROR:
    case types.DELETE_FLYER_ERROR:
    case types.CREATE_ADMIN_SMS_ERROR:
    case types.CREATE_JOB_EXPORT_ERROR:
    case types.CREATE_JOB_EXPORT_PREPARE_ERROR:
    case types.CREATE_JOB_EXPORT_READY_ERROR:
    case types.CREATE_JOB_EXPORT_ASSIGN_SUBJECTS_ERROR:
    case types.GET_JOB_EXPORT_ERROR:
    case types.UPDATE_JOB_EXPORT_ERROR:
    case types.UPDATE_JOB_EXPORT_ITEM_ERROR:
    case types.DELETE_JOB_EXPORT_ERROR:
    case types.DELETE_JOB_EXPORT_ITEM_ERROR:
    case types.CREATE_JOB_KNOCKOUT_ERROR:
    case types.GET_JOB_KNOCKOUT_ERROR:
    case types.UPDATE_JOB_KNOCKOUT_ERROR:
    case types.DELETE_JOB_KNOCKOUT_ERROR:
    case types.GET_JOB_MARKETING_MESSAGES_ERROR:
    case types.GET_JOB_QR_SESSIONS_ERROR:
    case types.GET_JOB_QR_CODE_BY_SESSION_ERROR:
    case types.CREATE_JOB_QR_CODE_RESOLUTION_ERROR:
    case types.DELETE_JOB_QR_SESSION_PHOTOS_ERROR:
    case types.CREATE_JOB_QR_IGNORE_ERRORS_ERROR:
    case types.CREATE_JOB_QR_SESSION_MATCHING_ERROR:
      return handleErrorState(state, payload);

    case types.GET_JOB_LIST_ERROR:
    case types.GET_JOB_LIST_BY_ORGANIZATION_ERROR:
      return handleJobsErrorState(state, payload, 'jobs');

    case types.UPDATE_JOB_ERROR:
    case types.GET_JOB_ERROR:
    case types.GET_JOB_PRICESHEETS_ERROR:
    case types.GET_JOB_UNSUBSCRIBE_LIST_ERROR:
    case types.GET_JOB_SUBJECTS_SUMMARY_ERROR:
      return handleJobsErrorState(state, payload, 'job');

    case types.CREATE_PHOTO_CHECK_DUPLICATE_ERROR:
    case types.GET_PHOTO_LIST_ERROR:
    case types.UPDATE_PHOTOS_ERROR:
    case types.DELETE_PEOPLE_PHOTO_ERROR:
    case types.DELETE_PHOTO_PEOPLE_ERROR:
      return handleJobsErrorState(state, payload, 'photos');

    case types.GET_INSIGHTS_CUSTOMERS_ERROR:
    case types.GET_INSIGHTS_CUSTOMERS_SUMMARY_ERROR:
    case types.GET_INSIGHTS_ACCESSED_SUBJECTS_SUMMARY_ERROR:
    case types.GET_INSIGHTS_YEARBOOK_SUBJECTS_SUMMARY_ERROR:
    case types.GET_INSIGHTS_SALES_ERROR:
    case types.GET_INSIGHTS_SALES_SUMMARY_ERROR:
      return handleJobsErrorState(state, payload, 'insights');

    case types.GET_PEOPLE_LIST_ERROR:
    case types.GET_SIDEBAR_PEOPLE_LIST_ERROR:
    case types.CREATE_PEOPLE_LOOKUP_CONFIG_ERROR:
    case types.CREATE_PEOPLE_LOOKUP_VALIDATE_ERROR:
    case types.GET_PEOPLE_LOOKUP_CONFIG_ERROR:
    case types.GET_PEOPLE_CSV_COLUMN_CONFIG_ERROR:
      return handleJobsErrorState(state, payload, 'people');

    case types.GET_PEOPLE_PHOTO_FACES_LIST_ERROR:
    case types.GET_PEOPLE_FACES_LIST_ERROR:
    case types.UPDATE_PEOPLE_FACES_ERROR:
      return handleJobsErrorState(state, payload, 'faces');

    case types.GET_TAG_LIST_ERROR:
      return handleJobsErrorState(state, payload, 'tags');

    case types.GET_FLYER_LIST_ERROR:
      return handleJobsErrorState(state, payload, 'flyers');

    case types.GET_JOB_EXPORT_LIST_ERROR:
    case types.GET_JOB_EXPORT_ITEMS_ERROR:
    case types.GET_JOB_EXPORTS_PEOPLE_FIELD_OPTIONS_ERROR:
      return handleJobsErrorState(state, payload, 'exports');

    case types.GET_JOB_KNOCKOUT_LIST_ERROR:
    case types.GET_JOB_KNOCKOUT_ITEMS_ERROR:
    case types.DELETE_JOB_KNOCKOUT_ITEMS_ERROR:
      return handleJobsErrorState(state, payload, 'knockouts');

    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Styles
import './style.css';

const Navigation = ({ items, isOpen, onToggleNavigation, enforceExact }) => {
  return (
    <nav className={`navigation ${isOpen ? 'navigation--open' : ''}`} onClick={onToggleNavigation}>
      {items
        .filter((item) => !item.hidden)
        .map((item, i) => (
          <NavLink
            className={`navigation__item ${item.disabled ? 'disabled' : ''}`}
            activeClassName="navigation__item--active"
            data-cy={item.dataCy ? item.dataCy : `${item.name}-nav-link`}
            to={item.link}
            key={i}
            draggable="false"
            isActive={
              enforceExact
                ? (match) => {
                    if (!match) {
                      return false;
                    }

                    return match.isExact;
                  }
                : null
            }
          >
            {item.name}
          </NavLink>
        ))}
    </nav>
  );
};

Navigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Navigation;

export const GET_MYACCOUNT_STUDIO_REQUEST = 'GET_MYACCOUNT_STUDIO_REQUEST';
export const GET_MYACCOUNT_STUDIO_SUCCESS = 'GET_MYACCOUNT_STUDIO_SUCCESS';
export const GET_MYACCOUNT_STUDIO_ERROR = 'GET_MYACCOUNT_STUDIO_ERROR';

export const GET_MYACCOUNT_STUDIO_TRANSACTIONS_REQUEST = 'GET_MYACCOUNT_STUDIO_TRANSACTIONS_REQUEST';
export const GET_MYACCOUNT_STUDIO_TRANSACTIONS_SUCCESS = 'GET_MYACCOUNT_STUDIO_TRANSACTIONS_SUCCESS';
export const GET_MYACCOUNT_STUDIO_TRANSACTIONS_ERROR = 'GET_MYACCOUNT_STUDIO_TRANSACTIONS_ERROR';

export const UPDATE_MYACCOUNT_STUDIO_REQUEST = 'UPDATE_MYACCOUNT_STUDIO_REQUEST';
export const UPDATE_MYACCOUNT_STUDIO_SUCCESS = 'UPDATE_MYACCOUNT_STUDIO_SUCCESS';
export const UPDATE_MYACCOUNT_STUDIO_ERROR = 'UPDATE_MYACCOUNT_STUDIO_ERROR';

export const CREATE_BUSINESS_CERTIFICATION_CERTIFY = 'CREATE_BUSINESS_CERTIFICATION_CERTIFY';
export const CREATE_BUSINESS_CERTIFICATION_CERTIFY_SUCCESS = 'CREATE_BUSINESS_CERTIFICATION_CERTIFY_SUCCESS';
export const CREATE_BUSINESS_CERTIFICATION_CERTIFY_ERROR = 'CREATE_BUSINESS_CERTIFICATION_CERTIFY_ERROR';

export const GET_MYACCOUNT_REQUEST = 'GET_MYACCOUNT_REQUEST';
export const GET_MYACCOUNT_SUCCESS = 'GET_MYACCOUNT_SUCCESS';
export const GET_MYACCOUNT_ERROR = 'GET_MYACCOUNT_ERROR';

export const UPDATE_MYACCOUNT_REQUEST = 'UPDATE_MYACCOUNT_REQUEST';
export const UPDATE_MYACCOUNT_SUCCESS = 'UPDATE_MYACCOUNT_SUCCESS';
export const UPDATE_MYACCOUNT_ERROR = 'UPDATE_MYACCOUNT_ERROR';

// Stripe
export const UPDATE_STRIPE_ACCOUNT_REQUEST = 'UPDATE_STRIPE_ACCOUNT_REQUEST';
export const UPDATE_STRIPE_ACCOUNT_SUCCESS = 'UPDATE_STRIPE_ACCOUNT_SUCCESS';
export const UPDATE_STRIPE_ACCOUNT_ERROR = 'UPDATE_STRIPE_ACCOUNT_ERROR';

export const DELETE_STRIPE_ACCOUNT_REQUEST = 'DELETE_STRIPE_ACCOUNT_REQUEST';
export const DELETE_STRIPE_ACCOUNT_SUCCESS = 'DELETE_STRIPE_ACCOUNT_SUCCESS';
export const DELETE_STRIPE_ACCOUNT_ERROR = 'DELETE_STRIPE_ACCOUNT_ERROR';

export const CREATE_STRIPE_EXPRESS_ONBOARDING_REQUEST = 'CREATE_STRIPE_EXPRESS_ONBOARDING_REQUEST';
export const CREATE_STRIPE_EXPRESS_ONBOARDING_SUCCESS = 'CREATE_STRIPE_EXPRESS_ONBOARDING_SUCCESS';
export const CREATE_STRIPE_EXPRESS_ONBOARDING_ERROR = 'CREATE_STRIPE_EXPRESS_ONBOARDING_ERROR';

export const CREATE_STRIPE_EXPRESS_COMPLETE_REQUEST = 'CREATE_STRIPE_EXPRESS_COMPLETE_REQUEST';
export const CREATE_STRIPE_EXPRESS_COMPLETE_SUCCESS = 'CREATE_STRIPE_EXPRESS_COMPLETE_SUCCESS';
export const CREATE_STRIPE_EXPRESS_COMPLETE_ERROR = 'CREATE_STRIPE_EXPRESS_COMPLETE_ERROR';

export const CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_REQUEST = 'CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_REQUEST';
export const CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_SUCCESS = 'CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_SUCCESS';
export const CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_ERROR = 'CREATE_STRIPE_EXPRESS_INSTANT_PAYOUT_ERROR';

export const CREATE_STRIPE_EXPRESS_REPORT_REQUEST = 'CREATE_STRIPE_EXPRESS_REPORT_REQUEST';
export const CREATE_STRIPE_EXPRESS_REPORT_SUCCESS = 'CREATE_STRIPE_EXPRESS_REPORT_SUCCESS';
export const CREATE_STRIPE_EXPRESS_REPORT_ERROR = 'CREATE_STRIPE_EXPRESS_REPORT_ERROR';

export const GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_REQUEST = 'GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_REQUEST';
export const GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_SUCCESS = 'GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_SUCCESS';
export const GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_ERROR = 'GET_STRIPE_EXPRESS_INSTANT_PAYOUT_INFO_ERROR';

export const GET_USER_STUDIOS_REQUEST = 'GET_USER_STUDIOS_REQUEST';
export const GET_USER_STUDIOS_SUCCESS = 'GET_USER_STUDIOS_SUCCESS';
export const GET_USER_STUDIOS_ERROR = 'GET_USER_STUDIOS_ERROR';

export const GET_CURRENT_TERMS_REQUEST = 'GET_CURRENT_TERMS_REQUEST';
export const GET_CURRENT_TERMS_SUCCESS = 'GET_CURRENT_TERMS_SUCCESS';
export const GET_CURRENT_TERMS_ERROR = 'GET_CURRENT_TERMS_ERROR';

export const CHANGE_CURRENT_STUDIO_REQUEST = 'CHANGE_CURRENT_STUDIO_REQUEST';
export const CHANGE_CURRENT_STUDIO_ERROR = 'CHANGE_CURRENT_STUDIO_ERROR';

export const CREATE_NEXUS = 'CREATE_NEXUS';
export const CREATE_NEXUS_SUCCESS = 'CREATE_NEXUS_SUCCESS';
export const CREATE_NEXUS_ERROR = 'CREATE_NEXUS_ERROR';

export const GET_NEXUS_LIST = 'GET_NEXUS_LIST';
export const GET_NEXUS_LIST_SUCCESS = 'GET_NEXUS_LIST_SUCCESS';
export const GET_NEXUS_LIST_ERROR = 'GET_NEXUS_LIST_ERROR';

export const DELETE_NEXUS = 'DELETE_NEXUS';
export const DELETE_NEXUS_SUCCESS = 'DELETE_NEXUS_SUCCESS';
export const DELETE_NEXUS_ERROR = 'DELETE_NEXUS_ERROR';

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR';

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR';

export const GET_SUBSCRIPTION_PLANS = 'GET_SUBSCRIPTION_PLANS';
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS';
export const GET_SUBSCRIPTION_PLANS_ERROR = 'GET_SUBSCRIPTION_PLANS_ERROR';

export const GET_SUBSCRIPTION_COUPON = 'GET_SUBSCRIPTION_COUPON';
export const GET_SUBSCRIPTION_COUPON_SUCCESS = 'GET_SUBSCRIPTION_COUPON_SUCCESS';
export const GET_SUBSCRIPTION_COUPON_ERROR = 'GET_SUBSCRIPTION_COUPON_ERROR';

export const REMOVE_SUBSCRIPTION_COUPON = 'REMOVE_SUBSCRIPTION_COUPON';

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR';

export const GET_STUDIO_LABS_IN_STUDIO = 'GET_STUDIO_LABS_IN_STUDIO';
export const GET_STUDIO_LABS_IN_STUDIO_SUCCESS = 'GET_STUDIO_LABS_IN_STUDIO_SUCCESS';
export const GET_STUDIO_LABS_IN_STUDIO_ERROR = 'GET_STUDIO_LABS_IN_STUDIO_ERROR';

export const ADD_STUDIO_LAB_TO_STUDIO = 'ADD_STUDIO_LAB_TO_STUDIO';
export const ADD_STUDIO_LAB_TO_STUDIO_SUCCESS = 'ADD_STUDIO_LAB_TO_STUDIO_SUCCESS';
export const ADD_STUDIO_LAB_TO_STUDIO_ERROR = 'ADD_STUDIO_LAB_TO_STUDIO_ERROR';

export const GET_ALL_LABS = 'GET_ALL_LABS';
export const GET_ALL_LABS_SUCCESS = 'GET_ALL_LABS_SUCCESS';
export const GET_ALL_LABS_ERROR = 'GET_ALL_LABS_ERROR';

// Users
export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_ERROR = 'GET_USER_LIST_ERROR';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const CREATE_STUDIO_USER_INVITATION = 'CREATE_STUDIO_USER_INVITATION';
export const CREATE_STUDIO_USER_INVITATION_SUCCESS = 'CREATE_STUDIO_USER_INVITATION_SUCCESS';
export const CREATE_STUDIO_USER_INVITATION_ERROR = 'CREATE_STUDIO_USER_INVITATION_ERROR';

export const CREATE_STUDIO_USER_ACCEPT_INVITATION = 'CREATE_STUDIO_USER_ACCEPT_INVITATION';
export const CREATE_STUDIO_USER_ACCEPT_INVITATION_SUCCESS = 'CREATE_STUDIO_USER_ACCEPT_INVITATION_SUCCESS';
export const CREATE_STUDIO_USER_ACCEPT_INVITATION_ERROR = 'CREATE_STUDIO_USER_ACCEPT_INVITATION_ERROR';

export const GET_STUDIO_USER_INVITATION_DETAILS = 'GET_STUDIO_USER_INVITATION_DETAILS';
export const GET_STUDIO_USER_INVITATION_DETAILS_SUCCESS = 'GET_STUDIO_USER_INVITATION_DETAILS_SUCCESS';
export const GET_STUDIO_USER_INVITATION_DETAILS_ERROR = 'GET_STUDIO_USER_INVITATION_DETAILS_ERROR';

export const GET_STUDIO_USERS = 'GET_STUDIO_USERS';
export const GET_STUDIO_USERS_SUCCESS = 'GET_STUDIO_USERS_SUCCESS';
export const GET_STUDIO_USERS_ERROR = 'GET_STUDIO_USERS_ERROR';

export const DELETE_STUDIO_USER_INVITATION = 'DELETE_STUDIO_USER_INVITATION';
export const DELETE_STUDIO_USER_INVITATION_SUCCESS = 'DELETE_STUDIO_USER_INVITATION_SUCCESS';
export const DELETE_STUDIO_USER_INVITATION_ERROR = 'DELETE_STUDIO_USER_INVITATION_ERROR';

// Studio credit system
export const CREATE_STUDIO_CREDIT_PURCHASE_REQUEST = 'CREATE_STUDIO_CREDIT_PURCHASE_REQUEST';
export const CREATE_STUDIO_CREDIT_PURCHASE_SUCCESS = 'CREATE_STUDIO_CREDIT_PURCHASE_SUCCESS';
export const CREATE_STUDIO_CREDIT_PURCHASE_ERROR = 'CREATE_STUDIO_CREDIT_PURCHASE_ERROR';

export const UPDATE_MY_ACCOUNT_STUDIO_FROM_CABLE = 'UPDATE_MY_ACCOUNT_STUDIO_FROM_CABLE';

export const RESET_USER_SETTINGS_STATE = 'RESET_USER_SETTINGS_STATE';

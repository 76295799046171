import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Plugins
import moment, { Moment } from 'moment';

// Images
import iconInfoBookmark from '@/assets/images/icon-info-bookmark.svg';
import iconInfoShield from '@/assets/images/icon-info-shield.svg';
import iconInfoWarning from '@/assets/images/icon-info-warning.svg';
import iconInfo from '@/assets/images/icon-info.svg';

// Styles
import './style.css';

// Types
import { Studio, StudioVerificationStatus, User } from '@/types';

enum BannerStage {
  VerificationInform = 'verification-inform',
  VerificationWarning = 'verification-warning',
  VerificationDisabled = 'verification-disabled',
  CertificationInform = 'certification-inform',
  CertificationWarning = 'certification-warning',
  CertificationDisabled = 'certification-disabled'
}

interface BannerContent {
  icon: string;
  title: string;
  description: string;
  link?: string;
  buttonText: string;
}

const VERIFICATION_DAYS_INFORM: number = 30;
const VERIFICATION_DAYS_WARNING: number = 10;
const VERIFICATION_DAYS_DISABLED: number = 0;

const CERTIFICATION_DAYS_INFORM: number = 21;
const CERTIFICATION_DAYS_WARNING: number = 10;
const CERTIFICATION_DAYS_DISABLED: number = 0;

const ALL_STUDIOS = 'all-studios';
const MANAGE_STUDIO_SETTINGS = 'manage_studio_settings';

const VerificationBanner: FC = () => {
  const { user: loginUser } = useSelector((state: any) => state.login);
  const { studio: usersStudio, user: usersUser, requesting: usersRequesting } = useSelector((state: any) => state.users);

  const studio: Studio = usersStudio;
  const { id: studioId, studio_verification: studioVerification } = studio ?? {};

  const user: User = Object.keys(usersUser ?? {}).length ? usersUser : loginUser;
  const { roles: userRoles } = user;

  const bannerContent: Record<BannerStage, BannerContent> = {
    [BannerStage.VerificationInform]: {
      icon: iconInfoBookmark,
      title: 'Congrats! You’re now a high-volume seller!',
      description:
        'In compliance with the INFORM Consumers Act (ICA), all high-volume sellers must verify their business to continue selling on PhotoDay. ICA provides a national standard that ensures consumers a safe online buying experience.',
      link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
      buttonText: 'Verify Now'
    },
    [BannerStage.VerificationWarning]: {
      icon: iconInfo,
      title: 'Please verify your business to continue selling.',
      description:
        "As a high-volume seller in PhotoDay, you must verify your business to continue selling products to customers. If you don’t verify, your customers won't be able to view or order from their galleries.",
      link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
      buttonText: 'Verify Now'
    },
    [BannerStage.VerificationDisabled]: {
      icon: iconInfoWarning,
      title: 'Customer Galleries are currently disabled. Please verify your business.',
      description:
        'Your customers cannot access their galleries, view photos, or purchase products until you successfully verify your business. This is in compliance with the INFORM Consumers Act (ICA) to ensure a safe online buying experience for consumers.',
      link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
      buttonText: 'Verify Now'
    },
    [BannerStage.CertificationInform]: {
      icon: iconInfoShield,
      title: 'It’s time to certify your account',
      description:
        'Your annual deadline is here! Let us know if your business information is up to date on our platform. As a verified high-volume seller, you must confirm or update your business information every year.',
      link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
      buttonText: 'Review Business Information'
    },
    [BannerStage.CertificationWarning]: {
      icon: iconInfoShield,
      title: 'Please certify your account to continue selling.',
      description: `As a high-volume seller, you must annually confirm your business information is still correct or update it by going through verification again. To continue selling through PhotoDay, complete your certification before ${moment.utc(studioVerification?.certify_at).add(CERTIFICATION_DAYS_INFORM, 'days').format('L')}.`,
      link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
      buttonText: 'Review Business Information'
    },
    [BannerStage.CertificationDisabled]: {
      icon: iconInfoWarning,
      title: 'Customer Galleries are currently disabled. Please certify your business.',
      description:
        'Your customers cannot access their galleries, view photos, or purchase products until you successfully certify your business. PhotoDay must confirm or update your business information annually in compliance with the INFORM Consumers Act (ICA).',
      link: 'https://support.photoday.io/en/articles/8828210-informed-consumers-act-ica-faq',
      buttonText: 'Certify Now'
    }
  };
  const shouldSeeCta = studioId !== ALL_STUDIOS && userRoles?.some((role: string) => role === MANAGE_STUDIO_SETTINGS);

  const [currentStage, setCurrentStage] = useState<BannerStage>();
  const [daysRemaining, setDaysRemaining] = useState<number>();

  useEffect(() => {
    const {
      verification_required: isStudioVerificationRequired,
      verification_status: studioVerificationStatus,
      verification_deadline: studioVerificationDeadline,
      certification_required: isStudioCertificationRequired
    } = studio;
    const { certify_at: studioCertRequiredDate } = studioVerification ?? {};
    const todaysDate: Moment = moment.utc().startOf('day');

    let currentStage;

    // Verification
    if (isStudioVerificationRequired) {
      if (studioVerificationStatus !== StudioVerificationStatus.Verified) {
        const verificationDeadlineDate: Moment = moment.utc(studioVerificationDeadline).startOf('day');
        const todaysDiffFromVerfiDeadline: number = verificationDeadlineDate.diff(todaysDate, 'days');

        if (todaysDiffFromVerfiDeadline <= VERIFICATION_DAYS_INFORM && todaysDiffFromVerfiDeadline > VERIFICATION_DAYS_WARNING) {
          currentStage = BannerStage.VerificationInform;
        } else if (todaysDiffFromVerfiDeadline <= VERIFICATION_DAYS_WARNING && todaysDiffFromVerfiDeadline > VERIFICATION_DAYS_DISABLED) {
          currentStage = BannerStage.VerificationWarning;
        } else if (todaysDiffFromVerfiDeadline <= VERIFICATION_DAYS_DISABLED) {
          currentStage = BannerStage.VerificationDisabled;
        }

        setDaysRemaining(todaysDiffFromVerfiDeadline);
      }
    }

    // Certification
    if (isStudioCertificationRequired) {
      // server def implies certification_status != 'certified'
      const certificationStartDate: Moment = moment.utc(studioCertRequiredDate).startOf('day');
      const certificationDeadlineDate: Moment = certificationStartDate.add(CERTIFICATION_DAYS_INFORM, 'days');
      const todaysDiffFromCertDeadline: number = certificationDeadlineDate.diff(todaysDate, 'days');

      if (todaysDiffFromCertDeadline <= CERTIFICATION_DAYS_INFORM && todaysDiffFromCertDeadline > CERTIFICATION_DAYS_WARNING) {
        currentStage = BannerStage.CertificationInform;
      } else if (todaysDiffFromCertDeadline <= CERTIFICATION_DAYS_WARNING && todaysDiffFromCertDeadline > CERTIFICATION_DAYS_DISABLED) {
        currentStage = BannerStage.CertificationWarning;
      } else if (todaysDiffFromCertDeadline <= CERTIFICATION_DAYS_DISABLED) {
        currentStage = BannerStage.CertificationDisabled;
      }

      setDaysRemaining(todaysDiffFromCertDeadline);
    }

    if (currentStage) setCurrentStage(currentStage);
  }, [studio]);

  if (!currentStage || usersRequesting) return null;

  return (
    <aside
      className={`flex justify-between flex-nowrap wrap-md gap-7 panel panel--spacious animate business-verification__banner business-verification__banner--${currentStage}`}
    >
      <hgroup className="basis-10/12 md:basis-full">
        <h1 className={`flex gap-4 items-center text-headline-md business-verification__title--${currentStage}`}>
          <img src={bannerContent[currentStage]?.icon} height="24" alt="" />
          {bannerContent[currentStage]?.title}
        </h1>
        <h5 className="text--normal text-headline-2xs m-0">
          {bannerContent[currentStage]?.description}{' '}
          {bannerContent[currentStage]?.link && (
            <a href={bannerContent[currentStage]?.link} target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          )}
        </h5>
      </hgroup>
      <aside className="basis-2/12 md:basis-full flex flex-col justify-center items-center gap-2.5">
        {shouldSeeCta && (
          <Link className={`button font-semibold button--large business-verification__button--${currentStage}`} to="/user/my-studio/verification">
            {bannerContent[currentStage]?.buttonText}
          </Link>
        )}
        {Number(daysRemaining) > 0 && (
          <span className={`text-body-sm font-semibold ${daysRemaining === 0 ? 'text-error-500' : ''}`}>{daysRemaining} Days Remaining</span>
        )}
      </aside>
    </aside>
  );
};

export default VerificationBanner;

export enum ReportProductTypes {
  All = 'all',
  Packages = 'package',
  ALaCarte = 'product',
  DigitalBundles = 'bundle',
  PhotoAddOns = 'photo_add_ons',
  DownloadAll = 'download_all'
}

export interface ReportProduct {
  id: string;
  name: string;
  type: string;
  price_sheet: string;
  price_sheet_id: string;
  quantity: number;
  retail_sold: number;
  digital_bundles?: { size: number; qty: number }[];
}

export interface ReportProductParams {
  start_date?: string;
  end_date?: string;
  jobs?: any;
  type?: string;
}

import React from 'react';
import { Form, reduxForm } from 'redux-form';

import { Switch } from '@/components/Shared/Forms';

const NPS_NOTIFICATIONS_ENABLED = import.meta.env.VITE_NPS_NOTIFICATIONS_ENABLED === '1';

const AccountNotificationForm = ({ loading, requesting, handleSubmit }) => {
  return (
    <section className="basis-full" data-cy="notification-form">
      <Form onSubmit={handleSubmit}>
        <header className="flex items-center justify-between user-container__header">
          <h2 className="text-headline-sm">Notifications</h2>
          <button type="submit" className="button button--medium" disabled={requesting || loading}>
            Save
          </button>
        </header>
        <main className="panel panel--section">
          <Switch
            name="daily_order_notification"
            label={<h5 className="text-body-lg">Daily Order Summary</h5>}
            note="This email summary gets sent once daily if there are any orders placed that day."
          />
          {NPS_NOTIFICATIONS_ENABLED ? (
            <>
              <Switch
                name="daily_nps_summary"
                label={<h5 className="text-body-lg">Daily NPS Response Summary</h5>}
                note="Receive an email daily if customers submit an NPS rating."
              />
              <Switch
                name="weekly_nps_summary"
                label={<h5 className="text-body-lg">Weekly NPS Response Summary</h5>}
                note="Receive a weekly email summary if customers submit an NPS rating."
              />
            </>
          ) : null}
        </main>
      </Form>
    </section>
  );
};

export default reduxForm({
  form: 'AccountNotificationForm',
  enableReinitialize: true,
  destroyOnUnmount: false
})(AccountNotificationForm);

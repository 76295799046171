import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import QuicktipItem from './QuicktipItem';

// Helpers
import { offerListRequest } from '../Offers/actions';
import { creditSheetListRequest } from '../AdvancePay/actions';
import { getMyAccountStudioRequest } from '../../Settings/actions';
import { getPricesheetListRequest } from '../PriceSheets/actions';
import { getStudioThemeCollections } from '../CustomThemes/actions';
import { getUserStripeClientId } from '../../Settings/Studio/Payment/Stripe/selectors';

// Images
import ReactImageOffers from '@/assets/images/image-offers.svg?react';
import ReactImageStripe from '@/assets/images/image-stripe-card.svg?react';
import ReactImageAdvancePay from '@/assets/images/image-advancepay.svg?react';
import ReactImageBackgrounds from '@/assets/images/image-backgrounds.svg?react';
import ReactImagePricesheets from '@/assets/images/image-price-sheets.svg?react';
import ReactImageCustomThemes from '@/assets/images/image-custom-themes.svg?react';

// Styles
import './quicktips.css';

const mapStateToProps = (state) => {
  const {
    login: { user, entities }
  } = state;
  const studio = Object.values(entities.studio)[0] || {};
  const { feature_flags: studioFeatureFlags, labs: studioLabs } = studio;

  const stripeConnected = Boolean(getUserStripeClientId(state));

  return {
    user,
    studioLabs,
    studioFeatureFlags,
    studioId: studio.id || '',
    isDonePayments: stripeConnected,
    isDonePriceSheets: !!state.pricesheets.result.length,
    isDoneOffers: !!state.offers.result.length,
    isDoneAdvancePay: !!state.advancepay.result.length,
    isDoneCustomThemes: state.customThemes.collections.length
  };
};

const mapDispatchToProps = {
  getPricesheetListRequest,
  getStudioThemeCollections,
  offerListRequest,
  getMyAccountStudioRequest,
  creditSheetListRequest
};

const CUSTOM_THEMES_ENABLED = import.meta.env.VITE_CUSTOM_THEMES_ENABLED === '1';
const KNOCK_OUT_BACKGROUND_ENABLED = import.meta.env.VITE_KNOCK_OUT_BACKGROUND_ENABLED === '1';

const QuickTips = (props) => {
  const {
    user,
    studioId,
    offerListRequest,
    creditSheetListRequest,
    studioFeatureFlags,
    studioLabs,
    isDonePayments,
    isDonePriceSheets,
    isDoneOffers,
    isDoneAdvancePay,
    getPricesheetListRequest,
    getMyAccountStudioRequest,
    getStudioThemeCollections
  } = props;
  const roles = (user && user.roles) || [];

  const studioBackgroundLabs = studioLabs && studioLabs.length ? studioLabs.filter((lab) => lab.allow_backgrounds).map((lab) => lab.name) : [];
  const showBackgrounds = KNOCK_OUT_BACKGROUND_ENABLED && studioBackgroundLabs.length > 0;

  const studioCustomThemeLabs = studioLabs && studioLabs.length ? studioLabs.filter((lab) => lab.allow_custom_themes).map((lab) => lab.name) : [];
  const showCustomThemes =
    CUSTOM_THEMES_ENABLED && studioFeatureFlags && studioFeatureFlags.allow_custom_themes
      ? [...studioFeatureFlags.allow_custom_themes, ...studioCustomThemeLabs].length > 0
      : false;

  useEffect(() => {
    if (studioId !== 'all-studios') {
      getMyAccountStudioRequest({ studioId });
    }

    if (roles.includes('view_price_sheets')) {
      getStudioThemeCollections();
      getPricesheetListRequest({ page: 1, perPage: 15, order: 'created_at', dir: 'DESC' });
    }

    if (roles.includes('view_offers')) {
      offerListRequest({ page: 1, perPage: 15 });
    }

    if (roles.includes('view_gift_cards')) {
      creditSheetListRequest({ page: 1, perPage: 15, order: 'created_at', dir: 'DESC' });
    }
  }, []);

  return (
    <>
      <div id="storefront__quicktips">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-headline-sm">Quick tips to get started</h2>
            <p>Follow these steps to get your storefront up and running.</p>
          </div>
        </div>

        <div className="quick-tips">
          {studioId !== 'all-studios' && roles.includes('manage_stripe') && (
            <QuicktipItem
              title="Payment Settings"
              description="Sign up with Stripe to accept online payments."
              image={<ReactImageStripe />}
              url="/user/my-studio/payment"
              linkLabel={isDonePayments ? 'Update' : 'Set up'}
              isDone={isDonePayments}
              backgroundColor="purple"
            />
          )}

          {studioId !== 'all-studios' && roles.includes('view_gift_cards') && (
            <QuicktipItem
              title="AdvancePay"
              description="Add credit sheets to offer pre-pay for AdvancePay galleries."
              image={<ReactImageAdvancePay className="self-end" />}
              url="./advance-pay"
              linkLabel={isDoneAdvancePay ? 'Update' : 'Set up'}
              isDone={isDoneAdvancePay}
              backgroundColor="yellow"
            />
          )}

          {roles.includes('view_price_sheets') && (
            <QuicktipItem
              title="Price Sheets"
              description="Create custom price sheets that include packages and products."
              image={<ReactImagePricesheets className="self-end" />}
              url="./price-sheets"
              linkLabel={isDonePriceSheets ? (roles.includes('manage_price_sheets') ? 'Update' : 'View') : 'Set up'}
              isDone={isDonePriceSheets}
              backgroundColor="red"
            />
          )}

          {roles.includes('view_offers') && (
            <QuicktipItem
              title="Offers"
              description="Incentivize customers to buy credits and products."
              image={<ReactImageOffers className="self-end" />}
              url="./offers-and-credits"
              linkLabel={isDoneOffers ? 'Update' : 'Set up'}
              isDone={isDoneOffers}
              backgroundColor="green"
            />
          )}

          {roles.includes('view_price_sheets') && showCustomThemes && (
            <QuicktipItem
              title="Custom Themes"
              description="Upload your own custom-designed Memory Mate themes."
              image={<ReactImageCustomThemes className="self-end" />}
              url="./custom-themes"
              linkLabel={isDoneAdvancePay ? 'Update' : 'Set up'}
              isDone={isDoneAdvancePay}
              backgroundColor="blue"
            />
          )}

          {roles.includes('view_price_sheets') && showBackgrounds && (
            <QuicktipItem
              title="Backgrounds"
              description="Upload your own backgrounds for PNG files."
              learnMoreLink="https://support.photoday.io/en/articles/4873011-getting-started-with-photoday-s-backgrounds-feature"
              image={<ReactImageBackgrounds className="self-end" />}
              url="./backgrounds"
              linkLabel={isDoneAdvancePay ? 'Update' : 'Set up'}
              isDone={false}
              backgroundColor="purple"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickTips);

import { FC, useState, useEffect, ChangeEvent } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { updateUser, getUser, createStudioUserInvitation, getStudioUsers, deleteStudioUserInvitation } from '../../actions';

// Plugins
import { Tooltip } from 'react-tippy';

// Components
import Pagination from '@/components/Shared/Pagination';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Types
import { User, Invitation, TablePagination, StudioRolesType } from '@/types';

const StudioUsers: FC = () => {
  const dispatch = useDispatch();
  const {
    requesting: usersRequesting,
    invitations: { list: invitationsList, pagination: invitationsPagination },
    requesting: invitationsRequesting
  }: { invitations: { list: Invitation[]; pagination: TablePagination }; requesting: boolean } = useSelector((state: any) => state.users);
  const { studio: usersStudio } = useSelector((state: any) => state.users);

  const requesting: boolean = usersRequesting || invitationsRequesting;

  // State
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [studioRole, setStudioRole] = useState<string>(StudioRolesType.CustomRole);
  const [search, setSearch] = useState('');
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserId, setCurrentUserId] = useState<string>('');
  const [showUserPermission, setShowUserPermission] = useState<boolean>(false);
  const [showEditInviteUser, setShowEditInviteUser] = useState<boolean>(false);
  const [isEditingUser, setIsEditingUser] = useState<boolean>(false);
  const [currentUserPermissions, setCurrentUserPermissions] = useState<string[]>([]);
  const [tableFilter, setTableFilter] = useState({
    per_page: invitationsPagination.perPage,
    search: '',
    page: invitationsPagination.page
  });

  // Helpers
  const fetchCaptureInvitations = (page: number = invitationsPagination.page) => dispatch(getStudioUsers({ page, per_page: invitationsPagination.perPage }));

  function getStudioRoleName(studioRole: string): string | undefined {
    const studioRoles: { [key: string]: string } = {
      customer_service: 'Customer Service',
      custom_role: 'Custom'
    };

    return studioRoles[studioRole];
  }

  const resetState = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setStudioRole(StudioRolesType.CustomRole);
    setCurrentUserId('');
    setCurrentUser(null);
    setShowUserPermission(false);
    setCurrentUserPermissions([]);
  };

  // Handlers
  const handlePaginationChange = (page: number) => fetchCaptureInvitations(page);

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStudioRole(e.target.value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => setSearch(e.target.value);

  const handleUserInvite = () => {
    dispatch(
      createStudioUserInvitation({ firstName, lastName, email, studioRole }, () => {
        fetchCaptureInvitations();
        resetState();
        handleEditInviteUserClose();
      })
    );
  };

  const handleSearch = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTableFilter({ ...tableFilter, search });
    dispatch(getStudioUsers({ ...tableFilter, search }));
  };

  const handleUserDelete = (id: string) => {
    dispatch(deleteStudioUserInvitation({ id }));
  };

  const handleEditUserOpen = (invitedUser: Invitation) => {
    setFirstName(invitedUser.first_name);
    setLastName(invitedUser.last_name);
    setEmail(invitedUser.email);
    setCurrentUserId(invitedUser.id);
    setStudioRole(invitedUser.studio_role);
    setShowEditInviteUser(true);
    setIsEditingUser(true);
  };

  const handleConfirmEditUser = () => {
    dispatch(
      updateUser({ id: currentUserId, first_name: firstName, last_name: lastName, studio_role: studioRole }, () => {
        fetchCaptureInvitations();
        handleEditInviteUserClose();
      })
    );
  };

  const handleEditInviteUserClose = () => {
    resetState();
    if (isEditingUser) setIsEditingUser(false);
    setShowEditInviteUser(false);
  };

  const handleUserPermissionOpen = (invitedUser: Invitation) => {
    dispatch(
      getUser(invitedUser, ({ data }: any) => {
        setCurrentUser(data);
        setCurrentUserPermissions(data.roles || []);
        setShowUserPermission(true);
      })
    );
  };

  const handleUserPermissionClose = () => resetState();

  const handleUserPermissionSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const permissions = name.trim().split(' ');
    const permission = permissions[0];
    const permissionDependencies = permissions.slice(1);

    let updatedPermissions = currentUserPermissions.filter((perm) => perm !== permission);

    if (checked) {
      updatedPermissions = [...updatedPermissions, permission, ...permissionDependencies];
    }

    setCurrentUserPermissions(updatedPermissions);
  };

  const handleUserPermissionSave = () => {
    const current = currentUser as User;

    dispatch(
      updateUser({ id: current.id, roles: currentUserPermissions }, () => {
        fetchCaptureInvitations();
        resetState();
      })
    );
  };

  useEffect(() => {
    fetchCaptureInvitations();
  }, []);

  return (
    <>
      <header className="flex items-center my-studio__header" data-cy="my-studio-users-header">
        <h2 className="text-headline-sm">Users</h2>
      </header>

      <p className="mb-5">
        Invite other users to your PhotoDay account and control their access permissions. After setting up their account, each invited user can log in to the
        PhotoDay Capture App, Studio Panel, or both. You can add/update user permissions here.{' '}
        <a href="https://support.photoday.io/en/articles/5025769-how-can-i-manage-my-user-s-permissions" target="_blank" rel="noopener noreferrer">
          Learn More.
        </a>
      </p>

      <div className="flex justify-between">
        <button className="button self-start" onClick={() => setShowEditInviteUser(true)}>
          Invite User
        </button>
        <form onSubmit={handleSearch}>
          <fieldset className="fieldset--lean">
            <input type="search" name="search" placeholder="Search Users" maxLength={50} value={search} onChange={handleSearchChange} />
            <button className="button button--icon" name="button" type="submit">
              <i className="icon-search" />
            </button>
          </fieldset>
        </form>
      </div>

      {requesting ? (
        <TableLoader rows={6} rowHeight={40} />
      ) : (
        <>
          {invitationsList?.length ? (
            <>
              <article className="table-box">
                <table className="table">
                  <thead className="table__header">
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      {usersStudio?.feature_flags?.allow_studio_roles && <th>Role</th>}
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="table__body">
                    {invitationsList.map((invitation) => (
                      <tr key={invitation.id}>
                        <td data-header="First Name">{invitation.first_name}</td>
                        <td data-header="Last Name">{invitation.last_name}</td>
                        <td data-header="Email">{invitation.email}</td>
                        {usersStudio?.feature_flags?.allow_studio_roles && <td data-header="Role">{getStudioRoleName(invitation.studio_role)}</td>}
                        <td data-header="Status">{invitation.accepted ? 'Accepted' : 'Invited'}</td>
                        <td data-header="Actions">
                          <Tooltip {...{ title: 'Edit User' }}>
                            <button
                              className="button button--clean"
                              name="permissions"
                              type="button"
                              onClick={() => handleEditUserOpen(invitation)}
                              disabled={requesting}
                            >
                              <i className="icon-edit" />
                            </button>
                          </Tooltip>
                          {invitation?.studio_role === StudioRolesType.CustomRole && (
                            <Tooltip {...{ title: 'Permissions' }}>
                              <button
                                className="button button--clean"
                                name="permissions"
                                type="button"
                                onClick={() => handleUserPermissionOpen(invitation)}
                                disabled={requesting}
                              >
                                <i className="icon-key" />
                              </button>
                            </Tooltip>
                          )}
                          <Tooltip {...{ title: 'Remove User' }}>
                            <button
                              className="button button--clean"
                              name="remove"
                              type="button"
                              onClick={() => handleUserDelete(invitation.id)}
                              disabled={requesting}
                            >
                              <i className="icon-trash" />
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </article>
              {invitationsPagination.total > invitationsPagination.perPage && (
                <Pagination pagination={invitationsPagination} onPagination={handlePaginationChange} />
              )}
            </>
          ) : (
            <aside className="flex items-center justify-center panel panel--nomargin panel--tall">
              <h2>Invite your first user</h2>
            </aside>
          )}
        </>
      )}

      {/* User permissions modal */}
      {showUserPermission && (
        <aside className="modal animate">
          <div className="modal__box">
            <header className="modal__header">
              <button className="button button--action modal__close" name="close" type="button" onClick={handleUserPermissionClose}>
                <i className="icon-close"></i>
              </button>
              <h3 className="text-left">Edit permission for {currentUser?.email}</h3>
            </header>

            <main className="modal__content text-left">
              <h5>Financials</h5>
              <ul className="mb-5">
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can view sales</span>
                  <input
                    id="viewSalesSwitch"
                    className="hidden"
                    name="view_sales"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('view_sales')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="viewSalesSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can manage Stripe</span>
                  <input
                    id="manageStripeSwitch"
                    className="hidden"
                    name="manage_stripe"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('manage_stripe')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="manageStripeSwitch" />
                </li>
              </ul>
              <h5>Studio</h5>
              <ul className="mb-5">
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can Access Studio Panel</span>
                  <input
                    id="studioPanelSwitch"
                    className="hidden"
                    name="studio_access"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('studio_access')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="studioPanelSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can manage studio</span>
                  <input
                    id="manageStudioSwitch"
                    className="hidden"
                    name="manage_studio_settings"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('manage_studio_settings')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="manageStudioSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Has access to Capture</span>
                  <input
                    id="accessCaptureSwitch"
                    className="hidden"
                    name="capture_access"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('capture_access')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="accessCaptureSwitch" />
                </li>
              </ul>
              <h5>Store</h5>
              <ul className="mb-5">
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can view price sheets</span>
                  <input
                    id="viewPriceSheetsSwitch"
                    className="hidden"
                    name="view_price_sheets"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('view_price_sheets')}
                    onChange={handleUserPermissionSwitch}
                    disabled={currentUserPermissions?.includes('manage_price_sheets')}
                  />
                  <label className="label-switch label-switch--small" htmlFor="viewPriceSheetsSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can manage price sheets</span>
                  <input
                    id="managePricesheetsSwitch"
                    className="hidden"
                    name="manage_price_sheets view_price_sheets"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('manage_price_sheets')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="managePricesheetsSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can view AdvancePay credits</span>
                  <input
                    id="viewAdvancePaySwitch"
                    className="hidden"
                    name="view_gift_cards"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('view_gift_cards')}
                    onChange={handleUserPermissionSwitch}
                    disabled={currentUserPermissions?.includes('manage_gift_cards')}
                  />
                  <label className="label-switch label-switch--small" htmlFor="viewAdvancePaySwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can manage AdvancePay credits</span>
                  <input
                    id="manageAdvancePaySwitch"
                    className="hidden"
                    name="manage_gift_cards view_gift_cards"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('manage_gift_cards')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="manageAdvancePaySwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can view offers</span>
                  <input
                    id="viewOffersSwitch"
                    className="hidden"
                    name="view_offers"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('view_offers')}
                    onChange={handleUserPermissionSwitch}
                    disabled={currentUserPermissions?.includes('manage_offers')}
                  />
                  <label className="label-switch label-switch--small" htmlFor="viewOffersSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can manage offers</span>
                  <input
                    id="manageOffersSwitch"
                    className="hidden"
                    name="manage_offers view_offers"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('manage_offers')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="manageOffersSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can view promos</span>
                  <input
                    id="viewPromosSwitch"
                    className="hidden"
                    name="view_promos"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('view_promos')}
                    onChange={handleUserPermissionSwitch}
                    disabled={currentUserPermissions?.includes('manage_promos')}
                  />
                  <label className="label-switch label-switch--small" htmlFor="viewPromosSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can manage promos</span>
                  <input
                    id="managePromosSwitch"
                    className="hidden"
                    name="manage_promos view_promos"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('manage_promos')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="managePromosSwitch" />
                </li>
              </ul>
              <h5>Job</h5>
              <ul className="mb-5">
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can view jobs</span>
                  <input
                    id="viewJobsSwitch"
                    className="hidden"
                    name="view_jobs"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('view_jobs')}
                    onChange={handleUserPermissionSwitch}
                    disabled={currentUserPermissions?.includes('manage_jobs')}
                  />
                  <label className="label-switch label-switch--small" htmlFor="viewJobsSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can manage jobs (add/edit/delete)</span>
                  <input
                    id="manageJobsSwitch"
                    className="hidden"
                    name="manage_jobs view_jobs view_clients"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('manage_jobs')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="manageJobsSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can view insights</span>
                  <input
                    id="viewJobInsightsSwitch"
                    className="hidden"
                    name="view_insights"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('view_insights')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="viewJobInsightsSwitch" />
                </li>
              </ul>
              <h5>Organizations</h5>
              <ul className="mb-5">
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can view organizations</span>
                  <input
                    id="viewOrganizationsSwitch"
                    className="hidden"
                    name="view_clients"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('view_clients')}
                    onChange={handleUserPermissionSwitch}
                    disabled={currentUserPermissions?.some((role) => 'manage_jobs manage_clients'.includes(role))}
                  />
                  <label className="label-switch label-switch--small" htmlFor="viewOrganizationsSwitch" />
                </li>
                <li className="flex items-center justify-between mb-2.5">
                  <span>Can manage organizations (add/edit/delete)</span>
                  <input
                    id="manageOrganizationsSwitch"
                    className="hidden"
                    name="manage_clients view_clients"
                    type="checkbox"
                    checked={currentUserPermissions?.includes('manage_clients')}
                    onChange={handleUserPermissionSwitch}
                  />
                  <label className="label-switch label-switch--small" htmlFor="manageOrganizationsSwitch" />
                </li>
              </ul>
              {usersStudio?.feature_flags?.allow_studio_roles && (
                <>
                  <h5>Customer Service</h5>
                  <ul className="mb-5">
                    <li className="flex items-center justify-between mb-2.5">
                      <span>Has access to customer service portal</span>
                      <input
                        id="accessCustomerServicePortalSwitch"
                        className="hidden"
                        name="customer_service_portal"
                        type="checkbox"
                        checked={currentUserPermissions?.includes('customer_service_portal')}
                        onChange={handleUserPermissionSwitch}
                      />
                      <label className="label-switch label-switch--small" htmlFor="accessCustomerServicePortalSwitch" />
                    </li>
                  </ul>
                </>
              )}
            </main>
            <footer className="modal__footer">
              <button className="button button--center button--large" name="save" type="button" data-loading={requesting} onClick={handleUserPermissionSave}>
                Save
              </button>
            </footer>
          </div>
        </aside>
      )}

      {/* Invite/Edit User Modal */}
      {showEditInviteUser && (
        <aside className="modal animate">
          <div className="modal__box">
            <header className="modal__header">
              <h3 className="text-left">{isEditingUser ? 'Edit User' : 'Invite a User'}</h3>
            </header>
            <main className="modal__content text-left">
              <div className="flex gap-2.5 flex-nowrap">
                <fieldset className="flex basis-6/12 flex-col">
                  <label className="label mb-1" htmlFor="first-name">
                    First Name
                  </label>
                  <input id="first-name" className="input" name="first-name" type="text" value={firstName} onChange={handleFirstNameChange} />
                </fieldset>
                <fieldset className="flex basis-6/12 flex-col">
                  <label className="label mb-1" htmlFor="last-name">
                    Last Name
                  </label>
                  <input id="last-name" className="input" name="last-name" type="text" value={lastName} onChange={handleLastNameChange} />
                </fieldset>
              </div>
              <fieldset className="flex flex-col">
                <label className="label mb-1" htmlFor="email">
                  Email
                </label>
                <input id="email" className="input" name="email" type="email" value={email} onChange={handleEmailChange} disabled={isEditingUser} />
              </fieldset>
              {usersStudio?.feature_flags?.allow_studio_roles && (
                <fieldset className="flex flex-col">
                  <label htmlFor="studio_role">Role</label>
                  <div className="flex gap-4">
                    <div className="flex flex-nowrap items-center	 basis-2/6 sm:basis-6/12">
                      <input
                        id="custom_role"
                        type="radio"
                        name="studio_role"
                        value="custom_role"
                        checked={studioRole === StudioRolesType.CustomRole}
                        onChange={handleRoleChange}
                      />
                      <label htmlFor="custom_role" className="m-0">
                        Custom
                      </label>
                    </div>
                    <div className="flex flex-nowrap items-center basis-2/6 sm:basis-6/12">
                      <input
                        id="customer_service"
                        type="radio"
                        name="studio_role"
                        value="customer_service"
                        checked={studioRole === StudioRolesType.CustomerService}
                        onChange={handleRoleChange}
                      />
                      <label htmlFor="customer_service" className="m-0">
                        Customer Service
                      </label>
                    </div>
                  </div>
                </fieldset>
              )}
            </main>
            <footer className="modal__footer justify-end flex gap-2.5">
              <button className="button button--outline" name="save" type="button" data-loading-dark={requesting} onClick={handleEditInviteUserClose}>
                Cancel
              </button>
              <button className="button" name="save" type="button" data-loading={requesting} onClick={isEditingUser ? handleConfirmEditUser : handleUserInvite}>
                {isEditingUser ? 'Save' : 'Invite'}
              </button>
            </footer>
          </div>
        </aside>
      )}
    </>
  );
};

export default StudioUsers;

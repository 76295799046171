import React, { useState, useEffect } from 'react';
import { Switch, NavLink, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import Security from './Security';
import Information from './Information';
import Notifications from './Notifications';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Styles
import './style.css';

const mapStateToProps = (state) => ({
  user: state.login.user
});

const Account = ({ user }) => {
  const location = useLocation();

  const [section, setSection] = useState('Account Information');
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const setSectionTitle = (path) => {
    const pathArr = path.split('/');
    const section = pathArr[pathArr.length - 1];

    if (section === 'information' && section !== 'Account Information') {
      setSection('Account Information');
    }

    if (section === 'security' && section !== 'Account Security') {
      setSection('Account Security');
    }

    if (section === 'notifications' && section !== 'Notification Settings') {
      setSection('Notification Settings');
    }
  };

  const handleToggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const roles = (user && user.roles) || [];

  useEffect(() => {
    setSectionTitle(location.pathname);
  }, []);

  return (
    <>
      <aside className="basis-3/12 md:basis-full sidebar-container">
        <header className="flex items-center justify-between sidebar-header">
          <h2 className="text-headline-sm">Account</h2>
          <button className="sidebar__mobile-action button button--outline" onClick={handleToggleSidebar}>
            {section}
          </button>
        </header>

        <nav className={`sidebar-navigation ${sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={handleToggleSidebar}>
          <NavLink
            className="sidebar-navigation__item"
            activeClassName="sidebar-navigation__item--active"
            data-cy="account-info-nav"
            to={`/user/account/information`}
          >
            Account Information
          </NavLink>
          <NavLink
            className="sidebar-navigation__item"
            activeClassName="sidebar-navigation__item--active"
            data-cy="account-security-nav"
            to={'/user/account/security'}
          >
            Account Security
          </NavLink>
          {roles.includes('view_sales') && (
            <NavLink
              className="sidebar-navigation__item"
              activeClassName="sidebar-navigation__item--active"
              data-cy="notification-nav"
              to={'/user/account/notifications'}
            >
              Notification Settings
            </NavLink>
          )}
        </nav>
      </aside>

      <section className="basis-9/12 md:basis-full account__section">
        <Switch>
          <Redirect exact from="/user/account" to="/user/account/information" />
          <PrivateRoute exact path="/user/account/information" component={Information} />
          <PrivateRoute exact path="/user/account/security" component={Security} />
          <PrivateRoute exact path="/user/account/notifications" component={Notifications} requiredPermission={'view_sales'} />
        </Switch>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(Account);

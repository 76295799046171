import * as api from '@/utils/http';

export const createPricesheetItem = (payload) => {
  const { priceSheetId, pricesheetItemInfo } = payload;
  const endpoint = `price-sheets/${priceSheetId}/price-sheet-items`;

  return api.post(endpoint, pricesheetItemInfo);
};

export const updatePricesheetItem = (payload) => {
  const { pricesheetItemInfo } = payload;
  const endpoint = `price-sheet-items/${pricesheetItemInfo.id}`;

  return api.put(endpoint, pricesheetItemInfo);
};

export const deletePricesheetItem = (payload) => {
  const endpoint = `price-sheets/${payload.id}/price-sheet-items/delete`;
  const args = { price_sheet_item_ids: payload.price_sheet_item_ids };

  return api.del(endpoint, args);
};

export const copyPricesheetItem = (pricesheetItemId) => {
  const endpoint = `price-sheet-items/${pricesheetItemId}/copy`;

  return api.put(endpoint);
};

export const createPricesheetItemByProducts = (payload) => {
  const { priceSheetId, products } = payload;
  const endpoint = `price-sheets/${priceSheetId}/price-sheet-items/create-by-products`;

  return api.post(endpoint, { products });
};

export const createPriceSheetItemDigitalBundle = (payload) => {
  const endpoint = `price-sheets/${payload.priceSheetId}/price-sheet-items/create-digital-bundle`;
  const args = { ...payload };

  return api.post(endpoint, args);
};

export const createPriceSheetItemDownloadAll = (payload) => {
  const { priceSheetId, price_cents } = payload;
  const endpoint = `price-sheets/${priceSheetId}/price-sheet-items/enable-download-all`;
  const args = { price_cents };

  return api.post(endpoint, args);
};

export const featurePriceSheetProduct = (payload) => {
  const endpoint = `price-sheet-items/${payload.id}`;
  const args = { featured: true };

  return api.put(endpoint, args);
};

export const unfeaturePriceSheetProduct = (payload) => {
  const endpoint = `price-sheet-items/${payload.id}`;
  const args = { featured: false };

  return api.put(endpoint, args);
};

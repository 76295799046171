import { FC } from 'react';

// Plugins
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { Tooltip } from 'react-tippy';

// Images
import ReactIconWarn from '@/assets/images/icon-mark.svg?react';

// Types
import { PriceSheetFieldValues, PriceSheetProductDigitalBundle } from '@/types';

// Constants
import { DIGITAL_BUNDLE_MAX_PRICE, DIGITAL_BUNDLE_MAX_TIERS, DIGITAL_BUNDLE_MAX_PHOTOS } from '../constants';

interface BundlePricing {
  bundleProp: string;
  bundleValue?: number | string | boolean;
  bundleTierValue?: { index: number; prop: string; value?: number };
}

interface DigitalProductSetupProps {
  priceFieldValues: PriceSheetFieldValues;
  priceFieldError?: string;
  digitalProductBundle: PriceSheetProductDigitalBundle;
  hasDownloadAllItem: boolean;
  onPriceChange: (values: NumberFormatValues) => void;
  onBundlePricingTypeChange: ({ bundleType }: { bundleType?: PriceSheetProductDigitalBundle['digital_bundle_pricing_type'] }) => void;
  onBundlePricingChange: ({ bundleProp, bundleValue, bundleTierValue }: BundlePricing) => void;
  onBundleTierChange: ({ action, tierIndex }: { action: 'remove' | 'add'; tierIndex?: number }) => void;
}

const DigitalProductSetup: FC<DigitalProductSetupProps> = ({
  priceFieldValues,
  priceFieldError,
  digitalProductBundle,
  hasDownloadAllItem,
  onPriceChange,
  onBundlePricingTypeChange,
  onBundlePricingChange,
  onBundleTierChange
}: DigitalProductSetupProps) => {
  const isRetailPriceValid = Number(priceFieldValues?.priceCents) > 0;

  return (
    <div className="animate">
      <h5>1. Single Digital Download Price</h5>
      <p>
        <small>Confirm the retail price for a single high-res digital download.</small>
      </p>

      {/* Retail Pricing */}
      <section className="flex justify-between">
        <fieldset className="basis-2/12 fieldset--clean">
          <label>Photos</label>
          <input className="input--clean" type="number" name="photos" value={1} disabled />
        </fieldset>
        <fieldset className="flex basis-6/12 basis-8/12-sm flex-nowrap gap-2.5 fieldset--clean">
          <div>
            <label>Unit Price</label>
            <NumericFormat className="input--clean" value={priceFieldValues.baseCostText} prefix={'$'} decimalScale={2} disabled={true} />
          </div>
          <div>
            <label>PD Fee</label>
            <NumericFormat className="input--clean" value={priceFieldValues.photoDayFeeText} prefix={'$'} decimalScale={2} disabled={true} />
          </div>
          <div>
            <label>Retail Price</label>
            <NumericFormat
              className="input--clean"
              value={priceFieldValues.formattedValue}
              prefix={'$'}
              decimalScale={2}
              allowNegative={false}
              isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PRICE || floatValue === undefined}
              onValueChange={onPriceChange}
            />
          </div>
        </fieldset>
      </section>

      <hr />

      {hasDownloadAllItem && (
        <aside className="flex gap-2.5 bg-warning-500 mb-5 p-3 rounded-md">
          <ReactIconWarn className="stroke-black-base w-5" />
          <p className="m-0 text-body-sm">Digital Bundles are unavailable while the "Download All My Photos" product is enabled.</p>
        </aside>
      )}

      <div className={hasDownloadAllItem ? 'disabled' : ''}>
        <h5>2. Digital Bundle (optional)</h5>

        {/* Tiered Pricing */}
        <section className="mt-5">
          <fieldset className="flex items-center gap-2.5 mb-2.5">
            <h5 className="m-0">Tiered Pricing</h5>
            <Tooltip
              {...{
                title: 'Enter a single digital retail price to enable',
                distance: 5,
                position: 'top',
                trigger: 'mouseenter',
                arrow: true,
                disabled: isRetailPriceValid
              }}
            >
              <input
                id="variablePricing"
                className="hidden"
                type="checkbox"
                checked={digitalProductBundle?.digital_bundle_pricing_type === 'digitals_tiered_pricing'}
                onChange={({ target }) => onBundlePricingTypeChange({ bundleType: target?.checked ? 'digitals_tiered_pricing' : undefined })}
                disabled={!isRetailPriceValid}
              />
              <label htmlFor="variablePricing" className="label-switch label-switch--small" />
            </Tooltip>
          </fieldset>
          <p>
            <small>
              Offer multiple high-res digital downloads by setting the number of photos selected and the price per photo (PPP) or percent discount. Add up to{' '}
              {DIGITAL_BUNDLE_MAX_TIERS} variable tiers.
            </small>
          </p>
          {digitalProductBundle?.digital_bundle_pricing_type === 'digitals_tiered_pricing' && (
            <aside className="mt-5 animate">
              <header className="flex sm:hidden">
                <label className="basis-6/12">Photos</label>
                <label className="basis-2/12">PPP</label>
                <label className="basis-2/12">Percent</label>
              </header>

              {/* Bundles */}
              {digitalProductBundle?.digital_bundle_tiers_attributes
                ?.filter((tier) => !tier._destroy)
                .map((tier, tierIndex, tierArray) => (
                  <fieldset key={tierIndex} className="flex justify-between mb-2.5">
                    <div className="flex basis-2/6 flex-nowrap whitespace-nowrap items-center gap-2.5">
                      <NumericFormat
                        className="input--clean input--auto"
                        decimalScale={0}
                        value={tier?.min_qty}
                        allowNegative={false}
                        disabled={tierIndex > 0}
                        isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PHOTOS || floatValue === undefined}
                        onValueChange={({ floatValue }) =>
                          onBundlePricingChange({
                            bundleProp: 'digital_bundle_tiers_attributes',
                            bundleTierValue: { index: tierIndex, prop: 'min_qty', value: floatValue }
                          })
                        }
                      />
                      <span>to</span>
                      <NumericFormat
                        className="input--clean input--auto"
                        decimalScale={0}
                        value={tier?.max_qty}
                        allowNegative={false}
                        isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PHOTOS || floatValue === undefined}
                        onValueChange={({ floatValue }) =>
                          onBundlePricingChange({
                            bundleProp: 'digital_bundle_tiers_attributes',
                            bundleTierValue: { index: tierIndex, prop: 'max_qty', value: floatValue }
                          })
                        }
                      />
                    </div>
                    <div className="flex basis-6/12 basis-8/12-sm flex-nowrap gap-2.5">
                      <NumericFormat
                        className="input--clean"
                        prefix={'$'}
                        decimalScale={2}
                        value={tier?.price_cents}
                        onChange={({ target }: { target: { value: string } }) =>
                          onBundlePricingChange({
                            bundleProp: 'digital_bundle_tiers_attributes',
                            bundleTierValue: { index: tierIndex, prop: 'price_cents', value: Number(target?.value?.replace('$', '')) }
                          })
                        }
                      />
                      <NumericFormat
                        className="input--clean"
                        suffix={'%'}
                        decimalScale={2}
                        value={tier?.discount}
                        onChange={({ target }: { target: { value: string } }) =>
                          onBundlePricingChange({
                            bundleProp: 'digital_bundle_tiers_attributes',
                            bundleTierValue: { index: tierIndex, prop: 'discount', value: Number(target?.value?.replace('%', '')) }
                          })
                        }
                      />
                      <button
                        className={`button button--outline button--small ${tierIndex > 0 && tierIndex === tierArray.length - 1 ? '' : 'transparent'}`}
                        type="button"
                        name="remove"
                        onClick={() => onBundleTierChange({ action: 'remove', tierIndex })}
                      >
                        <i className="icon-trash"></i>
                      </button>
                      <button
                        className={`button button--outline button--small ${
                          tierIndex === tierArray.length - 1 && tierIndex < 4 && tier?.max_qty < DIGITAL_BUNDLE_MAX_PHOTOS && tier?.price_cents
                            ? ''
                            : 'transparent'
                        }`}
                        type="button"
                        name="add"
                        onClick={() => onBundleTierChange({ action: 'add' })}
                      >
                        <i className="icon-add"></i>
                      </button>
                    </div>
                  </fieldset>
                ))}
            </aside>
          )}
        </section>

        <hr />

        {/* Buy All Pricing */}
        <section className="mb-10">
          <fieldset className="flex items-center gap-2.5 mb-2.5">
            <h5 className="m-0">Max Price</h5>
            <Tooltip
              {...{
                title: 'Enter a single digital retail price to enable',
                distance: 5,
                position: 'top',
                trigger: 'mouseenter',
                arrow: true,
                disabled: isRetailPriceValid
              }}
            >
              <input
                id="fixedPricing"
                className="hidden"
                type="checkbox"
                checked={digitalProductBundle?.digital_bundle_pricing_type === 'digitals_buy_all_pricing'}
                onChange={({ target }) => onBundlePricingTypeChange({ bundleType: target?.checked ? 'digitals_buy_all_pricing' : undefined })}
                disabled={!isRetailPriceValid}
              />
              <label htmlFor="fixedPricing" className="label-switch label-switch--small" />
            </Tooltip>
          </fieldset>
          <p>
            <small>
              Offer a bundle of high-res digital downloads at a fixed discounted price. Set a max number of allowed digitals and a max retail price.{' '}
              <a href="https://support.photoday.io/en/articles/8260763-how-do-i-set-up-digital-bundles" target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </small>
          </p>
          {digitalProductBundle?.digital_bundle_pricing_type === 'digitals_buy_all_pricing' && (
            <aside className="mt-5 animate">
              <p className="text-body-xs italic">
                <strong>Example</strong>
                <br />
                Max Allowed Digitals: 10 &nbsp;&nbsp;&nbsp;&nbsp; Max Retail Price: $100
              </p>
              <p className="text-body-xs italic">
                Since the Max Retail Price is $100 and price per download is $20, customers purchasing 5-10 downloads will pay the bundled Max Retail Price.
                Customers purchasing 1-4 downloads will pay the retail price of $20 per download.
              </p>
              <fieldset className="mb-4">
                <label>Max Allowed Digitals</label>
                <NumericFormat
                  className="input--auto"
                  decimalScale={0}
                  value={digitalProductBundle?.digital_bundle_max_qty}
                  isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PHOTOS || floatValue === undefined}
                  allowNegative={false}
                  onValueChange={({ floatValue }) =>
                    onBundlePricingChange({
                      bundleProp: 'digital_bundle_max_qty',
                      bundleValue: floatValue
                    })
                  }
                />
              </fieldset>
              <fieldset>
                <label className="label--block">Max Retail Price</label>
                <NumericFormat
                  className="input--auto"
                  prefix={'$'}
                  decimalScale={2}
                  value={digitalProductBundle?.digital_bundle_max_price_cents}
                  isAllowed={({ floatValue }) => Number(floatValue) <= DIGITAL_BUNDLE_MAX_PRICE || floatValue === undefined}
                  onValueChange={({ floatValue }) =>
                    onBundlePricingChange({
                      bundleProp: 'digital_bundle_max_price_cents',
                      bundleValue: floatValue
                    })
                  }
                />
              </fieldset>
            </aside>
          )}
        </section>
      </div>
      {priceFieldError && (
        <aside className="panel panel--error panel--neat animate">
          <p className="m-0">{priceFieldError}</p>
        </aside>
      )}
    </div>
  );
};

export default DigitalProductSetup;

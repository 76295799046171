import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import { getMyAccountRequest } from './actions';

// Components
import Header from './Header';
import Studio from './Studio';
import Account from './Account';
import TopNav from '@/components/Shared/TopNav';
import PrivateRoute from '@/components/Shared/PrivateRoute';

// Styles
import './styles.css';

const mapStateToProps = (state) => ({
  user: state.login?.user,
  studio: state.login?.studio,
  account: state.users?.account
});
const mapDispatchToProps = { getMyAccountRequest };

const SettingsRoute = ({ user, studio, account, getMyAccountRequest }) => {
  const location = useLocation();

  useEffect(() => {
    if (Object.keys(account).length === 0) {
      getMyAccountRequest();
    }
  }, []);

  return (
    <section className="user">
      <TopNav />
      <Header path={location.pathname} user={user} studio={studio} />

      <div className="container flex user-container" data-cy="settings-container">
        <Switch>
          <PrivateRoute path="/user/account" component={Account} />
          <PrivateRoute path="/user/my-studio" component={Studio} />
        </Switch>
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsRoute);

import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Helpers
import { shortDate } from '@/utils/displayFormats';

// Images
import imageStripe from '@/assets/images/stripe-logo.svg';

// Styles
import './style.css';

// Types
import { User, Studio, StudioStripeMigrationStatus } from '@/types';

interface BannerContent {
  title: string;
  description: string;
  link?: string;
  buttonText: string;
}

const bannerContent: Record<string, BannerContent> = {
  [StudioStripeMigrationStatus.Available]: {
    title: 'Switch to Stripe Express!',
    description:
      'Let’s get set up for success! Migrate from your current Stripe Standard account to a new Stripe Express account to stay up-to-date with all of our integrated features.',
    link: 'https://support.photoday.io/en/articles/9701303-stripe-express',
    buttonText: 'Make the Switch'
  },
  [StudioStripeMigrationStatus.Approaching]: {
    title: 'Please migrate to Stripe Express',
    description:
      'The deadline is approaching! To ensure a seamless payment transfer experience, follow the steps to migrate to Stripe Express by <b>12/31/2025</b>. This change is part of PhotoDay’s continued development as a marketplace facilitator.',
    link: 'https://support.photoday.io/en/articles/9701303-stripe-express',
    buttonText: 'Make the Switch'
  },
  [StudioStripeMigrationStatus.Past]: {
    title: 'PhotoDay is unable to transfer your funds',
    description:
      'As PhotoDay continues to grow as a marketplace facilitator, we are now required to migrate all of our users from their current Stripe Standard accounts to new Stripe Express accounts.',
    link: 'https://support.photoday.io/en/articles/9701303-stripe-express',
    buttonText: 'Make the Switch'
  }
};

const ALL_STUDIOS = 'all-studios';
const MANAGE_STUDIO_SETTINGS = 'manage_studio_settings';

const MigrationBanner: FC = () => {
  const { user: loginUser } = useSelector((state: any) => state.login);
  const { studio: usersStudio, user: usersUser, requesting: usersRequesting } = useSelector((state: any) => state.users);

  const studio: Studio = usersStudio;
  const { id: studioId, stripe_migration_deadline: studioMigrationDeadline } = studio ?? {};

  const user: User = Object.keys(usersUser ?? {}).length ? usersUser : loginUser;
  const { roles: userRoles } = user;

  const shouldSeeCta = studioId !== ALL_STUDIOS && userRoles?.some((role: string) => role === MANAGE_STUDIO_SETTINGS);

  const [currentStatus, setCurrentStatus] = useState<StudioStripeMigrationStatus>();

  useEffect(() => {
    const { resolved_stripe_migration_status: studioMigrationStatus } = studio;

    if (
      studioMigrationStatus === StudioStripeMigrationStatus.Available ||
      studioMigrationStatus === StudioStripeMigrationStatus.Approaching ||
      studioMigrationStatus === StudioStripeMigrationStatus.Past
    ) {
      setCurrentStatus(studioMigrationStatus);
    }
  }, [studio]);

  if (!currentStatus || usersRequesting) return null;

  return (
    <aside
      className={`flex justify-between flex-nowrap items-center wrap-md gap-5 panel panel--spacious animate stripe-migration__banner stripe-migration__banner--${currentStatus}`}
    >
      <figure>
        <img src={imageStripe} height="64" alt="Stripe" />
      </figure>
      <hgroup className="basis-10/12 md:basis-full">
        <h1 className={`flex gap-2.5 items-center text-headline-md stripe-migration__title--${currentStatus}`}>{bannerContent[currentStatus]?.title}</h1>
        <h5 className="text-body-lg text--normal m-0">
          <span dangerouslySetInnerHTML={{ __html: bannerContent[currentStatus]?.description }} />{' '}
          {bannerContent[currentStatus]?.link && (
            <a href={bannerContent[currentStatus]?.link} className="font-semibold" target="_blank" rel="noopener noreferrer">
              Learn more
            </a>
          )}
        </h5>
      </hgroup>
      <aside className="basis-2/12 md:basis-full flex flex-col justify-center gap-2.5">
        {shouldSeeCta && (
          <Link className={`button font-semibold button--block stripe-migration__button--${currentStatus}`} to="/user/my-studio/payment">
            {bannerContent[currentStatus]?.buttonText}
          </Link>
        )}
        {studioMigrationDeadline && (
          <span className={`text-center italic ${currentStatus === StudioStripeMigrationStatus.Past ? 'text-error-500' : ''}`}>
            Due {shortDate(studioMigrationDeadline)}
          </span>
        )}
      </aside>
    </aside>
  );
};

export default MigrationBanner;

import { connect } from 'react-redux';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';

// Plugins
import flagsmith from 'flagsmith';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

// Components
import { Tooltip } from 'react-tippy';
import AddEditPackage from './AddEditPackage';
import AddProductsModal from './AddProductsModal';
import EditProductModal from './EditProductModal';
import Dropdown from '@/components/Shared/Dropdown';
import ImageAddonsDetail from './ImageAddonsDetail';
import PriceSheetItemRow from './PriceSheetItemRow';
import PriceSheetsModal from './price-sheets-modal';
import DownloadAllDetail from './DownloadAllDetail';
import DeleteModal from '@/components/Shared/DeleteModal';
import ShippingTable from '@/components/Shared/ShippingTable';
import TableLoader from '@/components/Shared/ContentLoader/TableLoader';

// Helpers
import { isEqual } from 'lodash';
import { getMyAccountStudioRequest, getStudioLabsInStudio } from '../../Settings/actions';
import { productCategoryListRequest } from '@/components/Shared/ProductCategoriesDDL/actions';
import { getPriceSheetId, selectInitialValuesFromPriceSheet, existingProductsIds } from './selectors';
import {
  getPricesheetRequest,
  deletePricesheetItemRequest,
  copyPricesheetItemRequest,
  updatePricesheetItemRequest,
  updatePricesheetRequest,
  featurePriceSheetProduct,
  unfeaturePriceSheetProduct,
  getPriceSheetCsv
} from './actions';

// Images
import storefront_price_sheets from '@/assets/images/icon-pricesheet.png';

// Styles
import './pricesheet-detail.css';
import 'react-tippy/dist/tippy.css';

const ADD_ONS_ENABLED = import.meta.env.VITE_ADD_ON_ENABLED === '1';

const mapStateToProps = (state, ownProps) => {
  const priceSheetId = getPriceSheetId(ownProps);

  const {
    login: { entities },
    pricesheets: {
      requesting: pricesheetsRequesting,
      pricesheetIdDup,
      entities: { pricesheet }
    },
    productCategoriesDDL: { requesting: productCategoryRequesting, entities: productCategoryEntities }
  } = state;
  const studio = Object.values(entities.studio)[0] || {};
  const requesting = pricesheetsRequesting || productCategoryRequesting;
  const pdFee = Number(pricesheet.pd_fee);

  return {
    studio,
    pdFee,
    pricesheet,
    requesting,
    priceSheetId,
    pricesheetIdDup,
    pricesheetsRequesting,
    productCategoryEntities,
    lab: { id: studio.lab_id, name: studio.lab_name },
    existingProductsIds: existingProductsIds(state)
  };
};

const mapDispatchToProps = {
  getPricesheetRequest,
  deletePricesheetItemRequest,
  copyPricesheetItemRequest,
  updatePricesheetItemRequest,
  updatePricesheetRequest,
  featurePriceSheetProduct,
  unfeaturePriceSheetProduct,
  productCategoryListRequest,
  getMyAccountStudioRequest,
  getPriceSheetCsv,
  getStudioLabsInStudio
};

class PriceSheetDetail extends Component {
  state = {
    ModalComponent: null,
    showViewEditModal: false,
    deletePriceSheetItemModal: {
      display: false,
      name: '8x10',
      priceSheetId: null
    },
    section: 'Photo Add-ons',
    sidebarIsOpen: false,
    selectItems: [],
    studioLabs: [],
    printCategories: [],
    priceSheetItems: {
      product: {},
      package: []
    },
    sourceDroppableId: '',
    hasDownloadAllItem: false,
    isBuyAllEnabled: false
  };

  componentDidMount() {
    const { getPricesheetRequest, priceSheetId, productCategoryListRequest, getMyAccountStudioRequest, studio, pdFee, getStudioLabsInStudio } = this.props;

    this.setupBuyAllFlag(studio);

    getPricesheetRequest(priceSheetId, (response) => {
      this.setUpProductLists(response);
      productCategoryListRequest({ labId: response.lab.id }, ({ entities: categoryEntities }) => {
        this.initialValuesSetup(categoryEntities);
      });

      if (studio && studio.id !== 'all-studios') {
        getStudioLabsInStudio(studio.id, (labs) => {
          const studioLabs = Object.values(labs).map((lab) => {
            return {
              value: lab.lab?.id,
              label: lab.lab?.name,
              selected: lab.lab?.id === response?.lab?.id,
              allowBulkShipping: lab.lab?.allow_bulk_shipping
            };
          });

          this.setState({ studioLabs });
        });
      }
    });

    if (studio.id !== 'all-studios' && !pdFee) {
      getMyAccountStudioRequest({ studioId: studio.id });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (!isEqual(this.props.pricesheet.price_sheet_items, prevProps.pricesheet.price_sheet_items)) {
      this.setUpProductLists(this.props.pricesheet);
    }
  };

  setupBuyAllFlag = (studio) => {
    const isBuyAllEnabled = flagsmith.hasFeature('buy_all_digitals');

    if (isBuyAllEnabled) {
      this.setState({ isBuyAllEnabled: true });
    } else {
      flagsmith.identify(studio.id, { name: studio.name, email: studio.email }).then(() => {
        const studioHasBuyAll = flagsmith.hasFeature('buy_all_digitals');

        this.setState({ isBuyAllEnabled: studioHasBuyAll });
      });
    }
  };

  setUpProductLists = (pricesheet) => {
    const { price_sheet_items: items } = pricesheet;

    const initialPriceSheetItems = {};

    let downLoadAllItemExists = false;

    items.forEach((item) => {
      const productType = item.price_sheet_item_type;

      if (productType === 'package') {
        if (!initialPriceSheetItems[productType]) {
          initialPriceSheetItems[productType] = [];
        }
        initialPriceSheetItems[productType].push(item);
      } else {
        const categoryName = item.price_sheet_item_purchasables[0].product.category.name;

        if (item.download_all) {
          downLoadAllItemExists = true;

          // If Download All item exists, we don't want to show as a standalone item
          return null;
        }

        if (!initialPriceSheetItems[productType]) {
          initialPriceSheetItems[productType] = {};
        }

        if (!initialPriceSheetItems[productType][categoryName]) {
          initialPriceSheetItems[productType][categoryName] = [];
        }

        initialPriceSheetItems[productType][categoryName].push(item);
      }
    });

    this.setState({ priceSheetItems: initialPriceSheetItems, hasDownloadAllItem: downLoadAllItemExists });
  };

  initialValuesSetup = (categoryEntities) => {
    const productCategories =
      Object.values(categoryEntities).length > 0 ? categoryEntities?.productCategories && Object.values(categoryEntities.productCategories) : [];

    let initialCategories = Object.values(productCategories).filter((cat) => !cat.name.includes('Digital'));

    this.setState({ printCategories: initialCategories });
  };

  handleRemoveAll = () => {
    const newSelectItems = [];
    const { pricesheet } = this.props;

    if (pricesheet && pricesheet.price_sheet_items) {
      pricesheet.price_sheet_items.forEach((item) => {
        if (this.state.section === 'Print Products' && item.price_sheet_item_type === 'product' && !item.has_digital_downloads) {
          newSelectItems.push(item.id);
        }

        if (this.state.section === 'Digital Products' && item.price_sheet_item_type === 'product' && item.has_digital_downloads && !item.download_all) {
          newSelectItems.push(item.id);
        }

        if (this.state.section === 'Packages' && item.price_sheet_item_type === 'package') {
          newSelectItems.push(item.id);
        }
      });
    }

    this.setState({ selectItems: newSelectItems }, () => {
      this.toggleDeleteModal(true);
    });
  };

  handleSelectItem = (e, id, category) => {
    const { selectItems, section, priceSheetItems } = this.state;

    if (priceSheetItems && Object.values(priceSheetItems).length) {
      if (id === 'all') {
        let products = Array.from(selectItems);

        if (section === 'Print Products' && category) {
          const productList = priceSheetItems['product'][category].map((item) => item.id);

          e.target.checked ? (products = [...products, ...productList]) : (products = [...products.filter((item) => !productList.includes(item))]);
        } else if (section === 'Digital Products') {
          const productList = priceSheetItems['product']['Digital Downloads'].map((item) => item.id);

          e.target.checked ? (products = [...products, ...productList]) : (products = [...products.filter((item) => !productList.includes(item))]);
        } else if (section === 'Packages') {
          const packageList = priceSheetItems['package'].map((item) => item.id);

          e.target.checked
            ? (products = [...products, ...priceSheetItems['package'].map((item) => item.id)])
            : (products = [...products.filter((item) => !packageList.includes(item))]);
        }

        this.setState({ selectItems: products });
      } else {
        this.setState({ selectItems: e.target.checked ? [...selectItems, id] : [...selectItems.filter((select) => select !== id)] });
      }
    }
  };

  toggleAddPackageModal = (show) => {
    this.setState({
      ModalComponent: show && AddEditPackage
    });
  };

  toggleViewEditModal = (show) => {
    this.setState({
      showViewEditModal: show
    });
  };

  toggleAddProductModal = (show) => {
    this.setState({
      ModalComponent: show && AddProductsModal
    });
  };

  toggleEditProductModal = (show, priceSheetItem) => {
    this.setState({
      ModalComponent: show && EditProductModal,
      priceSheetItem: show && priceSheetItem
    });
  };

  toggleEditPackageModal = (show, priceSheetItem) => {
    this.setState({
      ModalComponent: show && AddEditPackage,
      priceSheetItem: show && priceSheetItem
    });
  };

  toggleDeleteModal = (show, id) => {
    if (id) {
      this.setState({ selectItems: [id] }, () => this.setState({ ModalComponent: null, priceSheetItem: {}, deletePriceSheetItemModal: { display: show } }));
    } else {
      this.setState({ ModalComponent: null, priceSheetItem: {}, deletePriceSheetItemModal: { display: show } });
    }
  };

  closeModal = () => {
    this.setState({
      ModalComponent: null,
      priceSheetItem: {}
    });
  };

  collapseMenu = (event) => {
    const newSection = event.target.innerHTML;
    this.setState({ sidebarIsOpen: false, section: newSection, selectItems: [] });
  };

  handleToggleSidebar = () => {
    this.setState({ sidebarIsOpen: !this.state.sidebarIsOpen });
  };

  updatePriceSheet = (priceSheet) => {
    const { id } = this.props.pricesheet;
    const { updatePricesheetRequest } = this.props;

    const { shipping_type, markup_attributes } = priceSheet;
    const { markup_type } = markup_attributes;

    const shippingTypeValue = typeof shipping_type === 'string' ? shipping_type : shipping_type?.value;

    const updatedPriceSheet = Object.assign(priceSheet, {
      id: id,
      shipping_type: shippingTypeValue,
      markup_attributes: { ...markup_attributes, markup_type: markup_type?.value }
    });

    if (shippingTypeValue !== 'drop_shipping') {
      updatedPriceSheet.delete_non_bulk_items = true;
    }

    updatePricesheetRequest(updatedPriceSheet, () => {
      this.toggleViewEditModal(false);
    });
  };

  deletePriceSheetItem = (id) => {
    const { pricesheet, deletePricesheetItemRequest, getPricesheetRequest, productCategoryListRequest, requesting } = this.props;

    !requesting &&
      deletePricesheetItemRequest({ id: pricesheet.id, price_sheet_item_ids: id }, () => {
        this.setState({ selectItems: [] });
        this.toggleDeleteModal(false);

        getPricesheetRequest(pricesheet.id, (response) => {
          productCategoryListRequest({ labId: response.lab.id });
        });
      });
  };

  copyPriceSheetItem = (id) => {
    const { copyPricesheetItemRequest, requesting } = this.props;

    !requesting && copyPricesheetItemRequest(id);
  };

  updatePricesheetItem = (values) => {
    const { updatePricesheetItemRequest, requesting } = this.props;

    !requesting && updatePricesheetItemRequest(values);
  };

  handleExportPricesheet = () => {
    const { getPriceSheetCsv, priceSheetId, pricesheet } = this.props;
    const fileName = `${pricesheet.name}.csv`;

    getPriceSheetCsv(priceSheetId, fileName);
  };

  handleProductFavorite = (id) => {
    const { getPricesheetRequest, priceSheetId } = this.props;

    this.props.featurePriceSheetProduct({ id }, () => getPricesheetRequest(priceSheetId));
  };

  handleProductUnfavorite = (id) => {
    const { getPricesheetRequest, priceSheetId } = this.props;

    this.props.unfeaturePriceSheetProduct({ id }, () => getPricesheetRequest(priceSheetId));
  };

  onDragStart = (start) => {
    this.setState({ sourceDroppableId: start.source.droppableId });
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newList = type === 'package' ? Array.from(this.state.priceSheetItems[type]) : Array.from(this.state.priceSheetItems[type][source.droppableId]);

    const [removed] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, removed);

    const newPriceSheetItems =
      type === 'package'
        ? { ...this.state.priceSheetItems, package: newList }
        : { ...this.state.priceSheetItems, product: { ...this.state.priceSheetItems.product, [source.droppableId]: newList } };

    const destinationPriority =
      destination.index > 0
        ? destination.index < newList.length - 1
          ? destination.index > source.index
            ? newList[destination.index + 1].display_priority - 1
            : newList[destination.index - 1].display_priority + 1
          : newList.length
        : 1;

    const limitDestinationPriority = Math.min(Math.max(1, destinationPriority), newList.length);

    this.setState({ sourceDroppableId: '', priceSheetItems: newPriceSheetItems }, () => {
      this.props.updatePricesheetItemRequest({ id: draggableId, display_priority: limitDestinationPriority }, () => {
        this.props.getPricesheetRequest(this.props.priceSheetId);
      });
    });
  };

  render() {
    const {
      deletePriceSheetItemModal,
      ModalComponent,
      priceSheetItem,
      showViewEditModal,
      section,
      selectItems,
      printCategories,
      priceSheetItems,
      sourceDroppableId,
      studioLabs,
      hasDownloadAllItem,
      isBuyAllEnabled
    } = this.state;

    const { pricesheet, priceSheetId, requesting, pricesheetIdDup, existingProductsIds, productCategoryEntities, pdFee, pricesheetsRequesting } = this.props;
    const isLoading = (requesting && printCategories.length === 0) || (requesting && Object.values(priceSheetItems).length === 0);
    const showDownloadAllDigital = hasDownloadAllItem || isBuyAllEnabled;

    return (
      <>
        {/* Sidebar Navigation */}
        <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
          <section className="flex pricesheet">
            <aside className="basis-3/12 md:basis-full sidebar-container">
              <header className="flex items-center justify-between sidebar-header">
                <h2 className="text-headline-sm">Price Sheets</h2>
                <button className="sidebar__mobile-action button button--outline" onClick={this.handleToggleSidebar}>
                  {this.state.section}
                </button>
              </header>

              <nav className={`sidebar-navigation ${this.state.sidebarIsOpen ? 'sidebar-navigation--open' : ''}`} onClick={this.handleToggleSidebar}>
                {ADD_ONS_ENABLED && (
                  <NavLink
                    className={`sidebar-navigation__item ${this.state.section === 'Photo Add-ons' ? 'sidebar-navigation__item--active' : ''}`}
                    to={'#add-ons'}
                    onClick={this.collapseMenu}
                  >
                    Photo Add-ons
                  </NavLink>
                )}
                <NavLink
                  className={`sidebar-navigation__item ${this.state.section === 'Packages' ? 'sidebar-navigation__item--active' : ''}`}
                  to={'#packages'}
                  onClick={this.collapseMenu}
                >
                  Packages
                </NavLink>
                <NavLink
                  className={`sidebar-navigation__item ${this.state.section === 'Print Products' ? 'sidebar-navigation__item--active' : ''}`}
                  to={`#products`}
                  onClick={this.collapseMenu}
                >
                  Print Products
                </NavLink>
                <NavLink
                  className={`sidebar-navigation__item ${this.state.section === 'Digital Products' ? 'sidebar-navigation__item--active' : ''}`}
                  to={'#digital'}
                  onClick={this.collapseMenu}
                >
                  Digital Products
                </NavLink>
                <NavLink
                  className={`sidebar-navigation__item ${this.state.section === 'Shipping Rates' ? 'sidebar-navigation__item--active' : ''}`}
                  to={'#shipping'}
                  onClick={this.collapseMenu}
                >
                  Shipping Rates
                </NavLink>
              </nav>
            </aside>

            <div className="basis-9/12 md:basis-full max-w-full">
              <div className="flex items-center justify-between pricesheet__header">
                <h2 className="text-headline-sm">{pricesheet.name}</h2>

                <div className="flex flex-nowrap pricesheet__actions">
                  <Tooltip
                    title={section === 'Packages' ? 'Add Package' : section === 'Photo Add-ons' ? 'Add-ons' : 'Add Product'}
                    position="top"
                    arrow={false}
                    delay={300}
                    touchHold={true}
                  >
                    <button
                      className="button button--outline button--small pricesheet__action"
                      name="view"
                      type="button"
                      onClick={() => (section === 'Packages' ? this.toggleAddPackageModal(true) : this.toggleAddProductModal(true))}
                      disabled={!Object.values(productCategoryEntities).length}
                    >
                      <i className="icon-add"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Edit Price Sheet" position="top" arrow={false} delay={300} touchHold={true}>
                    <button
                      className="button button--outline button--small pricesheet__action"
                      name="view"
                      type="button"
                      onClick={() => this.toggleViewEditModal(true)}
                    >
                      <i className="icon-gear"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="Remove" position="top" arrow={false} delay={300}>
                    <Dropdown buttonIcon="icon-trash" buttonExtraClass={'button--lean button--small button-dropdown pricesheet__action'}>
                      <ul className="panel panel-dropdown panel-dropdown--large">
                        <li
                          className={`panel-dropdown__item ${this.state.selectItems.length === 0 ? 'panel-dropdown__item--disabled' : ''}`}
                          onClick={() => this.toggleDeleteModal(true)}
                        >
                          Remove Selected
                        </li>
                        <li className="panel-dropdown__item" onClick={this.handleRemoveAll}>
                          Remove All
                        </li>
                      </ul>
                    </Dropdown>
                  </Tooltip>
                  <Tooltip title="Download Price Sheet" position="top" arrow={false} delay={300}>
                    <button
                      className="button button--outline button--small pricesheet__action"
                      name="view"
                      type="button"
                      onClick={this.handleExportPricesheet}
                      disabled={this.props.pricesheetsRequesting}
                      data-loading-dark={this.props.pricesheetsRequesting}
                    >
                      <i className="icon-download-sharp"></i>
                    </button>
                  </Tooltip>
                </div>
              </div>

              {isLoading ? (
                <div className="panel">
                  <TableLoader rows={5} rowHeight={40} />{' '}
                </div>
              ) : (
                <>
                  {section === 'Print Products' && priceSheetItems?.product && printCategories.some((cat) => priceSheetItems['product']?.[cat.name]) && (
                    <>
                      {printCategories.map((category, i) => {
                        const allSelected =
                          priceSheetItems['product'] &&
                          priceSheetItems['product'][category.name] &&
                          priceSheetItems['product'][category.name].length > 0 &&
                          priceSheetItems['product'][category.name].every((item) => selectItems.includes(item.id));
                        const emptyCategory =
                          (priceSheetItems['product'] && !priceSheetItems['product'][category.name]) || priceSheetItems['product'][category.name].length === 0;

                        if (emptyCategory) return null;

                        return (
                          <section className={`pricesheet-section ${this.state.section === 'Print Products' ? 'pricesheet-section--active' : ''}`} key={i}>
                            <article className="pricesheet-section__table">
                              <h4 className="font-semibold">{category.name}</h4>
                              <Droppable droppableId={category.name} type="product" isDropDisabled={sourceDroppableId !== category.name}>
                                {(provided, snapshot) => (
                                  <table className={`table ${snapshot.isDraggingOver ? 'table--drag-over' : ''}`} ref={provided.innerRef}>
                                    <thead className="table__header">
                                      <tr>
                                        <th>
                                          <input
                                            id={category.name}
                                            className="hidden"
                                            type="checkbox"
                                            name={category.name}
                                            checked={allSelected}
                                            onChange={(e) => this.handleSelectItem(e, 'all', category.name)}
                                          />
                                          <label htmlFor={category.name} className="label--checkbox label--clean" />
                                        </th>
                                        <th colSpan="2">Name</th>
                                        <th>Qty</th>
                                        <th className="whitespace-nowrap" width="85">
                                          <Tooltip title="Cost set by lab" position="top" trigger="mouseenter" arrow={true}>
                                            Unit Cost
                                            <a
                                              href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fa fa-question-circle-o" />
                                            </a>
                                          </Tooltip>
                                        </th>
                                        <th className="whitespace-nowrap">
                                          <Tooltip title="Retail Price minus Unit Cost" position="top" trigger="mouseenter" arrow={true}>
                                            Markup
                                            <a
                                              href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fa fa-question-circle-o" />
                                            </a>
                                          </Tooltip>
                                        </th>
                                        <th className="whitespace-nowrap">
                                          <Tooltip title="Amount displayed on price sheet" position="top" trigger="mouseenter" arrow={true}>
                                            Retail Price
                                            <a
                                              href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fa fa-question-circle-o" />
                                            </a>
                                          </Tooltip>
                                        </th>
                                        <th className="whitespace-nowrap">
                                          <Tooltip title={`${pdFee}% of Retail Price`} position="top" trigger="mouseenter" arrow={true}>
                                            PhotoDay Fee
                                            <a
                                              href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fa fa-question-circle-o" />
                                            </a>
                                          </Tooltip>
                                        </th>
                                        <th>Description</th>
                                        <th>Feature</th>
                                      </tr>
                                    </thead>

                                    <tbody className="table__body">
                                      <>
                                        {priceSheetItems['product'][category.name].map((item, index) => (
                                          <PriceSheetItemRow
                                            pdFee={pdFee}
                                            index={index}
                                            showQuantity={true}
                                            disabled={requesting}
                                            rowType="printProduct"
                                            key={item.id}
                                            selectItems={this.state.selectItems}
                                            priceSheetItem={item}
                                            digitalProductsCount={0}
                                            shippingType={pricesheet.shipping_type}
                                            onUpdate={this.updatePricesheetItem}
                                            onSelectItem={this.handleSelectItem}
                                            onProductFavorite={this.handleProductFavorite}
                                            onProductUnfavorite={this.handleProductUnfavorite}
                                            onEdit={() => this.toggleEditProductModal(true, item)}
                                          />
                                        ))}
                                        {provided.placeholder}
                                      </>
                                    </tbody>
                                  </table>
                                )}
                              </Droppable>
                            </article>
                          </section>
                        );
                      })}
                    </>
                  )}

                  {section === 'Digital Products' && (
                    <>
                      {showDownloadAllDigital && <DownloadAllDetail pricesheet={pricesheet} pricesheetsRequesting={pricesheetsRequesting} />}

                      {priceSheetItems?.product && priceSheetItems['product']?.['Digital Downloads'] && (
                        <>
                          <section className={`pricesheet-section ${this.state.section === 'Digital Products' ? 'pricesheet-section--active' : ''}`}>
                            <article className="pricesheet-section__table">
                              <Droppable droppableId="Digital Downloads" type="product" isDropDisabled={sourceDroppableId !== 'Digital Downloads'}>
                                {(provided, snapshot) => (
                                  <table className={`table ${snapshot.isDraggingOver ? 'table--drag-over' : ''}`} ref={provided.innerRef}>
                                    <thead className="table__header">
                                      <tr>
                                        <th>
                                          <input
                                            id="all"
                                            className="hidden"
                                            type="checkbox"
                                            name="all"
                                            checked={
                                              this.state.selectItems &&
                                              this.state.selectItems.length > 0 &&
                                              this.state.selectItems.length ===
                                                (pricesheet.price_sheet_items &&
                                                  pricesheet.price_sheet_items.filter(
                                                    (item) => item.has_digital_downloads && item.price_sheet_item_type !== 'package' && !item.download_all
                                                  ).length)
                                            }
                                            onChange={(e) => this.handleSelectItem(e, 'all')}
                                          />
                                          <label htmlFor="all" className="label--checkbox label--clean" />
                                        </th>
                                        <th colSpan="2">Name</th>
                                        <th className="whitespace-nowrap" width="85">
                                          <Tooltip title="Cost of digital download" position="top" trigger="mouseenter" arrow={true}>
                                            Unit Cost
                                            <a
                                              href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fa fa-question-circle-o" />
                                            </a>
                                          </Tooltip>
                                        </th>
                                        <th className="whitespace-nowrap">
                                          <Tooltip title="Retail Price minus Unit Cost" position="top" trigger="mouseenter" arrow={true}>
                                            Markup
                                            <a
                                              href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fa fa-question-circle-o" />
                                            </a>
                                          </Tooltip>{' '}
                                        </th>
                                        <th className="whitespace-nowrap">
                                          <Tooltip title="Amount displayed on price sheet" position="top" trigger="mouseenter" arrow={true}>
                                            Retail Price
                                            <a
                                              href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fa fa-question-circle-o" />
                                            </a>
                                          </Tooltip>
                                        </th>
                                        <th className="whitespace-nowrap">
                                          <Tooltip title={`${pdFee}% of Retail Price`} position="top" trigger="mouseenter" arrow={true}>
                                            PhotoDay Fee
                                            <a
                                              href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i className="fa fa-question-circle-o" />
                                            </a>
                                          </Tooltip>
                                        </th>
                                        <th>Bundle</th>
                                        <th>Description</th>
                                        <th>Feature</th>
                                      </tr>
                                    </thead>
                                    <tbody className="table__body">
                                      {priceSheetItems['product']['Digital Downloads'].map((item, index) => (
                                        <PriceSheetItemRow
                                          pdFee={pdFee}
                                          key={item.id}
                                          index={index}
                                          disabled={requesting}
                                          rowType="digitalProduct"
                                          priceSheetItem={item}
                                          digitalProductsCount={1}
                                          shippingType={pricesheet.shipping_type}
                                          selectItems={this.state.selectItems}
                                          onUpdate={this.updatePricesheetItem}
                                          onSelectItem={this.handleSelectItem}
                                          onProductFavorite={this.handleProductFavorite}
                                          onProductUnfavorite={this.handleProductUnfavorite}
                                          onEdit={() => this.toggleEditProductModal(true, item)}
                                        />
                                      ))}
                                      {provided.placeholder}
                                    </tbody>
                                  </table>
                                )}
                              </Droppable>
                            </article>
                          </section>
                        </>
                      )}
                    </>
                  )}

                  {section === 'Packages' && priceSheetItems?.package && Object.values(priceSheetItems['package']).length > 0 && (
                    <section className={`pricesheet-section ${this.state.section === 'Packages' ? 'pricesheet-section--active' : ''}`}>
                      <article className="pricesheet-section__table">
                        <Droppable droppableId="Packages" type="package" isDropDisabled={sourceDroppableId !== 'Packages'}>
                          {(provided, snapshot) => (
                            <table className={`table ${snapshot.isDraggingOver ? 'table--drag-over' : ''}`} ref={provided.innerRef}>
                              <thead className="table__header">
                                <tr>
                                  <th>
                                    <input
                                      id="all"
                                      className="hidden"
                                      type="checkbox"
                                      name="all"
                                      checked={
                                        this.state.selectItems &&
                                        this.state.selectItems.length > 1 &&
                                        this.state.selectItems.length ===
                                          (pricesheet.price_sheet_items &&
                                            pricesheet.price_sheet_items.filter((item) => item.price_sheet_item_type === 'package').length)
                                      }
                                      onChange={(e) => this.handleSelectItem(e, 'all')}
                                    />
                                    <label htmlFor="all" className="label--checkbox label--clean" />
                                  </th>
                                  <th colSpan="2">Name</th>
                                  <th className="whitespace-nowrap" width="85">
                                    <Tooltip title="Costs set by lab plus cost of digital downloads" position="top" trigger="mouseenter" arrow={true}>
                                      Unit Cost
                                      <a
                                        href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="fa fa-question-circle-o" />
                                      </a>
                                    </Tooltip>
                                  </th>
                                  <th className="whitespace-nowrap">
                                    <Tooltip title="Retail Price minus Unit Cost" position="top" trigger="mouseenter" arrow={true}>
                                      Markup
                                      <a
                                        href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="fa fa-question-circle-o" />
                                      </a>
                                    </Tooltip>
                                  </th>
                                  <th className="whitespace-nowrap">
                                    <Tooltip title="Amount displayed on price sheet" position="top" trigger="mouseenter" arrow={true}>
                                      Retail Price
                                      <a
                                        href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="fa fa-question-circle-o" />
                                      </a>
                                    </Tooltip>
                                  </th>
                                  <th className="whitespace-nowrap">
                                    <Tooltip title={`${pdFee}% of Retail Price`} position="top" trigger="mouseenter" arrow={true}>
                                      PhotoDay Fee
                                      <a
                                        href="https://support.photoday.io/en/articles/2225025-how-much-does-photoday-cost"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <i className="fa fa-question-circle-o" />
                                      </a>
                                    </Tooltip>
                                  </th>
                                  <th>Description</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody className="table__body">
                                {priceSheetItems['package'].map((item, index) => (
                                  <PriceSheetItemRow
                                    pdFee={pdFee}
                                    index={index}
                                    key={item.id}
                                    rowType="package"
                                    disabled={requesting}
                                    priceSheetItem={item}
                                    digitalProductsCount={
                                      item.has_digital_downloads
                                        ? item.price_sheet_item_purchasables.filter(({ product }) => product.is_digital_download).length
                                        : 0
                                    }
                                    shippingType={pricesheet.shipping_type}
                                    selectItems={this.state.selectItems}
                                    onUpdate={this.updatePricesheetItem}
                                    onSelectItem={this.handleSelectItem}
                                    duplicating={item.id === pricesheetIdDup}
                                    onCopy={() => this.copyPriceSheetItem(item.id)}
                                    onEdit={() => this.toggleEditPackageModal(true, item)}
                                  />
                                ))}
                                {provided.placeholder}
                              </tbody>
                            </table>
                          )}
                        </Droppable>
                      </article>
                    </section>
                  )}

                  {ADD_ONS_ENABLED && section === 'Photo Add-ons' && (
                    <ImageAddonsDetail pricesheet={pricesheet} pricesheetsRequesting={this.props.pricesheetsRequesting} />
                  )}

                  {section === 'Shipping Rates' && (
                    <section className={`${this.state.section === 'Shipping Rates' ? 'pricesheet-section--active' : ''}`}>
                      {pricesheet && pricesheet.lab && (
                        <ShippingTable labId={pricesheet.lab.id} labName={pricesheet.lab.name} shippingType={pricesheet.shipping_type} />
                      )}
                    </section>
                  )}

                  {/* Empty State */}
                  {((section === 'Packages' &&
                    (!priceSheetItems?.package || (priceSheetItems?.package && Object.values(priceSheetItems['package']).length === 0))) ||
                    (section === 'Print Products' &&
                      (!priceSheetItems?.product || (priceSheetItems?.product && !printCategories.some((cat) => priceSheetItems['product']?.[cat.name])))) ||
                    (section === 'Digital Products' &&
                      (!priceSheetItems?.product || (priceSheetItems?.product && !priceSheetItems['product']?.['Digital Downloads'])))) && (
                    <figure className="flex flex-col items-center justify-center font-semibold panel pricesheet-section__empty-state">
                      <img src={storefront_price_sheets} alt="price sheet" />
                      <figcaption>
                        Add{' '}
                        <button
                          className="button--link"
                          type="button"
                          onClick={() => (section === 'Packages' ? this.toggleAddPackageModal(true) : this.toggleAddProductModal(true))}
                        >
                          {section.toLowerCase()}
                        </button>
                        .
                      </figcaption>
                    </figure>
                  )}
                </>
              )}
            </div>

            {ModalComponent && (
              <ModalComponent
                pdFee={pdFee}
                isVisible={true}
                requesting={requesting}
                initialValues={priceSheetItem || {}}
                priceSheetId={priceSheetId}
                shippingType={pricesheet.shipping_type}
                markup={pricesheet.markup}
                labId={pricesheet.lab && pricesheet.lab.id}
                toggleDelete={this.toggleDeleteModal}
                currentSection={section}
                existingProductsIds={existingProductsIds}
                hasDownloadAllItem={hasDownloadAllItem}
                onClose={this.closeModal}
              />
            )}

            {deletePriceSheetItemModal.display && (
              <DeleteModal
                isVisible={deletePriceSheetItemModal.display}
                title="Delete Item"
                entity="item"
                currentId={selectItems}
                toggleModal={this.toggleDeleteModal}
                onConfirm={this.deletePriceSheetItem}
                requesting={requesting}
              >
                <p>
                  Are you sure you want to delete these item(s)?
                  <br /> This action cannot be undone.
                </p>
              </DeleteModal>
            )}

            {showViewEditModal && (
              <PriceSheetsModal
                labId={pricesheet.lab && pricesheet.lab.id}
                labSelected={studioLabs.find((lab) => lab.value === pricesheet?.lab?.id)}
                labs={studioLabs}
                disableLabs={true}
                isVisible={showViewEditModal}
                toggleModal={this.toggleViewEditModal}
                priceSheet={selectInitialValuesFromPriceSheet(pricesheet)}
                requesting={requesting}
                onSubmit={this.updatePriceSheet}
              />
            )}
          </section>
        </DragDropContext>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceSheetDetail);

import React, { useState } from 'react';
import { Form, reduxForm } from 'redux-form';

import { Input } from '@/components/Shared/Forms';

const SecurityForm = ({ loading, requesting, handleSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType = showPassword ? 'text' : 'password';

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <section className="basis-full" data-cy="security-form">
      <Form onSubmit={handleSubmit}>
        <header className="flex items-center justify-between user-container__header">
          <h2 className="text-headline-sm">Update Password</h2>
          <button type="submit" className="button button--medium" disabled={requesting || loading}>
            Save
          </button>
        </header>

        <main className="panel panel--section account-settings-item">
          <div className="flex gap-2.5">
            <div className="basis-2/6">
              <Input label="Current Password" name="old_password" type={inputType} loading={loading} autoComplete="new-password" />
            </div>
            <div className="basis-2/6">
              <Input label="New Password" name="password" type={inputType} autoComplete="new-password" loading={loading} />
            </div>
          </div>
          <div>
            <fieldset>
              <input type="checkbox" className="hidden" name="password" id="password" checked={showPassword} onChange={handleShowPassword} />
              <label htmlFor="password" className="label--checkbox">
                Show Password
              </label>
            </fieldset>
          </div>
        </main>
      </Form>
    </section>
  );
};

export default reduxForm({ form: 'SecurityForm', enableReinitialize: true, destroyOnUnmount: false })(SecurityForm);

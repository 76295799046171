import { FC, useState, useEffect } from 'react';

// Plugins
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

// Helpers
import { errorToast } from '@/utils/toast';
import { convertToText } from '@/utils/currency';
import { getWalletCreditValue } from '@/utils/getWalletCreditValue';

// Types
interface CardFormProps {
  knockoutCostCents: number;
  colorCorrectionCostCents: number;
  missingCredits?: number;
  studioCreditsPolicyAgreed: boolean;
  onBuyWalletCredits: (amount: number, source: string) => void;
}

const CREDIT_VALUE_CENTS = 10;
const MINIMUM_CREDIT_AMOUNT = 5;

const CardForm: FC<CardFormProps> = ({ knockoutCostCents, colorCorrectionCostCents, missingCredits, onBuyWalletCredits, studioCreditsPolicyAgreed }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [creditAmount, setCreditAmount] = useState<number>(MINIMUM_CREDIT_AMOUNT);
  const [dollarAmountCents, setDollarAmountCents] = useState<number>(0);

  const [buyCreditsLoading, setBuyWalletCreditsLoading] = useState<boolean>(false);
  const [buyCreditsDisabled, setBuyWalletCreditsDisabled] = useState<boolean>(true);
  const [creditPolicyAgreed, setCreditPolicyAgreed] = useState<boolean>(false);

  const handleCreditsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const credits = Number(event.currentTarget.value ?? 0);

    setCreditAmount(credits);
    setDollarAmountCents(credits * CREDIT_VALUE_CENTS);
  };

  const handleSubmitBuyWalletCredits = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setBuyWalletCreditsLoading(true);
    setBuyWalletCreditsDisabled(true);

    if (stripe && elements) {
      const card = elements.getElement(CardElement);

      if (card) {
        try {
          const result = await stripe.createToken(card);

          if (result.token) {
            onBuyWalletCredits(dollarAmountCents, result?.token?.id);
          }
        } catch (error) {
          console.error(error);
          errorToast(error);

          setBuyWalletCreditsLoading(false);
          setBuyWalletCreditsDisabled(false);
        }
      }
    }
  };

  useEffect(() => {
    setCreditPolicyAgreed(studioCreditsPolicyAgreed);
  }, [studioCreditsPolicyAgreed]);

  useEffect(() => {
    setCreditAmount(missingCredits ?? MINIMUM_CREDIT_AMOUNT);
    setDollarAmountCents((missingCredits || MINIMUM_CREDIT_AMOUNT) * CREDIT_VALUE_CENTS);
  }, []);

  return (
    <form onSubmit={handleSubmitBuyWalletCredits}>
      <fieldset>
        <input
          className="settings-wallet__credits input--clean"
          type="text"
          value={creditAmount}
          autoFocus={true}
          pattern="[0-9]*"
          onChange={handleCreditsChange}
        />
        <small className={`italic ${creditAmount < MINIMUM_CREDIT_AMOUNT ? 'text-error-500' : ''}`}>Minimum of 5 credits required</small>
      </fieldset>
      <aside className="flex justify-between flex-wrap panel panel--lean mb-4">
        {/* Knockout credits */}
        <span>Knockouts ({getWalletCreditValue(knockoutCostCents)} credit per photo) =</span>
        <span>{Math.floor(creditAmount / getWalletCreditValue(knockoutCostCents))} photos</span>
        <span className="block w-full font-semibold mt-2.5 mb-2.5">or</span>
        {/* Color correction credits */}
        <span>Color corrections ({getWalletCreditValue(colorCorrectionCostCents)} credit per photo) =</span>
        <span>{Math.floor(creditAmount / getWalletCreditValue(colorCorrectionCostCents))} photos</span>
      </aside>
      <aside className="flex justify-between panel panel--lean">
        <span>
          {creditAmount} Credits x {convertToText(CREDIT_VALUE_CENTS, '$')} =
        </span>
        <span className="font-semibold">{convertToText(dollarAmountCents, '$')}</span>
      </aside>
      <hr />
      <h6 className="text-left">Payment Information</h6>
      <CardElement className="settings-wallet__card" onChange={({ complete }) => setBuyWalletCreditsDisabled(!complete)} />
      <fieldset className="flex">
        <input
          type="checkbox"
          className="hidden"
          name="policyAgreed"
          id="policyAgreed"
          checked={creditPolicyAgreed}
          onChange={() => setCreditPolicyAgreed(!creditPolicyAgreed)}
        />
        <label htmlFor="policyAgreed" className="label--checkbox label--lighter">
          I've read and agree to the{' '}
          <a className="settings-wallet__policy-link" href="https://photoday.io/credits" target="_blank" rel="noreferrer noopener">
            PhotoDay Credits Policy
          </a>
        </label>
      </fieldset>
      <button
        className="button button--center button--medium"
        name="buy"
        type="submit"
        disabled={buyCreditsDisabled || !creditPolicyAgreed}
        data-loading={buyCreditsLoading}
      >
        Buy Credits
      </button>
    </form>
  );
};

export default CardForm;

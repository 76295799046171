import { FC, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  createJobKnockoutPrepare,
  createJobKnockoutTransfer,
  getJobKnockout,
  getJobKnockoutItems,
  updateJobKnockout,
  updateJobKnockoutFromCable,
  updateKnockoutUploadFromCable,
  deleteJobKnockoutItems
} from '../../../actions';

// Plugins
import { debounce } from 'lodash';
import { Tooltip } from 'react-tippy';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// @ts-ignore
import { useActionCable } from 'use-action-cable';

// Helpers
import imageScaling from '@/utils/imageScaling';

// Components
import Lightbox from '@/components/Shared/Lightbox';
import Dropdown from '@/components/Shared/Dropdown';
import GridLoader from '@/components/Shared/ContentLoader/GridLoader';

// Styles
import './style.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

interface ReviewProps {
  match: {
    params: {
      jobId: string;
      id: string;
    };
  };
  history: { push: (location: string) => void; goBack: () => void };
}

interface PostProcessingItem {
  id: string;
  status: string;
  skylab_status: string;
  skylab_message: string;
  filename: string;
  knocked_out_filename: string | null;
  original_image_url: string;
  knocked_out_image_url: string;
  upload_url: string;
  uploaded_at: string;
  completed_at: string;
  tag_list: string;
}

const Review: FC<ReviewProps> = ({ match, history }) => {
  const dispatch = useDispatch();

  const {
    params: { jobId, id: postProcessingId }
  } = match;

  const {
    knockout,
    knockouts: {
      knockoutItems: { list: knockoutItems, sort: knockoutItemsSort },
      requesting: knockoutsRequesting
    }
  } = useSelector((state: any) => state.jobs);

  const previousItemHoveredRef = useRef<PostProcessingItem | null>(null);

  const [itemsSortOrder, setItemsSortOrder] = useState<{ order: string; dir: string } | null>(knockoutItemsSort);

  const [itemsSelected, setItemsSelected] = useState<string[]>([]);
  const [unselectedItems, setUnselectedItems] = useState<string[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState<boolean>(false);

  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);
  const [shiftHoverSelected, setShiftHoverSelected] = useState<string[]>([]);
  const [previousItemSelected, setPreviousItemSelected] = useState<string>('');

  const [showLightbox, setShowLightbox] = useState(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [showMoveToGallery, setShowMoveToGallery] = useState<boolean>(false);
  const [moveToGalleryType, setMoveToGalleryType] = useState<string>('move-post-processing');
  const [moveToGalleryOverwrite, setMoveToGalleryOverwrite] = useState<boolean>(true);

  const failedCount = knockoutItems.filter((item: PostProcessingItem) => item.status === 'failed').length;
  const uploadedCount = knockout.knockout_items_count - failedCount;

  const formatFailedMessage = (message: string) => {
    if (message.includes('profile')) {
      return `Unsupported Profile: ${message.split(':')[1]}`;
    }
    return message;
  };

  const handleShowLightboxToggle = () => setShowLightbox(!showLightbox);

  const handleShowMoveToGalleryToggle = () => setShowMoveToGallery(!showMoveToGallery);

  const handlePostProcessingItemsSort = (sort: { order: string; dir: string }) => {
    setItemsSortOrder(sort);
    dispatch(getJobKnockoutItems({ knockoutId: postProcessingId, sort }));
  };

  const handleSelectItem = (item: PostProcessingItem) => {
    setItemsSelected([item.id]);
    setAllItemsSelected(false);
    setUnselectedItems([]);
  };

  const handlePostProcessingReviewed = () => {
    dispatch(
      updateJobKnockout({ knockoutId: postProcessingId, reviewed: true }, () =>
        history.push(`/jobs/${jobId}/services/postprocessing/${postProcessingId}/settings`)
      )
    );
  };

  const handleDeleteShow = () => {
    setShowDelete(true);
  };

  const handleDeleteCancel = () => {
    setShowDelete(false);
  };

  const handleDeleteConfirm = () => {
    dispatch(
      deleteJobKnockoutItems({ knockoutId: postProcessingId, ids: itemsSelected }, () => {
        dispatch(
          getJobKnockout({ knockoutId: postProcessingId }, () => {
            dispatch(
              getJobKnockoutItems({ knockoutId: postProcessingId, sort: knockoutItemsSort }, () => {
                handleDeleteCancel();
                handleItemsSelectClear();
              })
            );
          })
        );
      })
    );

    // Reset the main reviewed state if removing all items
    if (itemsSelected.length === knockoutItems.length) {
      dispatch(updateJobKnockout({ knockoutId: postProcessingId, reviewed: false }));
    }
  };

  const handleSelectItems = (itemId: string, bypass: boolean = false) => {
    const currentItemsSelected = itemsSelected;

    let newItemsSelected: string[] = [];

    if (shiftHoverSelected.length) {
      const previousItemIsSelected = currentItemsSelected.includes(previousItemSelected);

      if (previousItemIsSelected) {
        newItemsSelected = [...currentItemsSelected, ...shiftHoverSelected];
      } else {
        newItemsSelected = currentItemsSelected.filter((photo) => !shiftHoverSelected.includes(photo));
      }
    } else {
      if (currentItemsSelected.includes(itemId)) {
        newItemsSelected = bypass ? currentItemsSelected : currentItemsSelected.filter((item) => item !== itemId);
      } else {
        newItemsSelected = bypass ? [itemId] : [...currentItemsSelected, itemId];
      }
    }

    const filterUnselected = currentItemsSelected.filter((item: string) => !newItemsSelected.includes(item));
    const filterSelected = unselectedItems.filter((item: string) => !newItemsSelected.includes(item));

    setShiftHoverSelected([]);
    setAllItemsSelected(false);
    setItemsSelected(Array.from(new Set(newItemsSelected)));

    if (!isShiftPressed) setPreviousItemSelected(itemId);
    if (allItemsSelected) setUnselectedItems([...filterUnselected, ...filterSelected]);
  };

  const handleItemsSelectAll = () => {
    setItemsSelected(knockoutItems.map((item: PostProcessingItem) => item.id));
    setAllItemsSelected(true);
    setUnselectedItems([]);
    setShiftHoverSelected([]);
    setPreviousItemSelected('');
  };

  const handleItemsSelectClear = () => {
    setShiftHoverSelected([]);
    setItemsSelected([]);
    setUnselectedItems([]);
    setAllItemsSelected(false);
    setPreviousItemSelected('');
  };

  const handleMouseEnter = (e: any, knockoutItem: PostProcessingItem) => {
    e.preventDefault();

    if (itemsSelected.length) {
      previousItemHoveredRef.current = knockoutItem;

      if (e.shiftKey) {
        const start = previousItemSelected
          ? knockoutItems.findIndex((item: PostProcessingItem) => item.id === previousItemSelected)
          : knockoutItems.findIndex((item: PostProcessingItem) => item.id === itemsSelected[0]);
        const end = knockoutItems.findIndex((item: PostProcessingItem) => item.id === knockoutItem.id);

        setShiftHoverSelected(knockoutItems.slice(Math.min(start, end), Math.max(start, end) + 1).map((item: PostProcessingItem) => item.id));
      } else if (shiftHoverSelected.length) {
        setShiftHoverSelected([]);
      }
    }
  };

  const handleShiftSelect = () => {
    if (itemsSelected.length && previousItemHoveredRef.current) {
      const start = previousItemSelected
        ? knockoutItems.findIndex((item: PostProcessingItem) => item.id === previousItemSelected)
        : knockoutItems.findIndex((item: PostProcessingItem) => item.id === itemsSelected[0]);
      const end = knockoutItems.findIndex((item: PostProcessingItem) => item.id === previousItemHoveredRef.current?.id);

      setShiftHoverSelected(knockoutItems.slice(Math.min(start, end), Math.max(start, end) + 1).map((item: PostProcessingItem) => item.id));
    }
  };

  const handleKeyDown = useRef(
    debounce(
      (e) => {
        switch (e.which) {
          case 16:
            return setIsShiftPressed(true);
          default:
            return;
        }
      },
      50,
      { leading: true, trailing: false }
    )
  ).current;

  const handleKeyUp = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Shift':
        return setIsShiftPressed(false);

      default:
        return;
    }
  };

  const handlePostProcessingMoveToGallery = () => {
    dispatch(
      createJobKnockoutTransfer(
        {
          knockoutId: postProcessingId,
          transfer_originals: moveToGalleryType === 'move-both' ? true : false,
          transfer_overwrite: moveToGalleryOverwrite
        },
        () => history.push(`/jobs/${jobId}/services/postprocessing`)
      )
    );
  };

  const handlePostProcessingDownloadPrepare = () => {
    dispatch(createJobKnockoutPrepare({ knockoutId: postProcessingId }, () => history.push(`/jobs/${jobId}/services/postprocessing`)));
  };

  const handlePostProcessingMessageReceive = {
    received(message: any) {
      if (message.type === 'knockout') {
        dispatch(updateJobKnockoutFromCable({ data: message['knockout'] }));
      }

      if (message.type === 'knockout-item') {
        dispatch(updateKnockoutUploadFromCable({ data: message['knockout_item'] }));
      }
    }
  };

  useActionCable({ channel: 'JobEventsChannel', job_id: jobId }, handlePostProcessingMessageReceive);

  useEffect(() => {
    dispatch(
      getJobKnockout({ knockoutId: postProcessingId }, () => {
        dispatch(getJobKnockoutItems({ knockoutId: postProcessingId, sort: knockoutItemsSort }));
      })
    );

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (isShiftPressed) {
      handleShiftSelect();
    }
  }, [isShiftPressed]);

  return (
    <>
      <section className="job-postprocessing-review">
        <header className="flex items-center justify-between animate job-postprocessing-review__header">
          <aside className="flex items-start start">
            <Tooltip {...{ title: 'Back to list', arrow: false, distance: 5, position: 'top' }}>
              <Link to={`/jobs/${jobId}/services/postprocessing`}>
                <button className="button button--clean button--noborder" name="button" type="button">
                  <i className="icon-back"></i>
                </button>
              </Link>
            </Tooltip>

            <div>
              {knockout.status === 'created' ? (
                <h2 className="text-headline-sm">Review Uploaded Photos</h2>
              ) : (
                <h2 className="text-headline-sm">Post-Processing Complete</h2>
              )}
              <p className="font-semibold text-type-base">{knockout.name}</p>
            </div>
          </aside>

          {knockout?.status && (
            <div className="button-group animate">
              {knockout.status === 'created' && (
                <Tooltip {...{ title: 'Settings', arrow: false, distance: 5, position: 'top' }}>
                  <Link to={`/jobs/${jobId}/services/postprocessing/${postProcessingId}/settings`}>
                    <button className="button button--outline button--small" name="button" type="button">
                      <i className="icon-gear" />
                    </button>
                  </Link>
                </Tooltip>
              )}

              {knockout.status === 'complete' ? (
                <aside className="button-group">
                  {!knockout.transferred_at && (
                    <button
                      className="button button--medium"
                      data-loading={knockoutsRequesting}
                      disabled={knockoutsRequesting}
                      onClick={handleShowMoveToGalleryToggle}
                    >
                      <b>Move to Gallery</b>
                    </button>
                  )}

                  {knockout.download_url ? (
                    <a href={knockout.download_url} className="button button--outline button--medium" type="button" target="_blank" rel="noopener noreferrer">
                      <b>Download Ready</b>
                    </a>
                  ) : (
                    <button
                      className="button button--outline button--medium"
                      data-loading={knockoutsRequesting}
                      disabled={knockoutsRequesting}
                      onClick={handlePostProcessingDownloadPrepare}
                    >
                      <b>Prepare Download</b>
                    </button>
                  )}
                </aside>
              ) : (
                <button
                  className="font-semibold button button--medium"
                  disabled={knockout.uploading === true || knockoutItems?.length === 0 || failedCount > 0}
                  data-loading={knockout.uploading === true && failedCount === 0}
                  onClick={handlePostProcessingReviewed}
                >
                  <b>{failedCount > 0 ? 'Failed' : knockout.uploading === true ? 'Uploading' : 'Done'}</b>
                </button>
              )}
            </div>
          )}
        </header>

        <aside className="flex justify-between items-center job-postprocessing-review__header">
          <h6 className="text-neutral-500 m-0 animate">
            {`${uploadedCount} Photo${uploadedCount > 1 ? 's' : ''} Uploaded`}
            {failedCount > 0 && ` | ${failedCount} Photo ${failedCount > 1 ? 's' : ''} Failed`}
          </h6>
          <div className="flex items-center end button-group animate">
            <small className="text-neutral-500">{allItemsSelected ? knockoutItems.length : itemsSelected.length} Selected</small>

            {/* View */}
            {itemsSelected.length > 1 && (
              <Tooltip {...{ title: 'View', arrow: false, distance: 10, position: 'top' }}>
                <button className="button button--outline button--small" name="view" type="button" onClick={handleShowLightboxToggle}>
                  <i className="icon-preview"></i>
                </button>
              </Tooltip>
            )}

            {/*  Delete Photos */}
            {knockout?.status === 'created' && itemsSelected.length > 0 && (
              <Tooltip {...{ title: 'Delete', arrow: false, distance: 10, position: 'top' }}>
                <button className="button button--lean  button--small animate" name="delete" type="button" onClick={handleDeleteShow}>
                  <i className="icon-trash"></i>
                </button>
              </Tooltip>
            )}

            {/* Sort */}
            {itemsSelected.length === 0 && (
              <Tooltip {...{ title: 'Sort', arrow: false, distance: 10, position: 'top' }}>
                <Dropdown {...{ buttonIcon: 'icon-sort', buttonExtraClass: 'button--lean button--small button-dropdown' }}>
                  <ul className="panel panel-dropdown">
                    <li
                      className={`panel-dropdown__item ${
                        JSON.stringify(itemsSortOrder) === JSON.stringify({ order: 'filename', dir: 'asc' }) ? 'panel-dropdown__item--active' : ''
                      }`}
                      onClick={() => handlePostProcessingItemsSort({ order: 'filename', dir: 'asc' })}
                    >
                      Name: A to Z
                    </li>
                    <li
                      className={`panel-dropdown__item ${
                        JSON.stringify(itemsSortOrder) === JSON.stringify({ order: 'filename', dir: 'desc' }) ? 'panel-dropdown__item--active' : ''
                      }`}
                      onClick={() => handlePostProcessingItemsSort({ order: 'filename', dir: 'desc' })}
                    >
                      Name: Z to A
                    </li>
                  </ul>
                </Dropdown>
              </Tooltip>
            )}

            {/* Select */}
            <Tooltip {...{ title: 'Select', arrow: false, distance: 10, position: 'top' }}>
              <Dropdown {...{ buttonIcon: 'icon-select', buttonExtraClass: 'button--lean button--small button-dropdown' }}>
                <ul className="panel panel-dropdown panel-dropdown--large">
                  <li
                    className={`panel-dropdown__item ${knockoutItems && itemsSelected.length >= knockoutItems.length ? 'panel-dropdown__item--disabled' : ''}`}
                    onClick={handleItemsSelectAll}
                  >
                    Select All
                  </li>
                  <li className={`panel-dropdown__item ${itemsSelected.length === 0 ? 'panel-dropdown__item--disabled' : ''}`} onClick={handleItemsSelectClear}>
                    Clear Selection
                  </li>
                </ul>
              </Dropdown>
            </Tooltip>
          </div>
        </aside>

        <div className="job-postprocessing-review__container">
          {knockoutItems?.length ? (
            <div className="grid gap-5 animate job-postprocessing-review__row">
              {knockoutItems.map((knockoutItem: PostProcessingItem) => (
                <div
                  key={knockoutItem.id}
                  className={`job-postprocessing-review__item ${itemsSelected.includes(knockoutItem.id) ? 'job-postprocessing-review__item--active' : ''} ${shiftHoverSelected.includes(knockoutItem.id) ? 'job-postprocessing-review__item--shift-select' : ''}`}
                  onMouseEnter={(e) => itemsSelected.length && handleMouseEnter(e, knockoutItem)}
                >
                  <figure>
                    <div className="flex items-center justify-between job-postprocessing-review__image-header">
                      <span className="job-postprocessing-review__image-name">
                        <Tooltip {...{ title: knockoutItem.knocked_out_filename || knockoutItem.filename, arrow: false, distance: 18, position: 'top' }}>
                          {knockoutItem.knocked_out_filename || knockoutItem.filename}
                        </Tooltip>
                      </span>
                      <button
                        className="button button--outline button--small button--noborder"
                        type="button"
                        disabled={itemsSelected.length > 1 || knockoutsRequesting}
                        draggable="false"
                        onClick={() => {
                          handleSelectItems(knockoutItem.id, true);
                          handleShowLightboxToggle();
                        }}
                      >
                        <i className="icon-view"></i>
                      </button>
                    </div>
                    <div className="job-postprocessing-review__image-container" onClick={() => handleSelectItems(knockoutItem.id)}>
                      <LazyLoadImage
                        className="job-postprocessing-review__image"
                        src={imageScaling({
                          url: knockout.status === 'complete' ? knockoutItem?.knocked_out_image_url : knockoutItem?.original_image_url,
                          size: 'medium'
                        })}
                        alt={knockoutItem.knocked_out_filename || knockoutItem.filename}
                        height={225}
                        effect="opacity"
                        draggable="false"
                      />
                    </div>
                    <div className="flex justify-end job-postprocessing-review__image-footer">
                      {/* Delete Item */}
                      {knockout.status !== 'complete' && (
                        <Tooltip {...{ title: 'Remove', arrow: false, distance: 18, position: 'top' }}>
                          <button
                            className="button button--outline button--small button--noborder"
                            type="button"
                            draggable="false"
                            disabled={itemsSelected.length > 1}
                            onClick={() => {
                              handleSelectItem(knockoutItem);
                              handleDeleteShow();
                            }}
                          >
                            <i className="icon-trash"></i>
                          </button>
                        </Tooltip>
                      )}

                      {/* Image failed message */}
                      {knockoutItem.status === 'failed' && (
                        <span className="job-postprocessing-review__image-failed">
                          {formatFailedMessage(knockoutItem.skylab_message ?? 'Image post-processing failed')}
                        </span>
                      )}
                    </div>
                  </figure>
                </div>
              ))}
            </div>
          ) : (
            <>
              {knockoutsRequesting ? (
                <GridLoader rows={2} columns={5} gap={20} minHeight={303} />
              ) : (
                <aside className="flex items-center justify-center panel panel--secondary panel--tall animate">
                  <h3 className="m-0">No post-processing photos found</h3>
                </aside>
              )}
            </>
          )}
        </div>
      </section>

      {/* Delete modal */}
      <aside className={`modal ${showDelete ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleDeleteCancel}>
              <i className="icon-close"></i>
            </button>
            <h3>Remove Photos</h3>
          </header>
          <main className="modal__content">
            <p>
              Are you sure you want to remove <b>{itemsSelected.length === 1 ? 'this photo' : `these ${itemsSelected.length} photos`}</b> from the
              post-processing job?
            </p>
          </main>
          <footer className="flex justify-center modal__footer button-group">
            <button
              className="button button--danger"
              name="remove"
              type="button"
              onClick={handleDeleteConfirm}
              disabled={knockoutsRequesting}
              data-loading={knockoutsRequesting}
            >
              Remove
            </button>
            <button className="button button--lean" name="cancel" type="button" onClick={handleDeleteCancel}>
              Cancel
            </button>
          </footer>
        </div>
      </aside>

      {/* Move to gallery */}
      <aside className={`modal ${showMoveToGallery ? '' : 'transparent'} text-left`}>
        <div className="modal__box modal__box--xsmall modal__box--nomin">
          <header className="modal__header">
            <button className="button button--action modal__close" name="close" type="button" onClick={handleShowMoveToGalleryToggle}>
              <i className="icon-close"></i>
            </button>
            <h3 className="job-postprocessing-review__modal-title">Move to Gallery</h3>
            <small className="text-black text-body-xs">Please select one of the following:</small>
          </header>
          <main className="modal__content job-postprocessing-review__modal-content">
            <ul className="job-postprocessing-review__move-list">
              <li className="flex items-start nowrap">
                <input
                  id="move-post-processing"
                  type="radio"
                  name="type"
                  checked={moveToGalleryType === 'move-post-processing'}
                  onChange={() => setMoveToGalleryType('move-post-processing')}
                />
                <label htmlFor="move-post-processing">
                  <small className="text--normal">Move only post-processed photos to the gallery</small>
                </label>
              </li>
              <li className="flex items-start nowrap">
                <input id="move-both" type="radio" name="type" checked={moveToGalleryType === 'move-both'} onChange={() => setMoveToGalleryType('move-both')} />
                <label htmlFor="move-both" className="whitespace-pre-line">
                  <small className="text--normal">Move both post-processed and original photos to the gallery</small>
                </label>
              </li>
            </ul>
            <aside className="panel panel--lean flex items-center">
              <input
                id="overwrite"
                type="checkbox"
                name="overwrite"
                checked={moveToGalleryOverwrite}
                onChange={({ target }) => setMoveToGalleryOverwrite(target.checked)}
              />
              <label htmlFor="overwrite" className="m-0 job-postprocessing-review__label">
                Overwrite existing photos with the same file name
              </label>
            </aside>
          </main>
          <footer className="flex justify-center modal__footer button-group">
            <button
              className="button button--medium font-semibold"
              name="button"
              type="button"
              onClick={handlePostProcessingMoveToGallery}
              disabled={knockoutsRequesting}
              data-loading={knockoutsRequesting}
            >
              Move
            </button>
          </footer>
        </div>
      </aside>

      {showLightbox && (
        <Lightbox
          lightboxPhotos={knockoutItems
            .filter((item: PostProcessingItem) => itemsSelected.includes(item.id))
            .map((item: PostProcessingItem) => ({ imageUrl: item.knocked_out_image_url || item.original_image_url, imageName: item.filename }))}
          onLightboxClose={handleShowLightboxToggle}
        />
      )}
    </>
  );
};

export default Review;
